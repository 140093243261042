import {
  getSchedulingRuleType,
  getSchedulingRuleDefinition,
  getSchedulingRuleRundownEntity,
  getSchedulingRuleSequenceValue
} from './schedulingRules'

import {
  getEntitlementDisplayType,
  getEntitlementType,
  getCricketEntitlementType,
  getCricketEntitlementDisplayType,
  getCricketLEDType,
  getVrcEntitlementType,
  getEntitlementItemDisplayType,
  getEntitlementLayoutType
} from './entitlements'

import {
  getRundownItemSection,
  getRundownStatus,
  getRundownAssetType,
  getRundownVersionStatus,
  getRundownItemVersionStatus,
  getReplaceSpotType,
  getRundownAssetTypeDisplay,
  getCricketRundownItemPlayStatus
} from './rundown'

import {
  getEntitlementStatusDescriptions,
  getEntitlementStatuses,
  getEntitlementStatusDisplays,
  getEntitlementMediaType,
  getPaymentScheduleStatus,
  getPaymentStatusCode,
  getEntitlementMatchDetails,
  getEntitlementRaceDayDetails,
  getEntitlementStandardEventDetails,
  getEntitlementOverrideDate
} from './partnerContract'

import { getCompetitionType } from './fixtureItem'

import { getSportType, getSportTypeDisplay } from './fixture'

import {
  getSovTableDisplay,
  getSovFilterDisplayOptionTitleMap,
  getSovFilterDisplayOptionValueMap,
  getCricketSovUnit
} from './sov'

import {
  getFixtureType,
  getFixtureTypeDisplay,
  getPeriodStructure,
  getPeriodStructureDisplay,
  getSportTypeColor,
  getSportTypeColorDisplay
} from './sportType'

import {
  getSellingMetric,
  getEntitlementTemplateType,
  getCustomSchedulingParameter
} from './entitlementTemplate'

import { getTeamTypes } from './teamType'

import {
  getExportDestination,
  getExportType,
  getCricketExportType,
  getExportDisplayType
} from './export'

import { getSpotType } from './spotType'

import {
  getInventoryTemplateType,
  getInventoryType,
  getInventoryTableType,
  getTeamVTeamInventory
} from './inventory'

import { getGender, getSeriesType } from './series'

import { getRundownType, getRundownParamsType } from './rundownType'
import { getTermsAndConditionsTypes } from './termsAndConditions'

const makeEnums = () => {
  return {
    RULE_TYPES: getSchedulingRuleType(),
    RULE_DEFINITIONS: getSchedulingRuleDefinition(),
    RULE_RUNDOWN_ENTITY: getSchedulingRuleRundownEntity(),
    RULE_RUNDOWN_SEQUENCE_VALUE: getSchedulingRuleSequenceValue(),
    CRICKET_ENTITLEMENT_TYPES: getCricketEntitlementType(),
    CRICKET_ENTITLEMENT_DISPLAY_TYPES: getCricketEntitlementDisplayType(),
    CRICKET_LED_TYPE: getCricketLEDType(),
    ENTITLEMENT_DISPLAY_TYPES: getEntitlementDisplayType(),
    ENTITLEMENT_TYPES: getEntitlementType(),
    RUNDOWN_STATUS: getRundownStatus(),
    RUNDOWN_ITEM_SECTION: getRundownItemSection(),
    RUNDOWN_ASSET_TYPE: getRundownAssetType(),
    RUNDOWN_VERSION_STATUS: getRundownVersionStatus(),
    RUNDOWN_ITEM_VERSION_STATUS: getRundownItemVersionStatus(),
    RUNDOWN_ASSET_TYPE_DISPLAY: getRundownAssetTypeDisplay(),
    CRICKET_RUNDOWN_ITEM_PLAY_STATUS: getCricketRundownItemPlayStatus(),
    REPLACE_SPOT_TYPE: getReplaceSpotType(),
    ENTITLEMENT_STATUS: getEntitlementStatuses(),
    ENTITLEMENT_STATUS_DISPLAY: getEntitlementStatusDisplays(),
    ENTITLEMENT_STATUS_DESCRIPTION: getEntitlementStatusDescriptions(),
    ENTITLEMENT_MEDIA_TYPE: getEntitlementMediaType(),
    ENTITLEMENT_MATCH_DETAILS: getEntitlementMatchDetails(),
    ENTITLEMENT_RACE_DAY_DETAILS: getEntitlementRaceDayDetails(),
    ENTITLEMENT_STANDARD_EVENT_DETAILS: getEntitlementStandardEventDetails(),
    ENTITLEMENT_OVERRIDE_DATE: getEntitlementOverrideDate(),
    FIXTURE_ITEM: getCompetitionType(),
    SPORT_TYPE: getSportType(),
    SPORT_TYPE_DISPLAY: getSportTypeDisplay(),
    PAYMENT_SCHEDULE_STATUS: getPaymentScheduleStatus(),
    PAYMENT_STATUS_CODE: getPaymentStatusCode(),
    SOV_TABLE_DISPLAY: getSovTableDisplay(),
    SOV_FILTER_DISPLAY_OPTION_TITLE_MAPS: getSovFilterDisplayOptionTitleMap(),
    SOV_FILTER_DISPLAY_OPTION_VALUE_MAPS: getSovFilterDisplayOptionValueMap(),
    FIXTURE_TYPE: getFixtureType(),
    FIXTURE_TYPE_DISPLAY: getFixtureTypeDisplay(),
    PERIOD_STRUCTURE: getPeriodStructure(),
    PERIOD_STRUCTURE_DISPLAY: getPeriodStructureDisplay(),
    SPORT_TYPE_COLOR: getSportTypeColor(),
    SPORT_TYPE_COLOR_DISPLAY: getSportTypeColorDisplay(),
    ENTITLEMENT_TEMPLATE_TYPE: getEntitlementTemplateType(),
    SELLING_METRIC: getSellingMetric(),
    CUSTOM_SCHEDULING: getCustomSchedulingParameter(),
    VRC_ENTITLEMENT_TYPES: getVrcEntitlementType(),
    TEAM_TYPES: getTeamTypes(),
    EXPORT_DESTINATION: getExportDestination(),
    EXPORT_EXCEL_TYPE: getExportType(),
    SPOT_TYPE: getSpotType(),
    GENDER: getGender(),
    SERIES_TYPE: getSeriesType(),
    RUNDOWN_TYPE: getRundownType(),
    ENTITLEMENT_ITEM_DISPLAY_TYPE: getEntitlementItemDisplayType(),
    RUNDOWN_PARAM_TYPE: getRundownParamsType(),
    ENTITLEMENT_LAYOUT_TYPE: getEntitlementLayoutType(),
    TERM_AND_CONDITION: getTermsAndConditionsTypes(),
    INVENTORY_TEMPLATE_TYPE: getInventoryTemplateType(),
    INVENTORY_TYPE: getInventoryType(),
    INVENTORY_TABLE_TYPE: getInventoryTableType(),
    TEAMVTEAM_INVENTORY: getTeamVTeamInventory(),
    CRICKET_EXPORT_EXCEL_TYPE: getCricketExportType(),
    EXPORT_DISPLAY_TYPE: getExportDisplayType(),
    SOV_UNIT: getCricketSovUnit()
  }
}

export default makeEnums()
