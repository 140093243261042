import { useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Box, Text, Icon, Button } from 'components'
import Loading from 'assets/icon/loading.gif'

const PageContainer = styled(Box)`
  max-height: 290px;
  overflow: hidden;
  width: 100%;

  .react-pdf__Page {
    height: 290px;
    text-align: center;

    canvas {
      display: inline-block !important;
      height: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      background: #28293D;
      opacity: 60%;

      .overlay-item {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        button:not(:first-child) {
          margin-left: 60px;
        }
      }
    }

    .react-pdf__Page__annotations,
    .react-pdf__Page__textContent {
      display: none;
    }
  }
`

const ThumbnailContainer = styled(Box)`
  position: relative;
  margin-bottom: 8px;
  cursor: pointer;

  .react-pdf__Page {
    .react-pdf__Page__annotations {
      display: none;
    }
  }

  &.active {
    border: 1px solid #003e72;
  }

  .pageNumber {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-size: 8px;
    font-weight: 400px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 50%;
    transform: translateY(50%);

    &:hover {
      background: #28293d;
      opacity: 0.5;
    }
  }
`

const PDFSignContractViewer = ({ file, name, onPreview, onDelete, ...props }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageWidth, setPageWidth] = useState(652)
  const [isShowOverlay, setIsShowOverlay] = useState(false)

  const handleLoadSuccess = useCallback(({numPages}) => {
    setNumPages(numPages)
  }, [])

  const loading = useMemo(
    () => (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        backgroundColor='#F7F7F9'
        opacity='90%'
        width='100%'
        height='290px'
        top={0}
      >
        <img height='65' width='65' src={Loading} alt='loading' />
      </Box>
    ),
    []
  )

  return (
    <Document
      renderMode='canvas'
      file={file}
      onLoadSuccess={handleLoadSuccess}
      onLoadError={(error) => {
        console.log(error)
      }}
      loading={loading}
    >
      <Box height={290} width='100%' display='flex' background='#F7F7F9'>
        <Box display='inline-block' width='80%'>
          <PageContainer>
            <Page
              pageNumber={pageNumber}
              height={290}
              onLoadSuccess={(page) => {
                setPageWidth(page.width)
              }}
              onMouseEnter={(e) => {
                setIsShowOverlay(true)
              }}
              onMouseLeave={(e) => {
                setIsShowOverlay(true)
              }}
            >
              {isShowOverlay && <Box className='overlay' width={`${pageWidth}px`}>
                <Box className='overlay-item'>
                  { onPreview && (
                    <Button
                      type='icon-outline'
                      buttonType='button'
                      icon={<Icon name='eye'/>}
                      onClick={() => onPreview(pageNumber)}
                      width={24}
                    />
                  )}
                  { onDelete && (
                    <Button
                      type='icon-outline'
                      buttonType='button'
                      icon={<Icon name='binWhite'/>}
                      onClick={() => onDelete()}
                      width={24}
                    />
                  )}
                </Box>
              </Box>}
            </Page>
          </PageContainer>
        </Box>
        <Box display='inline-block' height={290} width='20%' textAlign='center' background='#E5E5EF'>
          <Text
            display='block'
            fontWeight={500}
            fontSize='8px'
            textAlign='left'
            p='12px 16px 2px 16px'
            style={{
              'white-space': 'nowrap',
              'overflow': 'hidden',
              'text-overflow': 'ellipsis',
            }}
          >
            {name}
          </Text>
          <Text display='block' fontWeight={500} fontSize='8px' textAlign='left' p='2px 16px 12px 16px'>
            Pages {numPages}
          </Text>
          <Box
            maxHeight={234}
            overflow='auto'
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            {numPages > 0 &&
              Array.from(new Array(numPages), (_, index) => (
                <ThumbnailContainer
                  className={index + 1 === pageNumber ? 'active' : ''}
                >
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={100}
                    scale={1}
                  />
                  <Text className='pageNumber'>{index + 1}</Text>
                  <Box
                    className='overlay'
                    onClick={() => setPageNumber(index + 1)}
                  ></Box>
                </ThumbnailContainer>
              ))}
          </Box>
        </Box>
      </Box>
    </Document>
  )
}

export default PDFSignContractViewer
