import { useState, useEffect } from 'react'
import { usePropState } from 'utils/hooks'
import useCustomState from './useCustomState'

import { Row, Box, Input, Icon, Table, Checkbox, TableHeader, Text } from 'components'
import HeaderButton from 'components/TableHeader/HeaderButton'
import MatchFilter from './MatchFilter'

import styled from 'styled-components'
import get from 'lodash/get'
import { getSortedData, getFilteredData, getFilteredSearchValue } from 'utils/helper'

const MatchTableRow = styled.tr`
  &.available {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #003e72;
  }
  &.not-available {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #bf2600;
  }
  &:hover {
    background: #f7f7f9;
  }
`

const InnerContainer = styled.div`
  border: 1px solid #e5e5ef;
  border-radius: 4px;
  padding: 16px;
`

const Container = styled.div``

const MatchesTable = ({
  columns,
  searchKeys,
  filterKeys,
  dataSource,
  initSelectedItems,
  selectedItems: propSelectedItems,
  onSelect,
  availableIds,
  notAvailableIds,
  style,
  className,
  title = 'Select Match',
  footer = null,
  syncURL = false
}) => {
  const [selectedItems, setSelectedItems] = usePropState(
    propSelectedItems || initSelectedItems || [],
    []
  )
  const [sortOrder, setSortOrder] = useState([])
  const { searchTerm, filters, setFilters, setSearchTerm } = useCustomState(filterKeys, syncURL)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSelect?.(selectedItems), [selectedItems])

  function getTableHeader() {
    let headers = []

    if (isSelectItemEnable()) {
      const disabled = !dataSource?.length || dataSource?.every?.((i) => i.disabled)
      const checked = !!dataSource?.length && isSelectedAll()

      headers.push(
        <HeaderButton>
          <Box className='table-header-button'>
            <Checkbox
              checked={checked}
              onChange={(e) => handleSelectedAll(e.target.checked)}
              disabled={disabled}
            />
          </Box>
        </HeaderButton>
      )
    }

    return headers.concat(
      columns.map((column) => {
        return (
          <TableHeader
            text={column.title}
            sortKey={column.key}
            sortOrder={sortOrder}
            handler={setSortOrder}
          />
        )
      })
    )
  }

  const handleSelectedAll = (checked) => {
    const ids = getDisplayMatch()
      .filter((item) => {
        if (item && item.disabled) {
          return !item.disabled
        }

        return true
      })
      .map((item) => item.id)

    if (checked) {
      setSelectedItems([...new Set([...selectedItems, ...ids])])
    } else {
      setSelectedItems(
        selectedItems.filter((id) => {
          return ids.includes(id) === false
        })
      )
    }
  }

  const isSelectedAll = () => {
    const fixtureItemsSorted = getDisplayMatch().filter((item) => {
      if (item.disabled) {
        return !item.disabled
      }

      return true
    })

    return fixtureItemsSorted.every((element) => {
      return selectedItems.indexOf(element.id) >= 0
    })
  }

  function isSearchable() {
    if (searchKeys) {
      return true
    }

    return false
  }

  function isRecordDisabled(record) {
    if (record && record.disabled) {
      return record.disabled
    }

    return false
  }

  function isSelectItemEnable() {
    return typeof onSelect === 'function'
  }

  const onSelectItem = (id) => {
    let ids = []

    if (selectedItems.includes(id)) {
      ids = selectedItems.filter((value) => value !== id)
    } else {
      selectedItems.push(id)
      ids = [...selectedItems]
    }

    setSelectedItems(ids)
  }

  function getIsAvailableClassName(matchId) {
    if (availableIds && availableIds.includes(matchId)) {
      return 'available'
    }
    if (notAvailableIds && notAvailableIds.includes(matchId)) {
      return 'not-available'
    }

    return null
  }

  function getTableBody() {
    return getDisplayMatch().map((record) => {
      const isChecked = selectedItems.includes(record.id)
      const availableClassName = getIsAvailableClassName(record.id)
      const className = `${availableClassName}`

      return (
        <MatchTableRow className={className}>
          {isSelectItemEnable() && (
            <td>
              <Checkbox
                checked={isChecked}
                onChange={() => {
                  onSelectItem(record.id)
                }}
                disabled={isRecordDisabled(record)}
              />
            </td>
          )}
          {columns.map((column) => {
            if (column.dataIndex) {
              const dataIndex = column.dataIndex
              const value = `${get(record, dataIndex)}`

              if (column.render) {
                return <td>{column.render(value, record)}</td>
              }

              return <td>{value}</td>
            }

            return null
          })}
        </MatchTableRow>
      )
    })
  }

  function getDisplayMatch() {
    let dataToDisplay = getFilteredSearchValue(dataSource, searchTerm, searchKeys)
    dataToDisplay = getFilteredData(dataToDisplay, filters)
    dataToDisplay = getSortedData(dataToDisplay, sortOrder)

    return dataToDisplay
  }

  return (
    <Container style={style} className={className}>
      {title && (
        <Text fontSize='14px' fontWeight={500} color='#28293D' mb='16px' mt={24}>
          {title}
        </Text>
      )}
      <InnerContainer className='inner-container'>
        <Row>
          <Box>
            <MatchFilter
              items={dataSource}
              filterKeys={filterKeys}
              filters={filters}
              setFilters={setFilters}
            />
          </Box>
          {isSearchable() && (
            <Box ml='auto'>
              <Input
                placeholder='Search'
                prefix={<Icon name='search' />}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
              />
            </Box>
          )}
        </Row>
        <Box mt='24px' className='table-container'>
          <Table sticky bordered width='100%' maxHeight={540}>
            <thead>
              <tr>{getTableHeader()}</tr>
            </thead>
            <tbody>{getTableBody()}</tbody>
          </Table>
        </Box>
        {footer}
      </InnerContainer>
    </Container>
  )
}

export default MatchesTable
