import { Controller } from 'react-hook-form'
import { Row, Col } from 'components'
import Input from './Input'
import { Label, getWidth } from './helper'
import { get } from 'lodash'

const InputForm = ({
  name,
  label,
  initialValue,
  control,
  errors,
  rules,
  register,
  width,
  labelWidth,
  labelMargin,
  ...props
}) => {
  return (
    <Row alignItems='center'>
      {label && (
        <Label
          gutter={width ? '0' : '8px'}
          useCustomWidth={labelWidth}
          width={labelWidth || '100%'}
        >
          {label}
        </Label>
      )}
      <Col width={getWidth({ labelWidth, width })} flex='unset'>
        <Controller
          {...props}
          as={Input}
          name={name}
          control={control}
          defaultValue={initialValue}
          rules={rules || {}}
          error={get(errors, name)}
          ref={register}
        />
      </Col>
    </Row>
  )
}

export default InputForm
