export const getExportDestination = () => {
  return { S3: 1, EMAIL: 2, DIRECT_DOWNLOAD: 3 }
}

export const getExportType = () => {
  return { LED_SALES_ADMIN: 1, LED_OPERATOR: 2, TVC: 3, TEAM_V_TEAM: 4, OUT_OF_GAME: 5 }
}

export const getCricketExportType = () => {
  return { EXCEL_LED: 1, EXCEL_TVC: 2 }
}

export const getExportDisplayType = () => {
  return { ALL: 1, LED: 2, SIGHTSCREEN: 3, PARAPET: 4, TVC: 5 }
}
