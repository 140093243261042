const getFixtureType = () => {
  return {
    STANDARD: 1,
    CUSTOM: 2,
    BASIC: 3,
    TEAM_V_TEAM: 4
  }
}

const getFixtureTypeDisplay = () => {
  return {
    STANDARD: 'Standard',
    CUSTOM: 'Custom',
    BASIC: 'DROP Event Placeholder',
    TEAM_V_TEAM: 'TeamVTeam'
  }
}

const getPeriodStructure = () => {
  return {
    HALVES: 1,
    QUARTERS: 2,
  }
}

const getPeriodStructureDisplay = () => {
  return {
    HALVES: 'Halves',
    QUARTERS: 'Quarters',
  }
}


const getSportTypeColor = () => {
  return {
    COLOR_000000: '#000000',
    COLOR_8C8DA0: '#8C8DA0',
    COLOR_FFDA00: '#FFDA00',
    COLOR_FF9000: '#FF9000',
    COLOR_DD0000: '#DD0000',
    COLOR_50D300: '#50D300',
    COLOR_005D04: '#005D04',
    COLOR_8FE9FD: '#8FE9FD',
    COLOR_006DED: '#006DED',
    COLOR_7400C1: '#7400C1',
    COLOR_E864EB: '#E864EB',
  }
}

const getSportTypeColorDisplay = () => {
  return {
    COLOR_000000: '#000000',
    COLOR_8C8DA0: '#8C8DA0',
    COLOR_FFDA00: '#FFDA00',
    COLOR_FF9000: '#FF9000',
    COLOR_DD0000: '#DD0000',
    COLOR_50D300: '#50D300',
    COLOR_005D04: '#005D04',
    COLOR_8FE9FD: '#8FE9FD',
    COLOR_006DED: '#006DED',
    COLOR_7400C1: '#7400C1',
    COLOR_E864EB: '#E864EB',
  }
}

export {
  getFixtureType,
  getFixtureTypeDisplay,
  getPeriodStructure,
  getPeriodStructureDisplay,
  getSportTypeColor,
  getSportTypeColorDisplay
}
