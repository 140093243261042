import { useEffect, useRef, useState } from 'react'

export default function usePropState(propState, defaultState) {
  const [state, setState] = useState(propState || defaultState)
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) setState(propState)

    return () => {
      mounted.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(propState)])

  return [state, setState]
}
