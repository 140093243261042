import styled, { css } from 'styled-components'
import { Text, Icon } from 'components'

export const styled_error = ({ error }) => {
  if (error) {
    return css`
      border: 1px solid #bf2600 !important;
    `
  }
}

export const styled_disabled = ({ disabled }) => {
  if (disabled) {
    return css`
      .ant-input-group-addon,
      .ant-input,
      &.ant-input {
        background-color: #f7f7f9;
      }
    `
  }
}

const ErrorContainer = styled.div`
  margin-top: 10px;
  display: inline-block;

  svg {
    vertical-align: bottom;
  }
`

export const ErrorMessage = ({ enabled, message, color = '#bf2600' }) => {
  if (enabled === false || !message) return null

  return (
    <ErrorContainer>
      <Icon name='inputError' />
      <Text color={color} fontSize={14} ml={12}>
        {message}
      </Text>
    </ErrorContainer>
  )
}
