import * as validate from './resource/validate'

export const Expect = Object.keys(validate).reduce(
  (factory, key) => ({
    ...factory,
    [key]: (expectValue, value) => {
      const error = validate[key]
      const message = error(expectValue, value)
      return !message
    }
  }),
  {}
)

export { default } from './expect'
