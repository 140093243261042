import { forwardRef } from 'react'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import { space, layout, typography } from 'styled-system'
import { Box } from 'components'
import { clsx } from 'utils/helper'

const handleCursorType = (props) => {
  const tbody = _.find(props.children, { type: 'tbody' })
  if (tbody) {
    const tbodyFirstChild = _.get(tbody, 'props.children[0]', [])
    if (tbodyFirstChild.type === 'tr' && _.has(tbodyFirstChild, 'props.onClick')) {
      return css`
        cursor: pointer;
        &:hover {
          background: #f7f7f9;
        }
      `
    } else {
      if (tbodyFirstChild.length) {
        const trFirstChild = tbodyFirstChild[0]
        if (trFirstChild.type === 'tr' && _.has(trFirstChild, 'props.onClick')) {
          return css`
            cursor: pointer;
            &:hover {
              background: #f7f7f9;
            }
          `
        }
      }
    }
  }
}

const handleBordered = (bordered) => {
  if (bordered) {
    return css`
      /* Override Table border stylesheet */
      border-collapse: collapse;
      border-radius: 4px;
      border-style: hidden; /* hide standard table (collapsed) border */
      box-shadow: 0 0 0 0.5px #c2c9d1; /* this draws the table border  */

      tr:first-child th:first-child {
        border-top-left-radius: 4px;
      }

      tr:first-child th:last-child {
        border-top-right-radius: 4px;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
      }
      /* ---------------------------- */
    `
  }
}

const handleLastChildBorder = (hideLastChildBorder) => {
  if (hideLastChildBorder) {
    return css`
      border-bottom: 0 !important;
    `
  }
}

const TableComponent = styled.table`
  width: 100%;
  tr {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));

    td {
      color: #555770;
      padding: 16px;
    }

    &:last-child {
      th:after {
        bottom: 1px;
        border-bottom: 1px solid #e5e5ef;
      }
    }
  }

  thead > tr,
  tbody > tr {
    border-bottom: 1px solid #e5e5ef;
  }

  th:after,
  th:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  th:before {
    top: -1px;
    border-bottom: 1px solid #e5e5ef;
  }

  tbody > tr {
    ${(props) => handleCursorType(props)};
  }

  ${({ bordered }) => handleBordered(bordered)}

  ${({ sticky }) =>
    sticky &&
    css`
      border: 0 !important;

      thead tr {
        border: 0 !important;

        th {
          z-index: 1000;
          position: sticky;
          top: 0;

          > div {
            // border-bottom: 1px solid #e5e5ef;
          }
        }
      }

      tbody > tr:last-child {
        ${({ hideLastChildBorder }) => handleLastChildBorder(hideLastChildBorder)}
      }

      tr {
        background: #fff;
      }
    `}

  ${space}
  ${layout}
  ${typography}
`

const Table = ({
  wrapperClassName,
  wrapperStyle,
  overflow = 'overlay',
  ...props
}, ref) => {
  const { sticky, width, maxHeight, children, bordered } = props
  const getBorder = () => {
    if (bordered) {
      return '1px solid #e5e5ef'
    }
  }

  const getBorderRadius = () => {
    if (bordered) {
      return 4
    }
  }

  if (sticky) {
    return (
      <Box
        ref={ref}
        overflow={overflow}
        border={getBorder()}
        borderRadius={getBorderRadius()}
        maxHeight={maxHeight}
        width={width}
        className={clsx('table-container-inner', wrapperClassName)}
        style={wrapperStyle}
      >
        <TableComponent {...props} hideLastChildBorder={bordered} bordered={false}>
          {children}
        </TableComponent>
      </Box>
    )
  }

  return (
    <TableComponent {...props} ref={ref}>
      {children}
    </TableComponent>
  )
}

export default forwardRef(Table)
