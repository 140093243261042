import { useMemo } from 'react'
import { TimePicker as AntTimePicker } from 'antd'
import styled, { css } from 'styled-components'

import { Icon, Text } from 'components'

const StyledTimePicker = styled(AntTimePicker)`
  width: 100%;
  border: 1px solid #e5e5ef;
  border-radius: 4px;
  outline: none !important;
  box-shadow: none !important;

  &:hover {
    border: 1px solid #e5e5ef;
  }

  &:focus-within {
    border: 1px solid #003e72;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #bf2600 !important;
    `}
`

const ErrorContainer = styled.div`
  margin-top: 10px;
  display: inline-block;

  svg {
    vertical-align: bottom;
  }
`

const TimePicker = ({ error, onChange, ...props }) => {
  const errorWidget = useMemo(() => {
    return (
      error && (
        <ErrorContainer>
          <Icon name='inputError' />
          <Text color='#bf2600' fontSize={14} ml={12}>
            {error.message}
          </Text>
        </ErrorContainer>
      )
    )
  }, [error])

  return (
    <>
      <StyledTimePicker {...props} error={error} onChange={onChange} />
      {error && errorWidget}
    </>
  )
}

export default TimePicker
