import React from 'react'
import ENUM from 'constants/enums'
import {
  Text
} from 'components'
import { getRoundedPercentDisplay } from 'utils/helper'

function CricketLEDAvailableText({ available, displayType }) {
  function renderAvailable() {
    let ledAvailable = available

    if (displayType !== ENUM.CRICKET_LED_TYPE.PARAPET) {
      ledAvailable = getRoundedPercentDisplay(available)
    }

    return (
      <Text fontSize={12} color='#00A59A'>{ledAvailable}</Text>
    )
  }

  function renderFull() {
    return (
      <Text fontSize={12} color='#A63F3F'>Full</Text>
    )
  }

  return (
    <>
      {
        available > 0
          ? renderAvailable()
          : renderFull()
      }
    </>
  )
}

export default CricketLEDAvailableText
