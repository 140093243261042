import { Controller } from 'react-hook-form'
import { Text, Select as BaseSelect, Icon } from 'components'
import styled, { useTheme } from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

import { get } from 'lodash'
import { clsx } from 'utils/helper'

const Select = styled(BaseSelect)`
  .ant-select-clear {
    right: 16px;
  }
`

const SelectForm = ({
  as,
  name,
  label,
  mode,
  placeholder,
  showSearch,
  initialValue,
  value,
  options,
  showArrow,
  control,
  errors,
  onSelect,
  removeIcon,
  rules,
  enabledRenderError,
  disabled,
  allowClear,
  wrapperClassName,
  loading,
  ...props
}) => {
  const { colors } = useTheme()

  return (
    <>
      {label && (
        <Text display='block' fontSize='12px' fontWeight='500' mb='8px' color={colors.dark500}>
          {label}
        </Text>
      )}
      <Controller
        as={as ?? Select}
        wrapperClassName={clsx('select-field', `field-${name}`)}
        name={name}
        control={control}
        mode={mode}
        showArrow={showArrow ? showArrow : true}
        defaultValue={initialValue}
        value={value}
        placeholder={placeholder}
        bordered={false}
        options={options}
        suffixIcon={loading ? <LoadingOutlined /> : <Icon name='selectDropdown' />}
        onSelect={onSelect ? onSelect : null}
        rules={rules}
        error={get(errors, name)}
        removeIcon={removeIcon}
        showSearch={showSearch}
        enabledRenderError={enabledRenderError}
        disabled={disabled || loading}
        allowClear={allowClear}
        filterOption={(input, option) => {
          let displayText = ''

          if (Array.isArray(option.children)) {
            displayText = option.children[0]
          } else if (typeof option.children === 'string') {
            displayText = option.children
          } else {
            displayText = option.meta?.label
          }

          const label = String(displayText).toLowerCase()
          const txt = input.toLowerCase()

          return label.indexOf(txt) >= 0
        }}
        {...props}
      />
    </>
  )
}

export default SelectForm
