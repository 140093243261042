import { useEffect } from 'react'

export default function useDebounceEffect(effect, deps, wait = 500) {
  useEffect(() => {
    let cleanup = null
    const timeId = setTimeout(() => {
      cleanup = effect()
    }, wait)

    return () => {
      cleanup?.()
      if (timeId) {
        clearTimeout(timeId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, wait])
}
