import { useState, useEffect } from 'react'
import { useRouteState, useRouteStateSearch } from 'utils/hooks'
import {
  Row,
  Box,
  Input,
  Icon,
  Table,
  Checkbox,
  TableHeader
} from 'components'
import TableFilters from './TableFilters'
import get from 'lodash/get'
import { getSortedData, getFilteredData, getFilteredSearchValue } from 'utils/helper'


const FilterableTable = ({
  columns,
  searchKeys,
  filterKeys,
  dataSource,
  initSelectedItems,
  onSelect,
  maxHeight
}) => {
  const [selectedItems, setSelectedItems] = useState(initSelectedItems || [])
  const [sortOrder, setSortOrder] = useState([])
  const [searchTerm, setSearchTerm] = useRouteStateSearch('')
  const [filters, setFilters] = useRouteState({})

  function getTableHeader() {
    let headers = []

    if (isSelectItemEnable()) {
      headers.push(
        <TableHeader
          checkbox
          checked={isSelectedAll()}
          onChange={e => handleSelectedAll(e.target.checked)}
        />
      )
    }

    headers = [
      ...headers,
      ...columns.map(column => {
        return <TableHeader text={column.title} sortKey={column.key} sortOrder={sortOrder} handler={setSortOrder} />
      })
    ]

    return headers
  }

  const handleSelectedAll = (checked) => {
    const ids = getDisplayDataSource()
      .filter(item => {
        if (item && item.disabled) {
          return !item.disabled
        }

        return true
      })
      .map(item => item.id)

    if (checked) {
      setSelectedItems([...new Set([...selectedItems, ...ids])])
    } else {
      setSelectedItems(selectedItems.filter(id => {
        return ids.includes(id) === false
      }))
    }
  }

  const isSelectedAll = () => {
    const fixtureItemsSorted = getDisplayDataSource().filter(item => {
      if (item && item.disabled) {
        return !item.disabled
      }

      return true
    })

    return fixtureItemsSorted.every((element) => {
      return selectedItems.indexOf(element.id) >= 0
    })
  }

  function isSearchable() {
    if (searchKeys) {
      return true
    }

    return false
  }

  function isRecordDisabled(record) {
    if (record && record.disabled) {
      return record.disabled
    }

    return false
  }

  function isSelectItemEnable() {
    if (onSelect) {
      return true
    }

    return false
  }

  const onSelectItem = (id) => {
    let ids = []

    if (selectedItems.includes(id)) {
      ids = selectedItems.filter((value) => value !== id)
    } else {
      selectedItems.push(id)
      ids = [...selectedItems]
    }

    setSelectedItems(ids)
  }

  function getTableBody() {
    return getDisplayDataSource().map(record => {
      const isChecked = selectedItems.includes(record.id)
      return (
        <tr>
          {
            isSelectItemEnable() && (
              <td>
                <Checkbox checked={isChecked} onChange={() => { onSelectItem(record.id) }} disabled={isRecordDisabled(record)} />
              </td>
            )
          }
          {columns.map(column => {
            if (column.dataIndex) {
              const dataIndex = column.dataIndex
              const value = `${get(record, dataIndex)}`

              if (column.render) {
                return <td>{column.render(value, record)}</td>
              }

              return <td>{value}</td>
            }

            return null
          })}
        </tr>
      )
    })
  }

  function getDisplayDataSource() {
    let dataToDisplay = getFilteredSearchValue(dataSource, searchTerm, searchKeys)
    dataToDisplay = getFilteredData(dataToDisplay, filters)
    dataToDisplay = getSortedData(dataToDisplay, sortOrder)

    return dataToDisplay
  }

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems)
    }
  }, [onSelect, selectedItems])

  return (
    <>
      <Row mt='24px'>
        <Box>
          <TableFilters items={dataSource} filterKeys={filterKeys} filters={filters} setFilters={setFilters} />
        </Box>
        {
          isSearchable() && (
            <Box ml='auto'>
              <Input
                width={320}
                placeholder='Search'
                prefix={<Icon name='search' />}
                onChange={(e) => {setSearchTerm(e.target.value)}}
              />
            </Box>
          )
        }
      </Row>
      <Box mt='24px' height={maxHeight || 540}>
        <Table sticky bordered width='100%' maxHeight={maxHeight || 540}>
          <thead>
            <tr>
              {getTableHeader()}
            </tr>
          </thead>
          <tbody>
            {getTableBody()}
          </tbody>
        </Table>
      </Box>
    </>
  )
}

export default FilterableTable
