import styled, { css } from 'styled-components'
import { space, border, layout, typography } from 'styled-system'

import { Box, Text, Icon } from 'components'
import { InputNumber as AntInput } from 'antd'

const handleErrorAppearance = (error) => {
  if (error) {
    return css`
      border: 1px solid #bf2600 !important;
    `
  }
}

const InputBox = styled(AntInput)`
  border-radius: 4px;

  ${({ error }) => handleErrorAppearance(error)}

  ${border}
  ${layout}
  ${typography}

  &.ant-input-number-focused {
    border: 1px solid #003e72 !important;
  }

  .ant-input-number, &.ant-input-number {
    appearance: none;
    color: #444;
    width: 100%;
    font-size: 14px;
    line-height: 1.85;
    border: 1px solid #e5e5ef;
    box-sizing: border-box;
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    ${({ disabled }) => disabled && css`
      background-color: #f7f7f9;
    `}

    box-shadow: none;

    ::-webkit-input-number-placeholder {
      /* WebKit, Blink, Edge */
      color: #babac8;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #babac8;
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #babac8;
      opacity: 1;
    }

    :-ms-input-number-placeholder {
      /* Internet Explorer 10-11 */
      color: #babac8;
    }

    ::-ms-input-number-placeholder {
      /* Microsoft Edge */
      color: #babac8;
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      color: #babac8;
    }
  }

  .ant-input-number-handler-wrap {
    visibility: ${({ arrow }) => arrow ? 'initial' : 'hidden'};
  }

  ${space};
`

const ErrorContainer = styled.div`
  margin-top: 10px;
  display: inline-block;

  svg {
    vertical-align: bottom;
  }
`

const InputNumber = (props) => {
  const { children, onChange, error, enabledRenderError, arrow = false } = props
  const {  width, ...rest } = props

  const renderError = () => {
    if (enabledRenderError === false) {
      return null
    }

    return (
      <ErrorContainer>
        <Icon name='inputError' />
        <Text color='#bf2600' fontSize={14} ml={12}>
          {error.message}
        </Text>
      </ErrorContainer>
    )
  }


  return (
    <Box display='inline-block' width='100%'>
      <InputBox size='large' {...rest} arrow={arrow} onChange={onChange}>
        {children}
      </InputBox>
      {error && renderError()}
    </Box>
  )
}

export default InputNumber
