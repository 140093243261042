import styled from 'styled-components'
import { space, layout } from 'styled-system'

const MakeGoodsLabel = (props) => {
  return (
    <LabelContainer {...props}>
      <Label>Make-Goods</Label>
    </LabelContainer>
  )
}

export default MakeGoodsLabel

const LabelContainer = styled.div`
  margin-top: 4px;

  ${space}
  ${layout}
`

const Label = styled.div`
  color: #949494;
  border: 1px solid #949494;
  padding: 2px 4px;
  border-radius: 4px;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
