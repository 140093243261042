import ENUM from 'constants/enums'

const {
  ENTITLEMENT_STATUS: TYPES,
  ENTITLEMENT_STATUS_DISPLAY: DISPLAYS,
  ENTITLEMENT_STATUS_DESCRIPTION: DESCRIPTIONS,
  ENTITLEMENT_MEDIA_TYPE: MEDIA_TYPES
} = ENUM

const mapperDesc = {
  [`${TYPES.OPEN}${MEDIA_TYPES.MEDIA}${TYPES.HOLD}`]: DESCRIPTIONS.MEDIA_OPEN_TO_HOLD,
  [`${TYPES.OPEN}${MEDIA_TYPES.MEDIA}${TYPES.CONFIRMED}`]: DESCRIPTIONS.MEDIA_OPEN_TO_CONFIRMED,
  [`${TYPES.HOLD}${MEDIA_TYPES.MEDIA}${TYPES.OPEN}`]: DESCRIPTIONS.MEDIA_HOLD_TO_OPEN,
  [`${TYPES.HOLD}${MEDIA_TYPES.MEDIA}${TYPES.CONFIRMED}`]: DESCRIPTIONS.MEDIA_HOLD_TO_CONFIRMED,
  [`${TYPES.CONFIRMED}${MEDIA_TYPES.MEDIA}${TYPES.HOLD}`]: DESCRIPTIONS.MEDIA_CONFIRMED_TO_HOLD,
  [`${TYPES.CONFIRMED}${MEDIA_TYPES.MEDIA}${TYPES.OPEN}`]: DESCRIPTIONS.MEDIA_CONFIRMED_TO_OPEN,

  [`${TYPES.OPEN}${MEDIA_TYPES.NON_MEDIA}${TYPES.CONFIRMED}`]: DESCRIPTIONS.NON_MEDIA_OPEN_TO_CONFIRMED,
  [`${TYPES.CONFIRMED}${MEDIA_TYPES.NON_MEDIA}${TYPES.OPEN}`]: DESCRIPTIONS.NON_MEDIA_CONFIRMED_TO_OPEN,
}

const mapperStatusDisplay = {
  [TYPES.OPEN]: DISPLAYS.OPEN,
  [TYPES.HOLD]: DISPLAYS.HOLD,
  [TYPES.CONFIRMED]: DISPLAYS.CONFIRMED
}

export const getStatusDisplay = (value) => mapperStatusDisplay[value] || ''

export const validateChangeStatus = (previousValue, currentValue, mediaType) => {
  const previousStatus = getStatusDisplay(previousValue)
  const nextStatus = getStatusDisplay(currentValue)

  return {
    title: `Changing Status from ${previousStatus} to ${nextStatus}`,
    description: mapperDesc[`${previousValue}${mediaType}${currentValue}`]
  }
}
