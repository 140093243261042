import { getHostName, getApiEndpoint } from 'utils'

const HOST_NAME = getHostName()
const API_ENDPOINT = getApiEndpoint()

const makeAPIUrls = () => {
  return {
    HOME: API_ENDPOINT + '/',
    PARTNER: API_ENDPOINT + '/partners/',
    PARTNER_CATEGORIES: API_ENDPOINT + '/partner_categories/',
    PARTNER_CATEGORIES_WITH_PARTNER_LIST: API_ENDPOINT + '/partner_categories_with_partner_list/',
    AUTH_TOKEN: `${HOST_NAME}/api-token-auth/`,
    USER_DETAIL: `${API_ENDPOINT}/userdetail/`,
    FIXTURE: `${API_ENDPOINT}/fixtures/`,
    FIXTURE_RETRIEVE: `${API_ENDPOINT}/fixture_retrieve/`,
    FIXTURE_ITEM: `${API_ENDPOINT}/fixture_items/`,
    FIXTURE_ITEM_EDIT: `${API_ENDPOINT}/fixture_item_edits/`,
    FIXTURE_UPLOAD: `${API_ENDPOINT}/fixture_json_uploader/`,
    SCHEDULING_RULE: `${API_ENDPOINT}/scheduling_rules/`,
    RUNDOWN_CHANGELOG: `${API_ENDPOINT}/rundown/changelog/`,
    INVENTORIES: `${API_ENDPOINT}/inventories/`,
    INVENTORY_NOTES: `${API_ENDPOINT}/inventory_notes/`,
    AFL_LED_INVENTORY_NOTES: `${API_ENDPOINT}/afl_led_inventory_notes/`,
    CRICKET_INVENTORY_NOTES: `${API_ENDPOINT}/cricket_inventory_notes/`,
    TEAMVTEAM_INVENTORY_NOTES: `${API_ENDPOINT}/teamvteam_inventory_notes/`,
    INVENTORY_MATCH: `${API_ENDPOINT}/inventories_matches/`,
    ROLES: `${API_ENDPOINT}/roles/`,
    INVITE_USER: `${API_ENDPOINT}/invite_users/`,
    MANAGE_USER: `${API_ENDPOINT}/manage_users/`,
    VENUES: `${API_ENDPOINT}/venues/`,
    SPORT_TEAMS: `${API_ENDPOINT}/sport_teams/`,
    SPORT_TYPES: `${API_ENDPOINT}/sport_types/`,
    SERIES: `${API_ENDPOINT}/series/`,
    PDF_UPLOAD: `${API_ENDPOINT}/pdf_upload/`,
    DISPLAY_TYPE: `${API_ENDPOINT}/display_types/`,
    ENTITLEMENT_TEMPLATE: `${API_ENDPOINT}/entitlement_templates/`,
    TERMS_AND_CONDITIONS: `${API_ENDPOINT}/terms_and_conditions/`,
    TERMS_AND_CONDITIONS_GROUP: `${API_ENDPOINT}/terms_and_conditions/groups/`,
    USER_ACTIVITY: `${API_ENDPOINT}/user_activities/`,
    RUNDOWN_TYPE: `${API_ENDPOINT}/rundown_types/`
  }
}

export default makeAPIUrls()
