import Box from 'components/Box'
import Filter from './Filter'

export default function FilterGroup({
  filters,
  onChange,
  value = {},
  gutter = 16,
  listMaxHeight,
  ...stylingProps
}) {
  const onFilter = (key, values) => {
    onChange?.({ ...value, [key]: values })
  }

  return (
    <Box {...stylingProps}>
      {filters.map(({ filterKey, key, ...filter }) => {
        const name = filterKey || key
        const ownProps = {
          mr: gutter,
          ...filter,
          filterKey: name,
          value: value[name],
          onFilter
        }

        return <Filter {...ownProps} listMaxHeight={listMaxHeight} mb={2} />
      })}
    </Box>
  )
}
