import { useEffect } from 'react'

export default function useOnScrolledBottom(
  callback,
  {
    getContainer = () => document.querySelector('.page-content-wrapper'),
    offsetScreenPercentage = 0,
    deps = []
  }
) {
  useEffect(() => {
    const elContainer = getContainer()

    const scrollListener = () => {
      const { scrollTop, scrollHeight, clientHeight } = elContainer
      const scrolledTop = scrollTop + clientHeight
      const remainScrollHeightPercentage = ((scrollHeight - scrolledTop) / clientHeight) * 100

      if (remainScrollHeightPercentage <= offsetScreenPercentage) {
        callback()
      }
    }

    elContainer?.addEventListener('scroll', scrollListener)

    return () => {
      elContainer?.removeEventListener('scroll', scrollListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, getContainer, offsetScreenPercentage, ...deps])
}
