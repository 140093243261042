import { useEffect, useRef } from 'react'
import useSyncRef from './useSyncRef'

export default function useEventListener(event, listener = () => {}, options) {
  const savedListener = useRef(listener)

  useSyncRef(savedListener, listener)

  useEffect(() => {
    const listener = () => savedListener.current?.()

    window.addEventListener(event, listener, options)
    listener()

    return () => {
      window.removeEventListener(event, listener, options)
    }
  }, [event, options])
}
