const envVariables = {
  development: {
    LIVECHAT_LICENSE: 9688190,
    API_ENDPOINT: 'http://localhost:8000'
  },
  production: {
    LIVECHAT_LICENSE: process.env.REACT_APP_LIVECHAT_LICENSE,
    API_ENDPOINT:
      process.env.REACT_APP_API_ENDPOINT || 'http://54.179.80.230:8000'
  }
}[process.env.NODE_ENV]

Object.keys(envVariables).forEach((key, index) => {
  module.exports[key] = envVariables[key]
})
