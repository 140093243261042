import Decimal from 'decimal.js-light'
import Errors from './error'
import Regex from 'constants/regex'

const errors = Errors()
const INPUT_PATTEN = Regex.input

export const minimum = (min, val, error = errors.minimum) =>
  new Decimal(val).lessThan(min) ? error(min, val) : null

export const maximum = (max, val, error = errors.maximum) =>
  new Decimal(val).greaterThan(max) ? error(max, val) : null

export const step = (st, val, error = errors.step) => {
  const value = new Decimal(val)
  const result = value.modulo(st)
  const prevStep = value.minus(result)
  const nextStep = prevStep.add(st)

  return result.equals(0) ? null : error(st, val, [prevStep, nextStep])
}

export const empty = (bool, val, error = errors.empty) => {
  let result = false
  if (Array.isArray(val)) {
    result = !val.length
  } else if ([null, undefined].includes(val)) {
    result = true
  } else if (typeof val === 'object') {
    result = !Object.keys(val).length
  } else if (typeof val === 'number') {
    result = isNaN(val)
  } else {
    result = !val
  }

  return result === bool ? null : error(bool, val)
}

export const regex = (name, val, error = errors.regex) => {
  const pattern = INPUT_PATTEN[name]

  if (!val) return null
  return pattern.test(val) ? null : error(name, val)
}
