import { useMemo } from 'react'
import { DatePicker as AntDatePicker } from 'antd'

import styled, { css } from 'styled-components'

import { Icon, Text } from 'components'

const StyledDatePicker = styled(AntDatePicker)`
  width: 100%;
  border: 1px solid #e5e5ef;
  border-radius: 4px;
  height: 40px;
  outline: none !important;
  box-shadow: none !important;

  .ant-picker-input {
    input {
      font-size: 14px;
      line-height: 150%;
    }
  }

  &:hover {
    border: 1px solid #e5e5ef;
  }

  &:focus-within {
    border: 1px solid #003e72;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #bf2600 !important;
    `}
`

const ErrorContainer = styled.div`
  margin-top: 10px;
  display: inline-block;

  svg {
    vertical-align: bottom;
  }
`

const DatePicker = ({ error, onChange, allowClear, ...props }) => {
  const errorWidget = useMemo(() => {
    return (
      error && (
        <ErrorContainer>
          <Icon name='inputError' />
          <Text color='#bf2600' fontSize={14} ml={12}>
            {error.message}
          </Text>
        </ErrorContainer>
      )
    )
  }, [error])

  return (
    <>
      <StyledDatePicker
        error={error}
        onChange={onChange}
        allowClear={allowClear}
        {...props}
      />
      {error && errorWidget}
    </>
  )
}

export default DatePicker
