import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

import {
  Icon,
  Box
} from 'components'

const handleAlertType = type => {
  switch (type) {
  case 'success':
    return css`
      border: 1px solid #077E76;
      background: #E9FBFA;
      color: #077E76;
    `
  case 'warning':
    return css`
      border: 1px solid #AA5209;
      background: #FEF3E7;
      color: #AA5209;
    `
  case 'error':
    return css`
      border: 1px solid #862D2D;
      background: #FBEAEA;
      color: #862D2D;
    `
  default:
    return css``
  }
}

const Alert = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  padding: 20px 32px;

  ${({ type }) => handleAlertType(type)};
  
  ${space};
  ${layout};
`

const AlertComponent = (props) => {
  const { message, type, ...rest } = props

  return (
    <Alert type={type} {...rest}>
      <Icon mr='8px' name={type} />
      <Box width='calc(100% - 32px)'>
        {message}
      </Box>
    </Alert>
  )
}

export default AlertComponent