import { useState, useCallback, useEffect } from 'react'

import ClientAPI from 'utils/ClientAPI'

const initialVenues = []

const useSportVenues = (sportId) => {
  const [venues, setVenues] = useState(initialVenues)

  const fetchCricketVenues = useCallback((sportId) => {
    const venueParams = { sport_types: sportId }
    ClientAPI.Venue.list(venueParams)
      .then((res) => res.data)
      .then((data) => setVenues(data))
  }, [])

  useEffect(() => {
    sportId && fetchCricketVenues(sportId)
  }, [fetchCricketVenues, sportId])

  return venues
}

export default useSportVenues
