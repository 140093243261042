import { Route, Redirect } from 'react-router-dom'
import { useAuth } from 'utils/hooks'

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { data: { isAuthenticated } } = useAuth()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...rest} {...props} />
        } else {
          return <Redirect to={{ pathname: '/login' }} />
        }
      }}
    />
  )
}

export default AuthenticatedRoute
