const getSellingMetric = () => {
  return {
    NUMBER_OF_SPOTS_PER_MATCH: 1,
    SHARE_OF_VOICE: 2,
    DURATION: 3,
    PRINT: 4,
    DIGITAL: 5,
    MOMENT: 6,
    OOG_DURATION: 7,
    NONE: 99,
  }
}

const getCustomSchedulingParameter = () => {
  return {
    QUARTER_SELECTION: 1,
    LENGTH_OF_SPOT: 2,
    HALF_SELECTION: 3
  }
}

const getEntitlementTemplateType = () => {
  return {
    NONE: null,
    LED: 1,
    MOMENTS: 2,
    IN_RACE_STATIC: 3,
    OUT_OF_RACE: 4,
    TVC: 5,
    PRODUCTION_AND_NON_MEDIA: 99,
  }
}

export {
  getSellingMetric,
  getCustomSchedulingParameter,
  getEntitlementTemplateType
}