import { Tooltip as AntTooltip } from 'antd'
import styled from 'styled-components'
import { space, layout } from 'styled-system'

const TooltipWrapper = styled.span`
  .ant-tooltip-inner {
    color: #ffffff;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    background-color: #28293d;
    border-radius: 4px;
    padding: 8px;
  }
`

const TooltipAnt = styled(AntTooltip)`
  ${space};
  ${layout};
`

const Tooltip = (props) => {
  const { children } = props

  return (
    <TooltipWrapper>
      <TooltipAnt getPopupContainer={(triggerNode) => triggerNode} {...props}>
        {children}
      </TooltipAnt>
    </TooltipWrapper>
  )
}

export default Tooltip
