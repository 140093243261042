import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

const handleBadgeType = type => {
  switch (type) {
  case 'primary':
    return css`
      background: #EBF1FA;
      color: #003E72;
    `
  default:
    return css``
  }
}

const Badge = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  ${({ type }) => handleBadgeType(type)};

  ${space};
  ${layout};
`

const BadgeComponent = ({type, message, ...rest}) => {
  return (
    <Badge type={type} {...rest}>
      {message}
    </Badge>
  )
}

export default BadgeComponent