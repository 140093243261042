import { AbilityBuilder, Ability } from '@casl/ability'
export const AppAbility = Ability

export default function defineRulesFor(role, id) {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)
  if (role === 'superadmin') {
    can('manage', 'all')
  } else if (role === 'systemadmin') {
    can('manage', 'all')
  } else if (role === 'seniorsales') {
    can(['access', 'create', 'update', 'delete'], ['Partner', 'Contract', 'Entitlement'])
    can('access', ['Fixture', 'Rundown'])
    cannot(['create', 'update'], ['Fixture', 'Rundown'])
    cannot('access', 'AdminSettings')
    cannot('update', 'DisplayType')
  } else if (role === 'sales') {
    can('access', 'Fixture')
    can(['access', 'create'], ['Partner', 'Contract', 'Entitlement'])
    can(['update', 'delete'], ['Partner', 'Contract', 'Entitlement'], { created_by: id })
    cannot(['access', 'create', 'update'], 'Rundown')
    cannot(['create', 'update'], 'Fixture')
    cannot('access', 'AdminSettings')
    cannot('update', 'DisplayType')
  } else if (role === 'scheduler') {
    can('access', ['Partner', 'Contract', 'Entitlement', 'Rundown', 'Fixture'])
    can(['create', 'update'], ['Rundown', 'Fixture'])
    cannot(['create', 'update', 'delete'], ['Partner', 'Contract', 'Entitlement'])
    cannot('access', 'AdminSettings')
    cannot('update', 'DisplayType')
  }

  return rules
}

export function buildAbilityFor(userDetail) {
  return new AppAbility(defineRulesFor(userDetail ? userDetail.role : 'systemadmin', userDetail?.id), {
    detectSubjectType: (object) => {
      return object?.subject_type
    }
  })
}
