import { includes } from 'lodash'
import { css } from 'styled-components'

export const $color = ({ className, theme, color, disabled, disabledColor = '#A4B8C8' }) => {
  let _color
  const isDropdownActive = includes(className, 'ant-dropdown-trigger ant-dropdown-open')

  if (color) {
    _color = color
  }

  if (isDropdownActive) {
    _color = theme.colors.gray800
  }

  if (disabled) {
    _color = disabledColor
  }

  if (_color) {
    return css`
      path {
        fill: ${_color};
      }
    `
  }

  return css``
}

export const $hoverColor = ({ hoverColor }) => {
  if (hoverColor) {
    return css`
      &:hover path {
        fill: ${hoverColor};
      }
    `
  }

  return css``
}

export const $clickable = ({ clickable }) => {
  if (clickable) {
    return css`
      cursor: pointer;
    `
  }

  return css``
}
