import axios from 'axios'

const axiosFactory = () => {
  return axios
}

const Axios = axiosFactory()
Axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Token ${token}`
  }

  return config
})

export { Axios as default }
