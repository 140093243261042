import { forwardRef } from 'react'
import { useTheme } from 'styled-components'
import { useModalHandler } from 'utils/hooks'
import { Box, Icon, Modal } from 'components'

function ConfirmDeleteModal(
  { visible: defaultVisible, loading, title, okText = 'Confirm', children },
  ref
) {
  const { visible, close, onOk } = useModalHandler(defaultVisible, ref)
  const theme = useTheme()

  return (
    <Modal
      onOk={() => onOk?.()}
      title={title}
      okText={okText}
      visible={visible}
      onCancel={close}
      okButtonProps={{ className: 'ant-btn-danger', loading }}
      zIndex={2000}
    >
      <Box style={{ gap: 8, display: 'flex', marginBottom: 24 }}>
        <Icon name='info' color={theme.colors.danger} size={20} />
        <Box>{children}</Box>
      </Box>
    </Modal>
  )
}

export default forwardRef(ConfirmDeleteModal)
