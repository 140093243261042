import { useEffect, useRef, useState } from 'react'
import { useSyncRef } from 'utils/hooks'
import { isOverflowContainerBottom, isOverflowContainerRight } from './helper'

export default function useInScreenPosition({ getPopupContainer, enabled, deps }) {
  const [dropdownStyle, setDropdownStyle] = useState({})

  const savedPopupContainerRef = useRef(getPopupContainer)
  const refs = useRef({
    container: null,
    dropdown: null
  })

  useSyncRef(savedPopupContainerRef, getPopupContainer)

  const register = (name) => (ref) => (refs.current[name] = ref)

  useEffect(() => {
    if (!enabled) return

    const { container: elContainer, dropdown } = refs.current
    const elPopupContainer = savedPopupContainerRef.current?.()

    const listener = () => {
      const boundingContainer = elPopupContainer.getBoundingClientRect()
      const maxContainerHeight = boundingContainer.height
      const maxContainerWidth = boundingContainer.width

      if (elContainer) {
        const elInnerBox = elContainer.childNodes[0]
        const elDropdown = dropdown.element?.current

        const boundingInnerBox = elInnerBox.getBoundingClientRect()
        const boundingDropdown = elDropdown.getBoundingClientRect()
        const OFFSET = 2
        const DROPDOWN_MARGIN_TOP = 4

        let posLeft = boundingInnerBox.left
        let posTop = boundingInnerBox.top + boundingInnerBox.height + OFFSET

        const posDropdownBottom = posTop + boundingDropdown.height
        let posDropdownLeft = posLeft + boundingDropdown.width

        if (isOverflowContainerBottom(maxContainerHeight, posDropdownBottom)) {
          // placement = 'right'
          posTop = boundingInnerBox.top - DROPDOWN_MARGIN_TOP
          posLeft += boundingInnerBox.width + OFFSET
          posDropdownLeft = posLeft + boundingDropdown.width

          if (isOverflowContainerRight(maxContainerWidth, posDropdownLeft)) {
            // placement = 'top'
            posTop = boundingInnerBox.top - boundingDropdown.height - OFFSET
            posLeft = boundingInnerBox.left
          }
        }

        if (isOverflowContainerBottom(maxContainerHeight, posTop + boundingDropdown.height)) {
          posTop = maxContainerHeight - boundingDropdown.height - OFFSET - DROPDOWN_MARGIN_TOP
        }

        setDropdownStyle({
          position: 'fixed',
          background: 'white',
          top: posTop,
          left: posLeft
        })
      }
    }

    listener()
    elPopupContainer.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)

    return () => {
      elPopupContainer.removeEventListener('scroll', listener)
      window.removeEventListener('resize', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, ...deps])

  return {
    register,
    style: dropdownStyle,
    refs
  }
}
