import { Tag as TagAntd } from 'antd'
import styled from 'styled-components'
import { space } from 'styled-system'

const TagComponent = styled(TagAntd)`
    ${space}
`

const Tag = ({ children, color, ...props }) => {
  return (
    <TagComponent color={color} {...props}>
      {children}
    </TagComponent>
  )
}


export default Tag
