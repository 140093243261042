import { Switch as AntSwitch } from 'antd'
import Box from 'components/Box'

import styled, { useTheme } from 'styled-components'
import { space, layout } from 'styled-system'

const Switch = styled(AntSwitch)`
  &.ant-switch-checked {
    background: #003e72;
  }

  ${space};
  ${layout};
`

const SwitchHint = ({ checked, ...styleProps }) => {
  const { colors } = useTheme()

  return (
    <Box as='span' ml='8px' fontWeight={500} fontSize='inherit' {...styleProps}>
      <span style={{ color: checked ? colors.text400 : colors.dark500 }}>N</span>
      <span style={{ color: colors.text400 }}>/</span>
      <span style={{ color: checked ? colors.dark500 : colors.text400 }}>Y</span>
    </Box>
  )
}

Switch.Hint = SwitchHint
export default Switch
