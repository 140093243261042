import Box from 'components/Box'
import Icon from 'components/Icon'
import { useTheme } from 'styled-components'

export default function EmptyActivity(props) {
  const { colors } = useTheme()

  return (
    <Box {...props}>
      <Box textAlign='center' mb='16px'>
        <Icon name='recentVisit' />
      </Box>
      <Box
        textAlign='center'
        mb='8px'
        fontWeight={500}
        fontSize={16}
        lineHeight={1.5}
        color={colors.text600}
      >
        No recent activity
      </Box>
      <Box
        textAlign='center'
        fontWeight={500}
        fontSize={14}
        lineHeight={1.5}
        color={colors.text600}
      >
        There is no recent activity
      </Box>
    </Box>
  )
}
