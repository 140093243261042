import { useState, useImperativeHandle, forwardRef } from 'react'
import { Box, Modal } from 'components'

const ConfirmModal = ({
  keyboard = false,
  maskClosable = false,
  okText = 'Confirm',
  cancelText = 'Cancel',
  okButtonProps,
  cancelButtonProps,
  afterClose
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [onOk, setOnOk] = useState(null)

  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
    setTitle: (title) => setTitle(title),
    setBody: (body) => setBody(body),
    setOnOk: (okFunc) => setOnOk(okFunc),
  }))

  return (
    <Modal
      keyboard={keyboard}
      maskClosable={maskClosable}
      centered={true}
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={() => setVisible(false)}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      afterClose={afterClose}
    >
      <Box>{body}</Box>
    </Modal>
  )
}

export default forwardRef(ConfirmModal)
