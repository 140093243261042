import { Errors, Validate } from './resource'
import pickBy from 'lodash/pickBy'

const errors = Errors()

export const getRuleNames = (expectSchemas) => {
  const customRuleNames = []
  const defaultRuleNames = []

  for (const key of Object.keys(expectSchemas)) {
    if (key in Validate) {
      customRuleNames.push(key)
    } else {
      defaultRuleNames.push(key)
    }
  }

  return {
    customRuleNames,
    defaultRuleNames
  }
}

export const getDefaultRules = (expectValuesSchemas = {}, defaultRuleNames = []) =>
  pickBy(expectValuesSchemas, (_, key) => defaultRuleNames.includes(key))

export const getRequiredError = (required, customErrors) => {
  if (typeof required === 'boolean') {
    if (required) {
      const error = customErrors['required'] || errors.required
      return error?.()
    }
  }

  return required
}

export const isNilInput = (input) => ['', null, undefined].includes(input)
