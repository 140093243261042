import styled, { useTheme } from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import Box from 'components/Box'
import Text from 'components/Text'
import Icon from 'components/Icon'

export const FILE_STATUS = {
  COMPLETE: 'completed',
  PENDING: 'pending',
  FAIL: 'failed'
}

export const ListFile = styled.div`
  max-height: 150px;
  overflow-y: auto;
  margin-top: 14px;

  .list-item {
    margin-bottom: 14px;
  }

  .list-item:last-child {
    margin-bottom: 0;
  }
`

function ListFileItem({ status, children, onRemove, style }) {
  const { colors } = useTheme()

  let color = colors.text800

  if (status === FILE_STATUS.FAIL) {
    color = colors.danger
  }

  if (status === FILE_STATUS.PENDING) {
    color = colors.gray400
  }

  const widgetIcon = () => {
    if (status === FILE_STATUS.PENDING) {
      return <LoadingOutlined style={{ marginRight: 4 }} />
    }

    return <Icon name='uploaded' color={color} />
  }

  return (
    <Box className='list-item center-y justify-between' color={color} style={style}>
      <Box className='center-y'>
        {widgetIcon()}
        <Text ml={'4px'}>{children}</Text>
      </Box>
      <Icon name='delete' color={color} clickable hoverColor={colors.danger} onClick={onRemove} />
    </Box>
  )
}

ListFile.Item = ListFileItem

export default ListFile
