import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSyncRefValue from './useSyncRef'

import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { getQueryParams } from 'utils/helper'

const COMMON_ROUTE_STATE_NAMES = ['search', 'show_past']

const getInitialState = (initAction, search, { ignoreParamNames, keys }) => {
  if (!search) {
    return typeof initAction === 'function' ? initAction() : initAction
  }

  const searchParams = new URLSearchParams(search)
  const urlState = {}
  for (const [key, value] of searchParams.entries()) {
    const isForbiddenName = [...COMMON_ROUTE_STATE_NAMES, ...ignoreParamNames].includes(key)
    const isTargetKey = Array.isArray(keys) && keys.includes(key)

    if (!isTargetKey && isForbiddenName) continue
    if (keys.length && !isTargetKey) continue

    let val = value?.split?.(',')
    urlState[key] = val
  }

  return urlState
}

const validate = (state, prevState) => {
  const params = {}

  for (const [key, value] of Object.entries(state)) {
    if (Array.isArray(value) && value.length) {
      params[key] = value
      continue
    }
  }

  return {
    isValid: !isEmpty(params),
    params
  }
}

export default function useRouteState(initialState, { ignoreParamNames = [], keys = [] } = {}) {
  const { replace } = useHistory()
  const { search } = useLocation()
  const savedOptions = useRef({ ignoreParamNames, keys })

  const [state, setState] = useState(getInitialState(initialState, search, savedOptions.current))
  const mounted = useRef(false)
  const savedState = useRef(state)
  const savedSearch = useRef(search)

  useSyncRefValue(savedSearch, search)

  useEffect(() => {
    if (mounted.current && typeof state === 'object') {
      const { current: search } = savedSearch
      const { current: prevState } = savedState
      const { isValid, params } = validate(state, prevState)
      const notStateParams = omit(getQueryParams(search), Object.keys({ ...state, ...prevState }))

      if (isValid) {
        const queryString = new URLSearchParams({ ...notStateParams, ...params }).toString()
        savedState.current = state
        replace({ search: queryString })
        return
      }

      const queryString = new URLSearchParams(notStateParams).toString()
      savedState.current = state
      replace({ search: queryString })
    }
  }, [state, replace])

  useEffect(() => {
    mounted.current = true
  }, [])

  return [state, setState]
}
