import Moment from 'moment'
import _ from 'lodash'

import { Filter, FilterDate } from 'components'

const TableFilters = ({ items, filterKeys, filters, setFilters }) => {

  const handleOnFilter = (filterKey, checkedList) => {
    setFilters({ ...filters, ...{ [filterKey]: checkedList } })
  }

  const handleOnFilterDate = (filterKey, startDate, endDate) => {
    if (startDate) {
      setFilters({ ...filters, ...{ [filterKey]: [startDate.format('YYYY-MM-DD')] }})
    } else {
      setFilters({ ...filters, ...{ [filterKey]: [] }})
    }
  }

  function getValidDateRange(key) {
    let dateList = [...new Set(items.map((item) => `${item[key]}`))].sort()

    return {
      start: Moment(dateList[0]),
      end: Moment(dateList[dateList.length - 1])
    }
  }

  function getFilterOptions(key) {
    return [...new Set(items.map((item) => `${_.get(item, key)}`))].sort()
  }

  if (filterKeys) {
    return filterKeys.map(filter => {
      const filterText = filter.text
      const filterKey = filter.key
      const filterType = filter.type

      if (filterType === 'date') {
        return (
          <FilterDate
            mr='12px'
            mb='8px'
            text={filterText}
            format={'DD MMM YYYY'}
            filterKey={filterKey}
            value={filters[filterKey]}
            validDateRange={getValidDateRange(filterKey)}
            onFilter={handleOnFilterDate}
          />
        )
      }
      return (
        <Filter
          mr='12px'
          mb='8px'
          text={filterText}
          options={getFilterOptions(filterKey)}
          filterKey={filterKey}
          value={filters[filterKey]}
          onFilter={handleOnFilter}
        />
      )
    })
  }

  return null

}
export default TableFilters
