import { useState } from 'react'
import useEventListener from './useEventListener'

// max-width query
export default function useResponsiveValue(defaultValue, dictionary = {}) {
  const [value, setValue] = useState(defaultValue)

  useEventListener('resize', () => {
    const width = window.innerWidth

    const sortedKeys = Object.keys(dictionary)
      .map(Number)
      .sort((a, b) => a - b)

    let matchedValue

    // eslint-disable-next-line no-restricted-syntax
    for (const breakpoint of sortedKeys) {
      if (matchedValue === undefined && width <= breakpoint) {
        matchedValue = dictionary[breakpoint]
        break
      }
    }

    setValue(matchedValue ?? defaultValue)
  })

  return value
}
