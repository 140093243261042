import Box from 'components/Box'
import Icon from 'components/Icon'

export default function EmptyRecent(props) {
  return (
    <Box {...props}>
      <Box textAlign='center' mb='16px'>
        <Icon name='recentActivity' />
      </Box>
      <Box
        textAlign='center'
        mb='8px'
        fontWeight={500}
        fontSize={16}
        lineHeight={1.5}
        color='#555770'
      >
        No most recent visit
      </Box>
      <Box textAlign='center' fontWeight={500} fontSize={14} lineHeight={1.5} color='#555770'>
        There is no visit page
      </Box>
    </Box>
  )
}
