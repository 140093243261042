import { Tabs as AntTabs } from 'antd'
import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

const { TabPane: AntTabPane } = AntTabs


const TabPane = styled(AntTabPane)`
  ${space};
  ${layout};

  ${({ border }) => border && css`
    padding: 0 8px 8px 8px;
    border: 1px solid #e5e5ef;
    border-top: none;
  `}
`

export default TabPane
