import styled from 'styled-components'
import { space, layout } from 'styled-system'


const HrWrapper = styled.hr`
  border: none;
  border-top: 1px solid #E5E5EF;

  ${space}
  ${layout}
`

const Hr = (props) => {
  return (
    <HrWrapper {...props} />
  )
}

export default Hr
