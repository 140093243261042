export const initialState = {
  isAuthenticated: false,
  isAnonymous: true,
  isLoading: true,
  userDetail: null
}

export const AuthReducer = (state, action) => {
  switch (action.type) {
  case 'REQUEST_AUTH':
    return {
      ...state,
      isLoading: true
    }
  case 'AUTH_SUCCESS':
    return {
      ...state,
      userDetail: action.payload.user || {},
      isLoading: false,
      isAuthenticated: true,
      isAnonymous: false
    }
  case 'LOGOUT':
    return {
      ...state,
      userDetail: null,
      isAuthenticated: false,
      isAnonymous: true
    }
  case 'AUTH_FINISH':
    return {
      ...state,
      isLoading: false
    }
  case 'AUTH_ERROR':
    return {
      ...state,
      isLoading: false,
      isAuthenticated: false,
      isAnonymous: true,
      userDetail: null
    }

  default:
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}
