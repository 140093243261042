import { useState } from 'react'
import { useRouteState, useRouteStateSearch } from 'utils/hooks'
import map from 'lodash/map'

export default function useCustomState(filterKeys, enabled) {
  const [input, setInput] = useState('')
  const [state, setState] = useState({})

  const [urlSearch, setUrlSearch] = useRouteStateSearch('')
  const [queries, setQueries] = useRouteState({}, { keys: map(filterKeys, 'key') })

  return {
    searchTerm: enabled ? urlSearch : input,
    filters: enabled ? queries : state,
    setSearchTerm: enabled ? setUrlSearch : setInput,
    setFilters: enabled ? setQueries : setState
  }
}
