import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSyncRefValue from './useSyncRef'

import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import { getQueryParams } from 'utils/helper'

const getInitialState = (initAction, name, search = '', dataType = 'string') => {
  const defaultValue = typeof initAction === 'function' ? initAction() : initAction

  if (!(name in getQueryParams(search))) {
    return defaultValue
  }

  const { [name]: value } = getQueryParams(search)
  const val = value || defaultValue
  switch (dataType) {
  case 'boolean':
    return String(val) === 'true'
  case 'array':
    return String(val).split(',')
  default:
    return val
  }
}

export default function useRouteStateValue(
  name = 'search',
  initialState,
  { delay = 300, dataType = 'string', initialize = false } = {}
) {
  const savedOptions = useRef({ name, delay, dataType, initialize, initialState })
  const { replace } = useHistory()
  const { search } = useLocation()
  const [value, setValue] = useState(getInitialState(initialState, name, search, dataType))

  const mounted = useRef(false)
  const savedSearch = useRef(search)
  const timeId = useRef()

  useSyncRefValue(savedSearch, search)

  useEffect(() => {
    if (mounted.current) {
      const { current: { dataType, delay, name } } = savedOptions
      const { current: search } = savedSearch

      const execute = () => {
        const params = omit(getQueryParams(search), [name])
        const isValueEmpty = dataType === 'array' && Array.isArray(value) ? !value.length : !value

        if (isEmpty(params) && isValueEmpty) {
          replace({ search: '' })
          return
        }

        if (!isValueEmpty) {
          params[name] = value
        }
        const searchParams = new URLSearchParams(params)
        replace({ search: searchParams.toString() })
      }

      if(delay) {
        timeId.current = setTimeout(execute, delay)
      } else {
        execute()
      }

    }

    return () => {
      if (timeId.current) {
        clearTimeout(timeId.current)
      }
    }
  }, [value, replace])

  useEffect(() => {
    mounted.current = true
    const { current: { name, initialize, initialState } } = savedOptions
    const { current: search } = savedSearch
    const params = new URLSearchParams(search)

    if(initialize && !params.has(name)) {
      const defaultValue = typeof initialState === 'function' ? initialState() : initialState
      params.set(name, defaultValue)
      replace({ search: params.toString() })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [value, setValue]
}

export const useRouteStateSearch = (initialState) => {
  return useRouteStateValue('search', initialState, { delay: 300, dataType: 'string' })
}

export const useRouteStateShowPast = (initialState) => {
  return useRouteStateValue('show_past', initialState, { delay: 0, dataType: 'boolean' })
}
