import { Modal } from 'components'

const FormModal = ({
  okButtonProps,
  formId,
  children,
  onSubmit,
  onCancel,
  loading,
  keyboard = false,
  maskClosable = false,
  ...props
}) => {
  return (
    <Modal
      cancelButtonProps={{ loading }}
      {...props}
      maskClosable={maskClosable}
      keyboard={keyboard}
      okButtonProps={{
        ...okButtonProps,
        loading,
        form: String(formId),
        key: 'submit',
        htmlType: 'submit'
      }}
      onOk={() => {}}
      onCancel={onCancel}
    >
      <form id={formId} onSubmit={onSubmit}>
        {children}
      </form>
    </Modal>
  )
}

export default FormModal
