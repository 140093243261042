import { useMemo } from 'react'

import {
  isAFL as helperIsAfl,
  isCricket as helperIsCricket,
  isVRC as helperIsVrc,
  isTVC as helperIsTvc,
  isOtherSport as helperIsOtherSport,
  isStandardFixture as helperIsStandardFixture,
  isBasicFixture as helperIsBasicFixture,
  isTeamVTeamFixture as helperTeamVTeamFixture
} from 'utils/helper'

const useSportTypes = ({ sportTypeValue, fixtureTypeValue }) => {
  return useMemo(() => {
    const helpers = {
      isAFL: () => helperIsAfl(sportTypeValue),
      isCricket: () => helperIsCricket(sportTypeValue),
      isVRC: () => helperIsVrc(sportTypeValue),
      isTVC: () => helperIsTvc(sportTypeValue),
      isOtherSport: () => helperIsOtherSport(sportTypeValue),
      isStandardFixture: () => helperIsStandardFixture(fixtureTypeValue),
      isBasicFixture: () => helperIsBasicFixture(fixtureTypeValue),
      isTeamVTeamFixture: () => helperTeamVTeamFixture(fixtureTypeValue)
    }

    return {
      ...helpers,
      is: {
        AFL: helpers.isAFL(),
        Cricket: helpers.isCricket(),
        VRC: helpers.isVRC(),
        TVC: helpers.isTVC(),
        OtherSport: helpers.isOtherSport(),
        StandardFixture: helpers.isStandardFixture(),
        BasicFixture: helpers.isBasicFixture(),
        TeamVTeamFixture: helpers.isTeamVTeamFixture()
      }
    }
  }, [sportTypeValue, fixtureTypeValue])
}

export default useSportTypes
