export const getGender = () => {
  return {
    MALE: 'M',
    FEMALE: 'W'
  }
}

export const getSeriesType = () => {
  return {
    TEST: 'Test',
    ODI: 'ODI',
    T20: 'T20'
  }
}
