import React from 'react'

import {
  Text,
  Box
} from 'components'

function HexColorBox({
  hexColor,
  label
}) {
  return (
    <Text verticalAlign='middle'>
      <Box display='flex' alignItems='center'>
        <Box width='16px' height='16px' bg={hexColor} mr='8px'/>
        {label}
      </Box>
    </Text>
  )
}

export default HexColorBox