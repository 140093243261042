import { AbilityContext } from 'utils/can'
import { buildAbilityFor } from 'config/ability'
import { useAuth } from 'utils/hooks'

const AbilityContextProvider = ({ children }) => {
  const { data:{ userDetail } } = useAuth()
  const ability = buildAbilityFor(userDetail)

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

export default AbilityContextProvider
