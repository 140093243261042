import { useState, useCallback, useEffect } from 'react'

import ClientAPI from 'utils/ClientAPI'

const initialTeams = []

const useSportTeams = (sportId) => {
  const [teams, setTeams] = useState(initialTeams)

  const fetchCricketSportTeams = useCallback((sportId) => {
    ClientAPI.SportTeam.list(sportId).then((res) => {
      setTeams(res.data)
    })
  }, [])

  useEffect(() => {
    sportId && fetchCricketSportTeams(sportId)
  }, [fetchCricketSportTeams, sportId])

  return teams
}

export default useSportTeams
