const getCompetitionType = () => {
  return {
    HOME_AND_AWAY: 1,
    FINALS: 2,
    CRICKET: 3,
    ELIMINATION_QUALIFY: 4,
    SEMI: 5,
    PRELIMINARY: 6,
    GRAND: 7,
    VRC_STANDARD: 8,
    VRC_MELBOURNE_CARNIVAL: 9,
    BASIC: 10,
    STANDARD: 11
  }
}

export { getCompetitionType }
