/* eslint-disable indent */
import { useState } from 'react'
import useEventListener from './useEventListener'

const useWindowSize = ({ useBreakpointAntD = true } = {}) => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  })

  useEventListener('resize', () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  })

  return windowSize
}

export default useWindowSize
