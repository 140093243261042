import styled from 'styled-components'
import Loading from 'assets/icon/loading.gif'

const SpinnerContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  user-select: none;
  z-index: 2000;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s;
`

const Spinner = () => {
  return (
    <SpinnerContainer>
      <img height='65' width='65' src={Loading} alt='loading' />
    </SpinnerContainer>
  )
}

export default Spinner