export const classNames = {
  row: 'ant-table-row ant-table-row-level-0 nested-table-row',
  cell: 'ant-table-cell nested-table-cell'
}

export const TRow = ({ index, background, className, children, nestedChildren, ...props }) => {
  return (
    <>
      <tr style={{ background }} className={className} {...props} index={index}>
        {children}
      </tr>
      {nestedChildren}
    </>
  )
}
