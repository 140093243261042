import Box from 'components/Box'
import Icon from 'components/Icon'
import React from 'react'

export default function Empty({ description, ...props }) {
  if (!description)
    return (
      <Box textAlign='center' mb='16px'>
        <Icon name='schedulingEmpty' />
      </Box>
    )

  return (
    <Box {...props}>
      <Box textAlign='center' mb='16px'>
        <Icon name='schedulingEmpty' />
      </Box>
      <Box
        textAlign='center'
        width='100%'
        fontWeight={500}
        fontSize={16}
        lineHeight={1.5}
        color='#555770'
      >
        {description}
      </Box>
    </Box>
  )
}
