import { useState, useCallback } from 'react'
import useEventListener from './useEventListener'

const useRemainWindowHeight = (
  nodeRef,
  defaultHeight,
  { deps = [], ...opts } = { minHeight: 200, offset: 0 }
) => {
  const [height, setHeight] = useState({ height: defaultHeight, maxHeight: defaultHeight })

  const computeHeight = useCallback(
    (element) => {
      setHeight(() => {
        let _height = defaultHeight
        const { offset = 0, minHeight = 200 } = opts
        if (element) {
          const rect = element.getBoundingClientRect()
          _height = window.innerHeight - rect.top - offset
        }

        if (_height < minHeight) {
          _height = minHeight
        }

        return _height
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultHeight, opts.minHeight, opts.offset, ...deps]
  )

  useEventListener('resize', () => {
    computeHeight(nodeRef.current)
  })

  return height
}

export default useRemainWindowHeight
