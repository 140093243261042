import { Controller } from 'react-hook-form'
import { TimePicker, Text } from 'components'

import { get } from 'lodash'

const TimePickerForm = ({
  label,
  name,
  control,
  errors,
  defaultValue,
  value: fieldValue,
  rules,
  format,
  ...props
}) => {
  return (
    <>
      <Text fontSize='12px' fontWeight='500' mb='8px'>
        {label}
      </Text>
      <Controller
        render={({ onChange, value }, { invalid }) => {
          const error = invalid

          return (
            <TimePicker
              size='large'
              hideDisabledOptions
              format={format || 'HH:mm'}
              allowClear={false}
              value={value || defaultValue || fieldValue}
              defaultValue={value || defaultValue || fieldValue}
              onChange={(time) => {
                onChange(time)
              }}
              onSelect={(time) => {
                onChange(time)
              }}
              error={error}
              {...props}
            />
          )
        }}
        name={name}
        control={control}
        error={get(errors, name)}
        value={defaultValue || fieldValue}
        defaultValue={defaultValue || fieldValue}
        rules={rules ? rules : {}}
      />
    </>
  )
}

export default TimePickerForm
