import styled, { css } from 'styled-components'
import { color, typography, space } from 'styled-system'

const handleHoverColor = (hoverStyle) => {
  if (hoverStyle) {
    return css`
      ${hoverStyle}
    `
  }
}

const A = styled.a`
  color: #003e72;

  &:hover {
    color: #00325f;

    ${({ hoverStyle }) => handleHoverColor(hoverStyle)}
  }

  ${color}
  ${typography}
  ${space}
`
export default A
