import useDebounceEffect from './useDebounceEffect'

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current?.contains?.(e.target)) {
      callback(e)
    }
  }

  useDebounceEffect(
    () => {
      document.addEventListener('click', handleClick)

      return () => {
        document.removeEventListener('click', handleClick)
      }
    },
    [handleClick],
    300
  )
}

export default useOutsideClick
