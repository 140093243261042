import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'utils/hooks'

import styled from 'styled-components'

import {
  Box,
  Text,
  Icon,
  Dropdown,
  Menu
} from 'components'

const NavbarComponent = styled.nav`
  --height: 60px;
  --i-size: 40px;

  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);
  background: #fff;
  border-bottom: 1px solid #E5E5EF;

  .ant-dropdown-link {
    display: flex;
    align-items: center;
  }

  .profile-icon {
    display: inline-block;
    background-size: cover;
    border-radius: 20px;
    background-image: url(${props => props?.profileImage || ''});
    width: var(--i-size);
    height: var(--i-size);
  }
`

const Navbar = () => {
  const auth = useAuth()

  function getUserMenu() {
    return (
      <Menu>
        <Menu.Item>
          <Link to='/logout'>Logout</Link>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <NavbarComponent profileImage={auth.data.userDetail?.profile_picture_url}>
      <Box display='flex' alignItems='center' justifyContent='flex-end' p='0px 26px' height='100%'>
        <Dropdown overlay={getUserMenu()} trigger={['click']}>
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            <Text fontSize='14px' color='#003E72' mr='20px'>{`${auth.data.userDetail?.firstname} ${auth.data.userDetail?.lastname}`}</Text>
            {auth.data.userDetail?.profile_picture_url && <Box className='profile-icon' />}
            {!auth.data.userDetail?.profile_picture_url && <Icon name='userProfile' />}
            <Icon name='chevronDown' ml='14px' />
          </a>
        </Dropdown>
      </Box>
    </NavbarComponent>
  )
}

export default Navbar
