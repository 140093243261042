import { useHistory } from 'react-router-dom'
import Link from 'components/Link'
import styled from 'styled-components'
import { color, typography, space, layout, border } from 'styled-system'

const Td = styled.td`
  word-break: ${({ wordBreak = 'break-word' }) => wordBreak};
  ${space}
  ${typography}
  ${color}
  ${layout}
  ${border}
`

export default function TableTd({ link, renderATag = true, replaceHistory, children, ...props }) {
  const history = useHistory()

  if (['object', 'string'].includes(typeof link) && link) {
    return (
      <Td
        {...props}
        style={{ cursor: 'pointer', ...props.style }}
        onClick={(e) => {
          e.stopPropagation()
          renderATag && history.push(link)
        }}
      >
        {renderATag ? (
          <Link to={link} replace={replaceHistory} onClick={(e) => e.stopPropagation()}>
            {children}
          </Link>
        ) : (
          children
        )}
      </Td>
    )
  }

  return <Td {...props}>{children}</Td>
}
