const BREAKPOINT = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1600
}

const BREAKPOINT_ANTD = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

const DEFAULT_MODULE_SETTING = {
  breakpoints: BREAKPOINT,
  useBreakpointAntD: false,
  isMobileFirst: true
}

const isEmpty = (obj) => {
  const arr = Object.keys(obj)
  return !arr.length
}

const getSize = ({ useBreakpointAntD, breakpoints }, breakpoint) => {
  const defaultBreakpoints = useBreakpointAntD ? BREAKPOINT_ANTD : BREAKPOINT
  const _breakpoints = isEmpty(breakpoints) ? defaultBreakpoints : breakpoints
  let _size = _breakpoints[breakpoint]

  if (_size) {
    return [_size, _breakpoints]
  }

  _size = defaultBreakpoints[breakpoint]
  if (_size) {
    return [_size, defaultBreakpoints]
  }

  return [breakpoint, _breakpoints]
}

function mq(breakpoint) {
  const { isMobileFirst, ...settings } = mq.defaults
  const [size, breakpoints] = getSize(settings, breakpoint)

  return {
    get currentBreakpoints() {
      return breakpoints
    },
    get size() {
      return size
    },
    get css(){
      return `@media (min-width: ${this.size}px)`
    },
    rule(sizing = isMobileFirst ? 'min-width' : 'max-width') {
      return `@media (${sizing}: ${this.size}px)`
    }
  }
}

mq.defaults = new Proxy(DEFAULT_MODULE_SETTING, {
  set(target, key, value) {
    if (key in target) {
      target[key] = value
      return true
    }

    throw Error(`Invalid setting key: ${key}`)
  }
})

mq.breakpoint = function (width, opts = { useBreakpointAntD: false }) {
  let { breakpoints } = this.defaults
  if (opts?.useBreakpointAntD) {
    breakpoints = BREAKPOINT_ANTD
  }

  const sortedKeys = Object.keys(breakpoints)
    .map((key) => ({ breakpoint: key, maxWidth: breakpoints[key] }))
    .sort((a, b) => a.maxWidth - b.maxWidth)

  let matchedBreakpoint

  // eslint-disable-next-line no-restricted-syntax
  for (const { breakpoint, maxWidth } of sortedKeys) {
    if (!matchedBreakpoint && width <= maxWidth) {
      matchedBreakpoint = breakpoint
      break
    }
  }

  return matchedBreakpoint
}

export default mq
