import { Box } from 'components'
import styled from 'styled-components'
import IMG_LOADING from 'assets/icon/loading.gif'

export const ZOOM_IN_SCALE = 0.8
export const ZOOM_OUT_SCALE = 2.3

export const CanvasContainer = styled(Box)`
  max-height: ${({ height }) => `${height}px`};
  overflow: ${({ scale }) => (scale === ZOOM_IN_SCALE ? 'hidden' : 'auto')};
  cursor: ${({ scale }) => (scale === ZOOM_IN_SCALE ? 'zoom-in' : 'zoom-out')};

  .react-pdf__Page {
    text-align: center;

    canvas {
      display: inline-block !important;
    }

    .react-pdf__Page__annotations,
    .react-pdf__Page__textContent {
      display: none;
    }
  }
`

export const ThumbnailContainer = styled(Box)`
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;

  .react-pdf__Page {
    .react-pdf__Page__annotations {
      display: none;
    }
  }

  &.active {
    border: 1px solid #003e72;
  }

  .page-number {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-size: 8px;
    font-weight: 400;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 50%;
    transform: translateY(50%);

    &:hover {
      background: #28293d;
      opacity: 0.5;
    }
  }
`

export const Spinner = ({ height }) => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    backgroundColor='#F7F7F9'
    opacity='90%'
    width='100%'
    height={height}
    top={0}
  >
    <img height='65' width='65' src={IMG_LOADING} alt='loading' />
  </Box>
)
