const getRundownItemSection = () => {
  return {
    SOLD: 1,
    PREFERENCE_BONUS: 2,
    BONUS: 3,
    OTHER: 98,
    RESTART_OVER: 99
  }
}

const getRundownStatus = () => {
  return {
    PENDING: 1,
    OPEN: 2,
    CLOSED: 3,
    ACTIVE: 4
  }
}

const getRundownAssetType = () => {
  return {
    HIDDEN: 0,
    FULL_DOM: 1,
    GOAL: 2,
    WING: 3,
    PGI: 4,
    MAI: 5,
    FIVE_MW: 6,
    TIME_ON: 7,
    FIRST_BOUNCE: 8,
    MOMENT_SCHEDULED: 9
  }
}

const getRundownVersionStatus = () => {
  return {
    CREATED: 1,
    EDITED: 2,
    CONFIRMED: 3,
    EXPORTED_XLSX: 4,
    EXPORTED_PDF: 5,
    RESTORED: 6,
    ACTIVE: 7,
    REFRESHED: 8
  }
}

const getRundownItemVersionStatus = () => {
  return {
    AUTO: 1,
    ORDER: 2,
    CATEGORY: 3,
    CREATIVE: 4,
    CONTENT: 5
  }
}

const getRundownAssetTypeDisplay = () => {
  return {
    FULL_DOM: 'Full Dom',
    FULL_LED: 'Full LED',
    GOAL: 'Goal',
    WING: 'Wing',
    PGI: 'PGI',
    FIRST_BOUNCE: 'First Bounce'
  }
}

const getReplaceSpotType = () => {
  return {
    SINGLE_SPOT: 'single-spot',
    SAME_PARTNER: 'same-partner'
  }
}

const getCricketRundownItemPlayStatus = () => {
  return {
    SCHEDULED: 1,
    PLAYED: 2,
    OUTSTANDING: 3,
    OMIT: 4,
    LEFT_PLAYED_RIGHT_OMIT: 5,
    LEFT_OMIT_RIGHT_PLAYED: 6,
    CLOSE_MATCH_EARLY: 7
  }
}

export {
  getRundownItemSection,
  getRundownStatus,
  getRundownAssetType,
  getRundownVersionStatus,
  getRundownItemVersionStatus,
  getReplaceSpotType,
  getRundownAssetTypeDisplay,
  getCricketRundownItemPlayStatus
}
