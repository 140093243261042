const regex = {
  input: {
    image: /^[\w\d-_.]+\.[\w\d]+$/,
    phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/,
    hex: /^#[0-9A-F]{6}$/,
    url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
    abbreviation: /^[0-9A-Z]{1,5}$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  misc: {
    squareBracket: /\s\[(.*?)\]/,
    lastSquareBracket: /\s\[([^\]]+)\]$/g
  }
}

export default regex
