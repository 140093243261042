import { notification } from 'antd'
import { Icon } from 'components'

const openSuccessNotification = (desc) => {
  const key = `open${Date.now()}`
  notification.error({
    key,
    description: desc,
    icon: <Icon name='uploadSuccess' size={24} />,
    closeIcon: <div />,
    placement: 'bottomLeft',
    bottom: 40,
    style: {
      width: '326px',
      left: '40px',
      padding: '20px 32px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#077E76',
      background: '#E9FBFA',
      border: '1px solid #077E76',
      boxSizing: 'border-box',
      borderRadius: '4px',
    }
  })
}

const openFailNotification = (desc) => {
  const key = `open${Date.now()}`
  notification.error({
    key,
    description: desc,
    icon: <Icon name='uploadError' size={24} />,
    closeIcon: <div />,
    placement: 'bottomLeft',
    bottom: 40,
    style: {
      width: '326px',
      left: '40px',
      padding: '20px 32px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#862D2D',
      background: '#FBEAEA',
      border: '1px solid #862D2D',
      boxSizing: 'border-box',
      borderRadius: '4px',
    }
  })
}

const noti = (type, desc) => {
  switch (type) {
  case 'success':
    return openSuccessNotification(desc)
  case 'fail':
    return openFailNotification(desc)
  default:
    return ''
  }
}
export default noti
