import Decimal from 'decimal.js-light'
import { reduce, isNumber } from 'lodash'

// default global decimal instance configuration
Decimal.config({
  precision: 20,
  rounding: Decimal.ROUND_HALF_UP,
})

const numberDigit = (number, digit = 2) => Decimal(number).todp(digit).toNumber()
const decimalToNumber = (decimalNumber, digit = 2) => decimalNumber.todp(digit).toNumber()
const numberToString  = (number, digit = 1) => numberDigit(number, digit).toFixed(digit)

const decimalSum = (x1, x2, config = {}) => {
  let D = Decimal

  if (config?.decimal) {
    D = Decimal.set({ ...config?.decimal })
  }

  let x = new D(x1)
  let y = new D(x2)

  if (isNumber(config?.digit)) {
    return decimalToNumber(x.plus(y), config?.digit)
  }

  return decimalToNumber(x.plus(y))
}

const decimalMinus = (x1, x2, config = {}) => {
  let D = Decimal

  if (config?.decimal) {
    D = Decimal.set({ ...config?.decimal })
  }
  
  let x = new D(x1)
  let y = new D(x2)

  if (isNumber(config?.digit)) {
    return decimalToNumber(x.minus(y), config?.digit)
  }

  return decimalToNumber(x.minus(y))
}

const decimalTimes = (x1, x2, config = {}) => {
  let D = Decimal

  if (config?.decimal) {
    D = Decimal.set({ ...config?.decimal })
  }
  
  let x = new D(x1)
  let y = new D(x2)

  if (isNumber(config?.digit)) {
    return decimalToNumber(x.times(y), config?.digit)
  }

  return decimalToNumber(x.times(y))
}

const decimalDivide = (x1, x2, config = {}) => {
  let D = Decimal

  if (config?.decimal) {
    D = Decimal.set({ ...config?.decimal })
  }

  let x = new D(x1)
  let y = new D(x2)

  if (decimalToNumber(y) === 0) return null

  if (isNumber(config?.digit)) {
    return decimalToNumber(x.dividedBy(y), config?.digit)
  }

  return decimalToNumber(x.dividedBy(y))
}

const decimalReduceSumNumbers = (...inputs) => {
  let config = {}

  const sumTotal = reduce(inputs, (acc, current) => {
    if (typeof current === Object) {
      config = current
      return acc
    }

    return decimalSum(acc, current, config)
  }, 0)

  if (isNumber(config?.digit)) {
    return numberDigit(sumTotal, config?.digit)
  }

  return numberDigit(sumTotal)
}

const decimalReduceSumNumberArrays = (inputs, config = {}) => {
  const sumTotal = reduce(inputs, (acc, current) => {
    return decimalSum(acc, current, config)
  }, 0)

  if (isNumber(config?.digit)) {
    return numberDigit(sumTotal, config?.digit)
  }

  return numberDigit(sumTotal)
}


export {
  decimalSum,
  decimalMinus,
  decimalTimes,
  decimalDivide,
  decimalReduceSumNumbers,
  decimalReduceSumNumberArrays,
  numberDigit,
  numberToString
}