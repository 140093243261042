import { Controller } from 'react-hook-form'
import { Text, DatePicker, Icon } from 'components'
import { useTheme } from 'styled-components'

import { get } from 'lodash'

const DatePickerForm = (props) => {
  const {
    label,
    name,
    control,
    errors,
    rules,
    onChange,
    allowClear = true
  } = props
  const { colors } = useTheme()

  return (
    <>
      {label && (
        <Text fontSize='12px' fontWeight='500' mb='8px' display='block' color={colors.dark500}>
          {label}
        </Text>
      )}
      <Controller
        as={DatePicker}
        allowClear={allowClear}
        name={name}
        control={control}
        size='large'
        format='DD MMM YYYY'
        onChange={onChange}
        suffixIcon={<Icon name='selectCalendar' />}
        rules={rules || {}}
        error={get(errors, name)}
      />
    </>
  )
}

export default DatePickerForm
