import { useCallback, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { isNil, reduce } from 'lodash'
import { getQueryParams } from 'utils/helper'

export default function useURL() {
  const history = useHistory()
  const { search } = useLocation()
  const params = useParams()

  const queries = useMemo(() => getQueryParams(search), [search])

  const patch = useCallback(
    (params) => {
      const query = new URLSearchParams(search)
      for (const [key, value] of Object.entries(params)) {
        if (isNil(value) || (Array.isArray(value) && !value.length)) {
          query.delete(key)
        } else {
          query.set(key, value)
        }
      }

      history.replace({ search: query.toString() })
    },
    [history, search]
  )

  const onInput = useCallback(
    (event) => {
      const value = event?.target ? event.target.value : event
      patch({ search: value || null })
    },
    [patch]
  )

  const values = useCallback(
    (mapKeys) =>
      reduce(
        mapKeys,
        (acc, crr, index) => ({
          ...acc,
          [index]: queries[crr] === undefined ? [] : String(queries[crr] || '').split(',')
        }),
        {}
      ),
    [queries]
  )

  const makeFilterSetter = useCallback(
    (reverseMapKeys) => {
      return function setter(arg) {
        let _values = arg
        if (typeof arg === 'function') {
          _values = arg(queries)
        }

        const payload = reduce(
          reverseMapKeys,
          (acc, crr, index) => ({
            ...acc,
            [crr]: _values[index]
          }),
          {}
        )

        patch(payload)
      }
    },
    [patch, queries]
  )

  const reset = useCallback(
    (initial) => {
      const newParams = new URLSearchParams(initial)
      history.replace({ search: newParams.toString() })
    },
    [history]
  )

  return {
    history,
    search,
    queries,
    params,
    patch,
    onInput,
    values,
    makeFilterSetter,
    reset
  }
}
