import { createElement, isValidElement } from 'react'
import _ from 'lodash'
import { v4 as uuidV4 } from 'uuid'
import numeral from 'numeral'
import moment from 'moment'

import { classNames as ANT_CLASSNAME } from 'pages/match_detail/RundownTable/RundownViewTable/material'
import ENUM from 'constants/enums'
import DATA from 'constants/data'

import { Expect } from './expect'
import { numberDigit, numberToString } from './decimal'

const { TIMEZONES } = DATA

const isSequential = (arr) => {
  return !arr.some((val, idx) => val !== arr[0] + idx)
}

const getSorting = (sortOrder) => {
  let columns = []
  let orders = []
  _.forEach(sortOrder, (value, key) => {
    columns.push(key)
    orders.push(value)
  })

  return { columns, orders }
}

const getSortedData = (dataSource, sortOrder) => {
  const { columns, orders } = getSorting(sortOrder)
  return _.orderBy(dataSource, columns, orders)
}

const getFilteredData = (
  dataSource,
  filters = {},
  { caseSensitive = true, getDataRecord = (record, key) => _.get(record, key) } = {}
) => {
  const compare = (a, b) => {
    let input = a
    let target = b

    if (Array.isArray(input)) {
      return input.some((i) => {
        if (caseSensitive) return String(target) === String(i)

        return String(target).toLowerCase() === String(i).toLowerCase()
      })
    }

    target = caseSensitive ? String(target) : String(target).toLowerCase()
    input = caseSensitive ? String(input) : String(input).toLowerCase()

    return _.includes(target, input)
  }

  let records = dataSource

  for(const [key, searching] of Object.entries(filters)) {
    records = records.filter((record) => {
      const target = getDataRecord(record, key)

      if (searching?.length > 0)
        return Array.isArray(target)
          ? target.some((item) => compare(searching, item))
          : compare(searching, target)

      return true
    })
  }

  return records
}

const getFilteredSearchValue = (dataSource, searchTerm, searchKeys, uniqueKey = 'id') => {
  if (!searchTerm) {
    return dataSource
  }
  const searchTerms = searchTerm.split(',')

  let results = []

  searchKeys.forEach((key) => {
    searchTerms.forEach((searchTerm) => {
      if (searchTerm && searchTerm !== ' ') {
        const filtered = dataSource.filter((data) => {
          return `${_.get(data, key)}`.toLowerCase().includes(searchTerm.toLowerCase().trim())
        })
        results = _.concat(results, filtered)
      }
    })
  })

  return _.uniqBy(results, uniqueKey)
}

const isAFL = (sportType) => {
  return (
    sportType === ENUM.SPORT_TYPE.AFL ||
    sportType === String(ENUM.SPORT_TYPE.AFL) ||
    String(sportType).toLowerCase() === ENUM.SPORT_TYPE_DISPLAY.AFL.toLowerCase()
  )
}

const isCricket = (sportType) => {
  return (
    sportType === ENUM.SPORT_TYPE.CRICKET ||
    sportType === String(ENUM.SPORT_TYPE.CRICKET) ||
    String(sportType).toLowerCase() === ENUM.SPORT_TYPE_DISPLAY.CRICKET.toLowerCase()
  )
}

const isVRC = (sportType) => {
  return (
    sportType === ENUM.SPORT_TYPE.VRC ||
    sportType === String(ENUM.SPORT_TYPE.VRC) ||
    String(sportType).toLowerCase() === ENUM.SPORT_TYPE_DISPLAY.VRC.toLowerCase()
  )
}

const isLed = (displayTypeName) => {
  const PARAM_LED = ENUM.RUNDOWN_PARAM_TYPE.LED
  const lowerDisplayName = String(displayTypeName).toLowerCase()
  return lowerDisplayName.includes(PARAM_LED)
}

const isTVC = (displayTypeName) => {
  const PARAM_LED = ENUM.RUNDOWN_PARAM_TYPE.TVC
  const lowerDisplayName = String(displayTypeName).toLowerCase()
  return lowerDisplayName.includes(PARAM_LED)
}

const isMultiple = (displayTypeName) => {
  const lowerDisplayName = String(displayTypeName).toLowerCase()
  return lowerDisplayName.includes('multiple')
}

const isOtherSport = (sportType) => {
  return (
    sportType === ENUM.SPORT_TYPE.OTHER ||
    sportType === String(ENUM.SPORT_TYPE.OTHER) ||
    String(sportType).toLowerCase() === ENUM.SPORT_TYPE_DISPLAY.Other.toLowerCase()
  )
}

const isStandardFixture = (fixtureType) => {
  return fixtureType === ENUM.FIXTURE_TYPE.STANDARD
}

const isBasicFixture = (fixtureType) => {
  return fixtureType === ENUM.FIXTURE_TYPE.BASIC
}

const isTeamVTeamFixture = (fixtureType) => {
  return fixtureType === ENUM.FIXTURE_TYPE.TEAM_V_TEAM
}

const getTeamVTeamRundown = (fixtureItem, entitlementTemplate) => {
  const teamVTeamRundown = (fixtureItem?.team_v_team_rundowns || []).filter((item) => `${item.entitlement_template}` === `${entitlementTemplate}`)
  return teamVTeamRundown.length ? teamVTeamRundown[0]: {}
}

const isAFLDisplay = (sportType) => {
  return sportType && sportType.toUpperCase() === ENUM.SPORT_TYPE_DISPLAY.AFL
}

const isCricketDisplay = (sportType) => {
  return sportType && sportType.toUpperCase() === ENUM.SPORT_TYPE_DISPLAY.CRICKET
}

const getNoMatchesText = (sportType) => {
  if (isVRC(sportType)) {
    return '(No Race Days)'
  }

  return '(No Matches)'
}

const getSportTypeDisplay = (sportType) => {
  if (isAFL(sportType)) {
    return 'AFL'
  }

  if (isCricket(sportType)) {
    return 'Cricket'
  }

  if (isVRC(sportType)) {
    return 'VRC'
  }

  return sportType
}

const getRoleDisplay = (role) => {
  if (role === 'superadmin') {
    return 'SuperAdmin'
  }

  if (role === 'systemadmin') {
    return 'SystemAdmin'
  }

  if (role === 'seniorsales') {
    return 'SeniorSales'
  }

  return _.capitalize(role)
}

const isValidTime = (val) => {
  const regexp = /^\d{0,2}?:?\d{0,2}$/

  const [hoursStr, minutesStr] = val.split(':')

  if (!regexp.test(val)) {
    return false
  }

  const hourOffset = Number(hoursStr[0])
  const hours = Number(hoursStr)
  const minutes = Number(minutesStr)

  const isValidHourOffset = (hourOffset) => {
    if (!hoursStr[0]) {
      return true
    }

    return Number.isInteger(hourOffset) && hourOffset >= 0 && hourOffset < 3
  }
  const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24
  const isValidMinutes = (minutes) =>
    (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes)

  if (!isValidHourOffset(hourOffset)) {
    return false
  }

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false
  }

  const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val]

  // check mm and HH
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
  ) {
    return false
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false
  }

  return true
}

const range = (start, end) => {
  let min = parseInt(start)
  let max = parseInt(end)
  if (min > max) {
    min = parseInt(end)
    max = parseInt(start)
  }

  if (Number.isNaN(min) || Number.isNaN(max)) {
    return []
  }

  return Array(max - min + 1)
    .fill()
    .map((_, idx) => min + idx)
}

const clsx = (...classes) => [...new Set(classes.filter((string) => !!string))].join(' ')

const getTotalConflicts = (items) => {
  let count = 0
  if(items) {
    Object.keys(items).forEach((item) => {
      count += items[item].length
    })
  }
  return count
}

const commaSeparatedToList = (value, valueAsNumber = false) => {
  if (value) {
    const results = value.replace(/\s/g, '').split(',')
    if (valueAsNumber) {
      return results.map((val) => Number(val))
    }
    return results
  }
}

const numberWithCommas = (number) => {
  if (number === undefined || number === null) {
    return ''
  }
  return numeral(numberDigit(number, 2)).format('0,0.00')
}

const isNotAssigned = (rateCard, grossSale) => {
  if (rateCard === 0 && grossSale === 0) {
    return true
  }
  return false
}

const getPercentDisplay = (percent) => {
  return `${percent}%`
}

const getRoundedPercentDisplay = (number, decimalPlaces = 1) => {
  if (number === null) {
    number = 0
  }
  return getPercentDisplay(numberToString(number, decimalPlaces))
}

const isNumberSeparateBy = (separator) => (txt) => {
  // eslint-disable-next-line quotes
  const patternString = `^\\d+(${separator}\\d+)*$`

  const pattern = new RegExp(patternString, 'g')
  return pattern.test(txt)
}

const isNumberRange = (txt) => /^(\d+)-(\d+)$/g.test(txt)

const durationToHHMMSS = (duration) => {
  if (!duration) {
    return '-'
  }

  var hours = Math.floor(duration / 3600)
  var minutes = Math.floor((duration - hours * 3600) / 60)
  var seconds = duration - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return hours + ':' + minutes + ':' + seconds
}

const copy = (data) => {
  const text = JSON.stringify(data)
  return JSON.parse(text)
}

const antRender = (args, params = {}) => {
  const classes = clsx(
    ANT_CLASSNAME.cell,
    params.className,
    params.rowId && `expanded-row-key-[${params.rowId}]`
  )

  return (func) => {
    const result = func(...args)

    if (isValidElement(result)) return <td className={classes}>{result}</td>

    if (typeof result === 'object') {
      const { props: { className, ...props } = {}, children } = result
      return (
        <td className={clsx(classes, className)} {...props}>
          {children}
        </td>
      )
    }

    return <td className={classes}>{result}</td>
  }
}

const makeID = () => `new_${uuidV4()}`

const buildField = (component, defaultProps) => (props) =>
  createElement(component, {
    ...defaultProps,
    ...props
  })

const toHHMMSS = (seconds) => {
  var sec_num = parseInt(seconds, 10)
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor(sec_num / 60) % 60
  var secs = sec_num % 60

  return [hours, minutes, secs]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
}

const getIndexOfFilterValues = (values, options) => {
  const indexes = []
  for (const value of values) {
    indexes.push(options.indexOf(value))
  }
  return indexes.sort()
}

const cssUnit = (pix) => (typeof pix === 'string' ? pix : `${pix}px`)

const getTimezoneOptions = () =>
  Array.from(TIMEZONES).map((tz, index) => {
    const label = [tz.name, tz.offset]

    if (tz.isDST) {
      label.push('(DST)')
    }

    return {
      label: label.join(' '),
      value: tz.id,
      tz
    }
  })

const createRepeater = (func, delay) => {
  let timeId = null
  let isRepeated = false

  return (...args) => {
    const execute = () => func(...args)

    execute()

    if (isRepeated) return
    if (timeId) {
      clearTimeout(timeId)
    }
    isRepeated = true
    timeId = setTimeout(() => {
      execute()
      isRepeated = false
    }, delay)
  }
}

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

const characters = (text, search) => [...text].filter((c) => c === search).length

const submit = (formId) => {
  const btn = document.createElement('button')
  btn.style.display = 'none'
  btn.setAttribute('type', 'submit')
  btn.setAttribute('form', formId)

  document.body.appendChild(btn)
  btn.click()
  document.body.removeChild(btn)
}

const scrollToElement = (selector, option = { behavior: 'smooth', block: 'center' }) => {
  const element = document.querySelector(selector)
  element?.scrollIntoView?.(option)
}

const isEmpty = (value) => Expect.empty(true, value)

const pickWithDefault = (object, paths, defaultValue, logical = 'nullish') => {
  const gatValue = path => {
    if(logical === 'nullish') {
      return _.get(object, path) ?? defaultValue
    }

    return _.get(object, path) || defaultValue
  }

  return paths.reduce((acc, crr) => ({...acc, [crr]: gatValue(crr) }), {})
}

const isSpotOver = (record = {}) =>
  record?.total_scheduled > record?.total_contracted + record?.total_bonus_sold_spots
const isSpotUnder = (record = {}) =>
  record?.total_scheduled < record?.total_contracted + record?.total_bonus_sold_spots
const isSpotEqual = (record = {}) =>
  record?.total_scheduled === record?.total_contracted + record?.total_bonus_sold_spots

const isSpot = {
  over: isSpotOver,
  under: isSpotUnder,
  equal: isSpotEqual
}

const pluralize = (count, singular, plural) => `${count} ${count === 1 ? singular : plural}`

const renders = (condition,...components) => condition ? components : []

const getMatchSuffix = isSport => {
  if(isSport.AFL || isSport.Cricket || isSport.VRC || isSport.TeamVTeamFixture){
    return 'matches'
  }

  return 'events'
}

const getQueryParams = (search) => {
  const searchParams = new URLSearchParams(search)
  return Object.fromEntries(searchParams.entries())
}

const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n)

const getMomentClient = (dateTime) => {
  const utcDateTime = moment.utc(dateTime).toDate()
  return moment(utcDateTime).local()
}

const getRundownByEntitlementTemplate = (rundowns, templateId) => {
  return _.find(rundowns, rundown => String(rundown.entitlement_template) === String(templateId))
}

const getOOGRundown = (fixtureItem, entitlementTemplate) => {
  const templateId = entitlementTemplate?.id
  const isOutOfGame = entitlementTemplate?.selling_metric === ENUM.SELLING_METRIC.OOG_DURATION
  if (isOutOfGame) return getRundownByEntitlementTemplate(fixtureItem?.oog_rundowns, templateId)
  return null
}

export {
  isNumeric,
  getMatchSuffix,
  renders,
  isEmpty,
  scrollToElement,
  createRepeater,
  getTimezoneOptions,
  getIndexOfFilterValues,
  toHHMMSS,
  antRender,
  copy,
  clsx,
  getSorting,
  getSortedData,
  getFilteredData,
  getFilteredSearchValue,
  isAFL,
  isCricket,
  isVRC,
  isTVC,
  isLed,
  isOtherSport,
  isStandardFixture,
  isBasicFixture,
  isTeamVTeamFixture,
  getRoleDisplay,
  isValidTime,
  range,
  isAFLDisplay,
  isCricketDisplay,
  getTotalConflicts,
  commaSeparatedToList,
  numberWithCommas,
  isNotAssigned,
  getPercentDisplay,
  getRoundedPercentDisplay,
  isSequential,
  isNumberSeparateBy,
  isNumberRange,
  getSportTypeDisplay,
  getNoMatchesText,
  durationToHHMMSS,
  makeID,
  buildField,
  cssUnit,
  characters,
  isMultiple,
  submit,
  isSpot,
  pluralize,
  pickWithDefault,
  getQueryParams,
  getTeamVTeamRundown,
  collator,
  getMomentClient,
  getRundownByEntitlementTemplate,
  getOOGRundown
}
