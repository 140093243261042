/* eslint-disable import/no-anonymous-default-export */
import Axios from 'utils/axios'
import URL from 'constants/urls'

const getToken = (username, password) => {
  return Axios.post(URL.AUTH_TOKEN, { username, password })
}

const getUserDetail = () => {
  return Axios.get(URL.USER_DETAIL)
}

const postUploadFixtureFile = (data) => {
  return Axios.post(URL.FIXTURE_UPLOAD, data)
}

const postUploadPdfFile = (data) => {
  return Axios.post(URL.PDF_UPLOAD, data)
}


export default {
  getToken,
  getUserDetail,
  postUploadFixtureFile,
  postUploadPdfFile
}
