import { useMemo } from 'react'
import BaseInput from 'components/Input'
import { blockKeyPress, getFormattedValue } from './helper'

export default function Input({ value, onChange, allowedComma, type, ...props }) {
  const isNumberComma = useMemo(() => allowedComma && type === 'number', [allowedComma, type])

  const displayValue = useMemo(() => {
    if (isNumberComma) {
      const { asString } = getFormattedValue(value)
      return asString
    }

    return value
  }, [value, isNumberComma])

  const typeInput = useMemo(() => {
    return isNumberComma ? 'text' : type
  }, [isNumberComma, type])

  const handleChange = (e) => {
    let val = e.target.value

    if (isNumberComma) {
      const { asNumber } = getFormattedValue(val)
      val = asNumber
    }

    onChange?.(val)
  }

  return (
    <BaseInput
      {...props}
      type={typeInput}
      allowedComma={allowedComma}
      value={displayValue}
      onChange={handleChange}
      onKeyPress={blockKeyPress(isNumberComma)}
    />
  )
}
