import { Controller } from 'react-hook-form'
import { Text, InputTag } from 'components'

const InputTagForm = (props) => {
  const { label, name, control, placeholder, rules, errors } = props
  return (
    <>
      <Text fontSize='12px' fontWeight='500' mb='8px'>
        {label}
      </Text>
      <Controller as={InputTag} name={name} control={control} placeholder={placeholder} rules={rules} error={errors[name]} />
    </>
  )
}

export default InputTagForm
