export const getBtnLabel = (text, checkedList) => {
  if (checkedList?.length === 1) {
    return `${text} ${checkedList[0]}`
  }

  if (checkedList?.length > 1) {
    return `${text} ${checkedList[0]} + ${checkedList.length - 1}`
  }

  return text
}

export const isOverflowContainerBottom = (max, bottom) => bottom > max

export const isOverflowContainerRight = (max, left) => left > max
