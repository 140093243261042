const getEntitlementStatuses = () => {
  return {
    OPEN: 1,
    HOLD: 2,
    CONFIRMED: 3
  }
}

const getEntitlementStatusDisplays = () => {
  return {
    HOLD: 'HOLD',
    OPEN: 'OPEN',
    CONFIRMED: 'CONFIRMED'
  }
}

const getEntitlementMediaType = () => {
  // MEDIA is Contract SPOT that will play on LED
  // NON_MEDIA is Contract about Service and Product that will not play on LED
  return {
    MEDIA: 'MEDIA',
    NON_MEDIA: 'NON_MEDIA'
  }
}

const getEntitlementStatusDescriptions = () => {
  return {
    MEDIA_OPEN_TO_HOLD: 'Changing this entitlement\'s status to HOLD will fill the entitlement\'s spots in Inventory Management but will not populate the match rundowns. Do you want to continue with the entitlement status change?',
    MEDIA_OPEN_TO_CONFIRMED: 'Changing this entitlement\'s status to CONFIRMED will fill the entitlement\'s spots in Inventory Management and will populate the match rundowns. Do you want to continue with the entitlement status change?',
    MEDIA_HOLD_TO_OPEN: 'Changing this entitlement\'s status to OPEN will remove the entitlement spots from Inventory Management. Rundowns will remain un-populated with this entitlements. Do you want to continue with the entitlement status change?',
    MEDIA_HOLD_TO_CONFIRMED: 'Changing this entitlement\'s status to CONFIRMED will populate the match rundowns with contracted entitlements. Do you want to continue with the entitlement status change?',
    MEDIA_CONFIRMED_TO_OPEN: 'Changing this entitlement\'s status to OPEN will remove the entitlement spots from the contracted rundowns and Inventory Management. Do you want to continue with the entitlement status change?',
    MEDIA_CONFIRMED_TO_HOLD: 'Changing this entitlement\'s status to HOLD will remove the entitlement spots from the contracted rundowns. Entitlement spots will still be held in Inventory Management. Do you want to continue with the entitlement status change?',
    NON_MEDIA_OPEN_TO_CONFIRMED: 'Do you want to continue changing this entitlement status?',
    NON_MEDIA_CONFIRMED_TO_OPEN: 'Do you want to continue changing this entitlement status?'
  }
}

const getPaymentStatusCode = () => {
  return {
    OPEN: 1,
    SCHEDULED: 2,
    IN_CORRECT: 3
  }
}

const getPaymentScheduleStatus = () => {
  return {
    OPEN: 'Open',
    SCHEDULED: 'Scheduled',
    IN_CORRECT: 'Incorrect'
  }
}

const getEntitlementMatchDetails = () => {
  return {
    FULL_SEASON_HOME_AND_AWAY: 1,
    FULL_SEASON_INC_FINALS: 2,
    BY_ROUND_HOME_AND_AWAY: 3,
    BY_TEAM_HOME_AND_AWAY: 4,
    BY_ROUND_AND_TEAM_HOME_AND_AWAY: 5,
    INDIVIDUAL: 6,
    FINALS: 7,
    BY_ROUND_INC_FINALS: 8,
    BY_TEAM_INC_FINALS: 9,
    BY_ROUND_AND_TEAM_INC_FINALS: 10,
  }
}

const getEntitlementRaceDayDetails = () => {
  return {
    ALL_RACE_DAYS: 1,
    MELBOURNE_CUP_CARNIVAL: 2,
    INDIVIDUAL: 3,
    STANDARD: 4,
  }
}

const getEntitlementStandardEventDetails = () => {
  return {
    ALL_EVENTS: 1,
    INDIVIDUAL: 2
  }
}

const getEntitlementOverrideDate = () => {
  return {
    DATE: 1,
    YEAR: 2,
  }
}


export {
  getEntitlementStatuses,
  getEntitlementStatusDisplays,
  getEntitlementStatusDescriptions,
  getPaymentScheduleStatus,
  getPaymentStatusCode,
  getEntitlementMediaType,
  getEntitlementMatchDetails,
  getEntitlementRaceDayDetails,
  getEntitlementStandardEventDetails,
  getEntitlementOverrideDate,
}
