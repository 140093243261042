import React, { Fragment } from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'

const Item = styled.span`
  a {
      color: ${({ theme }) => theme.colors.primary};
  }

  color: ${({ highlight, theme }) => highlight ? theme.colors.primary: theme.colors.gray800};
`
const Wrapper = styled.div`
  margin-bottom: 24px;
  display: inline-block;

  ${layout}

  .breadcrumb-link {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
  }
  .breadcrumb-separator {
    margin: 0px 13px;
    &:last-child {
      display: none;
    } 
  }
`

const Breadcrumb = (props) => {
  const { children } = props

  if (Array.isArray(children)) {
    return (
      <Wrapper {...props}>
        {
          children.map((element, index) => {
            return (
              <Fragment key={index}>
                <span className='breadcrumb-link'>{element}</span>
                <span className='breadcrumb-separator'>&gt;</span>
              </Fragment>
            )
          })
        }
      </Wrapper>
    )
  }

  return <Wrapper {...props}>
    <span className='breadcrumb-link'>{children}</span>
  </Wrapper>
}

Breadcrumb.Item = Item

export default Breadcrumb
