import { render } from 'react-dom'

// COMPONENT
import IndexRoute from './router'
import MasterProvider from './Provider'

import './theme/index.less'

export default function renderApp() {
  render(
    <MasterProvider>
      <IndexRoute />
    </MasterProvider>,
    document.getElementById('root')
  )
}
