const colors = {
  white: '#ffffff',
  white200: '#f7f7f9',
  blue200: '#198BDB',
  blue500: '#003e72',
  blue600: '#00325f',
  blue800: '#001d3e',
  navy600: '#4D7ECB',
  red300: '#c74322',
  red500: '#bf2600',
  red600: '#C65353',
  red800: '#9e290b',
  gray: '#e5e5ef',
  gray100: '#dedee6',
  gray200: '#bdbdbd',
  gray250: '#949494',
  gray300: '#a4b8c8',
  gray400: '#BABAC8',
  gray500: '#8C8DA0',
  gray800: '#555770',
  dark500: '#28293d',
  teal700: '#00A59A',
  purple600: '#713CDD',
  orange600: '#E28436'
}

const variants = {
  primary: colors.blue500,
  primaryHover: colors.blue600,
  primaryActive: colors.blue800,
  danger: colors.red500,
  dangerHover: colors.red300,
  dangerActive: colors.red800,
  text400: colors.gray400,
  text600: colors.gray800,
  text800: colors.dark500,
  icon: colors.gray500
}

const Colors = {
  ...variants,
  ...colors
}

export default Colors
