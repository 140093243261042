import { Text, Col } from 'components'
import { isNil, isString, range } from 'lodash'

const ALLOW_CHARACTER_PATTERN = [...range(10).map(String), '.', '-']

export const getFormattedValue = (currentValue) => {
  const results = {
    asNumber: '',
    asString: ''
  }

  if (!isNil(currentValue)) {
    let textValue = String(currentValue).replaceAll(',', '')

    if (textValue.length > 1 && textValue[0] === '0' && !textValue.startsWith('0.')) {
      textValue = textValue.substring(1)
    }
    const numberValue = textValue
    const formattedValue = textValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')

    results.asNumber = numberValue
    results.asString = formattedValue
  }

  return results
}

export const blockKeyPress = (isNumber) => (event) => {
  const val = event.target.value
  const key = event.key

  if (isNumber) {
    if (!ALLOW_CHARACTER_PATTERN.includes(key)) {
      event.preventDefault()
    }

    if (key === '.') {
      if (!val || String(val).includes('.')) {
        event.preventDefault()
      }
    }
  }
}

export const getWidth = ({ labelWidth, width }) => {
  if (labelWidth) return `calc(100% - ${labelWidth})`

  return width || '100%'
}

export const Label = ({ children, useCustomWidth, width, gutter }) => {
  if (isString(children)) {
    if (useCustomWidth) {
      return (
        <Col width={width} flex='unset !important'>
          <Text fontSize='12px' fontWeight='500' color='#28293d'>
            {children}
          </Text>
        </Col>
      )
    }

    return (
      <Text fontSize='12px' fontWeight='500' mb={gutter} color='#28293d'>
        {children}
      </Text>
    )
  }

  return children
}
