import { AutoComplete as AntAutoComplete } from 'antd'
import styled, { css } from 'styled-components'
import { Box, Icon, Text } from 'components'

const AntdAutoComplete = styled(AntAutoComplete)`
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #003e72;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid #e5e5ef;
  }

  ${({ error }) => error && css`
    .ant-select-selector {
      border: 1px solid #bf2600 !important;
    }
  `}

  .ant-select-selector {
    height: unset !important;
    line-height: 1.4;
    box-sizing: border-box;
    border-radius: 4px !important;
    padding: 4px 16px !important;
    border: 1px solid #e5e5ef;

    &:hover {
      background: #f7f7f9 !important;
    }

    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 40px;
      }
    }
  }
`

const ErrorContainer = styled.div`
  margin-top: 10px;
  display='inline-block';

  svg {
    vertical-align: bottom;
  }
`

const AutoComplete = (props) => {
  const { error, enabledRenderError } = props

  const renderError = () => {
    if (enabledRenderError === false) {
      return null
    }

    return (
      <ErrorContainer>
        <Icon name='inputError' />
        <Text color='#bf2600' fontSize={14} ml={12}>{error.message}</Text>
      </ErrorContainer>
    )
  }

  return (
    <Box>
      <AntdAutoComplete {...props} />
      {props.error && renderError()}
    </Box>
  )
}

export default AutoComplete

