import React from 'react'
import ENUM from 'constants/enums'
import {
  Text
} from 'components'
import { getRoundedPercentDisplay } from 'utils/helper'

function CricketLEDAllocatedText({ allocated, displayType }) {
  function renderAllocated() {
    let ledAllocated = allocated

    if (displayType !== ENUM.CRICKET_LED_TYPE.PARAPET) {
      ledAllocated = getRoundedPercentDisplay(allocated)
    }

    return (
      <Text fontWeight={500} fontSize={12} color='#003E72'>{ledAllocated}</Text>
    )
  }

  return (
    <>
      {
        allocated !== 'undefined' && renderAllocated()
      }
    </>
  )
}

export default CricketLEDAllocatedText
