import React from 'react'

import Loadable from 'react-loadable'
import { Spinner } from 'components'

const LoadableComponent = (opts) =>
  Loadable({
    loading: () => <Spinner />,
    ...opts
  })

export default LoadableComponent
