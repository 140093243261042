const getSchedulingRuleDefinition = () => {
  return {
    CANNOT_PLAY_NEXT_TO: 1,
    MUST_OCCUPY: 2,
    MAJORITY_SATURATE: 3,
    CANNOT_PLAY_BEFORE: 4,
    OCCUPY_GOAL_SIDE: 5,
    MUST_PLAY_IN: 7,
    MUST_BE_SEQUENCED: 9
  }
}

const getSchedulingRuleType = () => {
  return {
    CATEGORY: 1,
    PARTNER_SPECIFIC: 2,
    ENTITLEMENT_SPECIFIC: 3,
    CONTENT_COLOUR: 4
  }
}

const getSchedulingRuleRundownEntity = () => {
  return {
    FIRST_SLOT: 1,
    FIRST_50_PERCENT_OF_QUARTER: 2,
    SECOND_50_PERCENT_OF_QUARTER: 3,
    LEFT_GOAL: 4,
    RIGHT_GOAL: 5,

    // TVC
    REEL_1: 601,
    REEL_2: 602,
    REEL_3: 603,
    REEL_4: 604,
    REEL_5: 605,
    REEL_6: 606,
    REEL_7: 607,
    REEL_8: 608,
    REEL_9: 609,
    REEL_10: 610,
    REEL_11: 611,
    REEL_12: 612,
    REEL_13: 613
  }
}

const getSchedulingRuleSequenceValue = () => {
  return {
    FIRST_IN_ALLOCATED_REEL: 1,
    LAST_IN_ALLOCATED_REEL: 2,
    ANYWHERE_IN_ALLOCATED_REEL: 3,
  }
}

export {
  getSchedulingRuleDefinition,
  getSchedulingRuleType,
  getSchedulingRuleRundownEntity,
  getSchedulingRuleSequenceValue
}
