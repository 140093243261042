import styled, { css } from 'styled-components'
import { color, typography, space, layout, border } from 'styled-system'

const TableTr = styled.tr`
  ${space}
  ${typography}
  ${color}
  ${layout}
  ${border}

  ${({ hover }) =>
    hover &&
      css`
      &:hover {
        background: #f7f7f9;
      }
    `}
`

export default TableTr
