import { useRef } from 'react'
import { Controller } from 'react-hook-form'
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Space,
  Icon,
  Popover
} from 'components'
import styled, { useTheme } from 'styled-components'

const RadioForm = (props) => {
  const {
    label,
    name,
    options,
    rules,
    initialValue,
    optionProps,
    labelProps,
    control,
    requiredMark = false,
    markInfo,
    onChange,
  } = props
  const { colors } = useTheme()

  const nodeTitle = useRef()

  const onChangeHandler = (value) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Box>
      <Title ref={nodeTitle}>
        <Text
          fontSize='14px'
          fontWeight='500'
          color={colors.dark500}
          {...labelProps}
        >
          {label}
        </Text>
        {requiredMark &&
          <Popover
            content={markInfo}
            getPopupContainer={() => nodeTitle.current}
          >
            <Icon name={'info'} ml='8px' className='mark-icon' />
          </Popover>
        }
      </Title>
      <Controller
        name={name}
        defaultValue={initialValue}
        render={({onChange, value}) => {
          return (
            <RadioGroup
              onChange={(e) => {
                onChangeHandler(e.target.value)
                onChange(e.target.value)
              }}
              value={value || initialValue}
            >
              <Space>
                {options.map((option) => {
                  return (
                    <Radio {...optionProps} value={option.value}>
                      {option.label}
                    </Radio>
                  )
                })}
              </Space>
            </RadioGroup>
          )
        }}
        rules={rules}
        control={control}
      />
    </Box>
  )
}


const Title = styled(Box)`
  --bg-color: #28293D;
  --color: #fff;

  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .mark-icon {
    cursor: pointer;
   }

  .ant-popover .ant-popover-inner {
    background-color: var(--bg-color);
    border-radius: 4px;
  }

  .ant-popover .ant-popover-inner-content {
    padding: 8px 12px;
    color: var(--color);
  }

  .ant-popover .ant-popover-arrow {
    border-right-color: var(--bg-color);
    border-bottom-color: var(--bg-color);
  }
`

export default RadioForm
