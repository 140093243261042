export const getRundownType = () => {
  return {
    AFL_LED: 1,
    AFL_TVC: 2,
    CRICKET_LED: 3,
    VRC_LED: 4,
    CRICKET_TVC: 5,
    CRICKET_SIGHTSCREEN: 6,
    CRICKET_PARAPET: 7,
    TEAM_V_TEAM_LED: 8,
    OUT_OF_GAME: 9
  }
}

export const getRundownParamsType = () => {
  return {
    LED: 'led',
    TVC: 'tvc'
  }
}
