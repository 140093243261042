function RegexError() {
  return {
    image: () => 'Invalid File Name Format',
    email: () => 'Invalid Email Address',
    phone: () => 'Invalid Phone Number Format',
    url: () => 'Invalid URL Format',
    abbreviation: () => 'Incorrect value, The input value should be 1-5 UPERCASE letters.'
  }
}

function Errors() {
  const regexErrors = RegexError()

  return {
    required: () => 'This field is required',
    minimum: (min, val) => `Value must be greater than or equal ${min}`,
    maximum: (max, val) => `Value must be less than or equal ${max}`,
    step: (step, val, [prevStep, nextStep]) =>
      `Please enter a valid value. The two nearest valid values are ${prevStep} and ${nextStep}`,
    empty: () => 'This field is required',
    equal: () => 'Value not equal',
    regex: (name, val) => {
      const error = regexErrors[name]
      return error?.(val) || `Invalid ${name} pattern`
    }
  }
}

export default Errors
