import { cloneElement } from 'react'
import { AuthContextProvider, AbilityContextProvider } from 'core/contexts'
import { ThemeProvider } from 'styled-components'
import theme from './theme'

const PROVIDER_LIST = [
  <ThemeProvider theme={theme} />,
  <AuthContextProvider />,
  <AbilityContextProvider />
]

const render = (providers, children) => {
  const [provider, ...restProviders] = providers

  if (provider) {
    return cloneElement(provider, {}, render(restProviders, children))
  }

  return children
}

export default function MasterProvider({ children }) {
  return render(PROVIDER_LIST, children)
}
