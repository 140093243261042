const TIMEZONES = [
  {
    id: 1,
    name: 'Australia/Adelaide',
    offset: '+09:30',
    isDST: false
  },
  {
    id: 2,
    name: 'Australia/Adelaide',
    offset: '+10:30',
    isDST: true
  },
  {
    id: 3,
    name: 'Australia/Brisbane',
    offset: '+10:00',
    isDST: false
  },
  {
    id: 4,
    name: 'Australia/Darwin',
    offset: '+09:30',
    isDST: false
  },
  {
    id: 5,
    name: 'Australia/Melbourne',
    offset: '+10:00',
    isDST: false
  },
  {
    id: 6,
    name: 'Australia/Melbourne',
    offset: '+11:00',
    isDST: true
  },
  {
    id: 7,
    name: 'Australia/Perth',
    offset: '+08:00',
    isDST: false
  },
  {
    id: 8,
    name: 'Australia/Sydney',
    offset: '+10:00',
    isDST: false
  },
  {
    id: 9,
    name: 'Australia/Sydney',
    offset: '+11:00',
    isDST: true
  }
]

const DATA = {
  TIMEZONES: TIMEZONES
}

export default DATA
