import styled, { css } from 'styled-components'
import { space, layout, color, border, shadow } from 'styled-system'

const handleButtonType = (type, isDisabled) => {
  switch (type) {
  case 'primary':
    return css`
      background-color: #003e72;
      color: #fff;
      &:hover {
        background-color: #00325f;
      }
      &:active {
        background-color: #001d3e;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #fff;
        }
      }
      /* ---------------------------- */

      ${isDisabled && `
        background-color: #a4b8c8;

        &:hover {
          background-color: #a4b8c8;
        }

        &:active {
          background-color: #a4b8c8;
        }
      `}
    `
  case 'secondary':
    return css`
      background-color: transparent;
      color: #003e72;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-shadow: none;
      &:hover {
        background-color: #f7f7f9;
      }
      &:active, &.active {
        background-color: #e5e5ef;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #003E72;
        }
      }
      /* ---------------------------- */

      ${isDisabled && `
        color: #A4B8C8;
        background-color: transparent;

        .icon {
          path {
            fill: #A4B8C8;
          }
        }

        &:hover {
          background-color: transparent;
        }

        &:active {
          background-color: transparent;
        }
      `}
    `
  case 'danger':
    return css`
      background-color: transparent;
      color: #C65353;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-shadow: none;
      &:hover {
        background-color: #f7f7f9;
      }
      &:active, &.active {
        background-color: #e5e5ef;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #C65353;
        }
      }
      /* ---------------------------- */

      ${isDisabled && `
        color: #A4B8C8;
        background-color: transparent;

        .icon {
          path {
            fill: #A4B8C8;
          }
        }

        &:hover {
          background-color: transparent;
        }

        &:active {
          background-color: transparent;
        }
      `}
    `
  case 'icon':
    return css`
      display: flex;
      padding: 4px;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;

      svg {
        path {
          fill: #003E72;
        }
      }

      &:hover, &:focus {
        svg {
          path {
            fill: #00325F;
          }
        }
      }

      ${isDisabled && `
        .icon {
          path {
            fill: #A4B8C8;
          }
        }

        &:hover {
          svg {
            path {
              fill: #A4B8C8;
            }
          }
        }

        &:active {
          svg {
            path {
              fill: #A4B8C8;
            }
          }
        }
      `}
    `
  case 'icon-outline':
    return css`
      display: flex;
      padding: 4px;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;

      svg {
        path {
          fill: #DFDFDE;
        }
      }

      &:hover, &:focus {
        svg {
          path {
            fill: #FFFFFF;
          }
        }
      }

      ${isDisabled && `
        .icon {
          path {
            fill: #DFDFDE;
          }
        }

        &:hover {
          svg {
            path {
              fill: #FFFFFF;
            }
          }
        }

        &:active {
          svg {
            path {
              fill: #FFFFFF;
            }
          }
        }
      `}
    `
  case 'cancel':
    return css`
      background-color: #FFFFFF;
      color: #003E72;
      &:hover {
        background-color: #FFFFFF;
      }
      &:active {
        background-color: #FFFFFF;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #003E72;
        }
      }
      /* ---------------------------- */
    `
  case 'secondary-border':
    return css`
      background-color: transparent;
      color: #003e72;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-shadow: none;
      border: 1px solid #e5e5ef;

      &:hover {
        background-color: #f7f7f9;
        border: 1px solid #E5E5EF;
      }
      &:active, &.active {
        background-color: #e5e5ef;
        border: 1px solid #e5e5ef;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #003E72;
        }
      }
      /* ---------------------------- */

      ${isDisabled && `
        color: #A4B8C8;
        background-color: transparent;

        .icon {
          path {
            fill: #A4B8C8;
          }
        }

        &:hover {
          background-color: transparent;
        }

        &:active {
          background-color: transparent;
        }
      `}
    `
  case 'dropdown':
    return css`
      text-shadow: none;
      height: 36px;
      background-color: #ffffff;
      color: #555770;
      box-shadow: 0px 1px 2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);

      &:hover {
        background-color: #f7f7f9;
      }
      &:active, &.active {
        background-color: #e5e5ef;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #8C8DA0;
        }
      }
      /* ---------------------------- */

      ${isDisabled && `
        color: #BABAC8;

        .icon {
          path {
            fill: #E5E5EF;
          }
        }

        &:hover {
          background-color: #ffffff;
        }

        &:active {
          background-color: #ffffff;
        }
      `}
    `
  default:
    return css`
      background-color: #003E72;
      color: #fff;
      &:hover {
        background-color: #00325f;
      }
      &:active {
        background-color: #001d3e;
      }

      /* Icon stylesheet */
      .icon {
        path {
          fill: #fff;
        }
      }
      /* ---------------------------- */
    `
  }
}

const ButtonWrapper = styled.button`
  /* Override Browser stylesheet */
  position: relative;
  appearance: none;
  outline: 0;
  text-transform: none;
  text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
  background-image: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
  cursor: pointer;
  -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  /* ---------------------------- */

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  padding: 8px 16px;
  border: none;

  ${({ icon, iconStyle }) => icon && css`
    .icon {
      margin-right: 8px;
      svg {
        float: left;
        ${iconStyle};
      }
    }
  `}

  ${({ suffixIcon }) => suffixIcon && css`
  .icon {
    margin-left: 8px;
    svg {
      float: right;
    }
  }
  `}

  /* Icon stylesheet */
  .icon {
    svg {
      vertical-align: middle;
      path {
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      }
    }
  }
  /* ---------------------------- */

  ${({ buttonType, isDisabled }) => handleButtonType(buttonType, isDisabled)};
  ${({ isDisabled }) => isDisabled && css`cursor: not-allowed;`}

  ${space};
  ${layout};
  ${color};
  ${border};
  ${shadow};
`

const Button = (props) => {
  const { children, icon, suffixIcon, isDisabled, onClick, type, buttonType } = props
  const handleClick = (e) => {
    if (isDisabled) {
      e?.preventDefault?.()
      return
    }

    onClick?.(e)
  }

  return (
    <ButtonWrapper {...props} buttonType={type} type={buttonType} isDisabled={isDisabled} onClick={handleClick}>
      { icon && <span className='icon'>{icon}</span> }
      {children}
      { suffixIcon && <span className='icon'>{suffixIcon}</span> }
    </ButtonWrapper>
  )
}

export default Button
