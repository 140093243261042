import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'
import capitalize from 'lodash/capitalize'

import { Tooltip } from 'components'

const getStatusDisplay = (key, capitalized) => {
  const statusDisplay = { 1: 'pending', 2: 'open', 3: 'closed', 4: 'active' }[key] || key

  return capitalized ? capitalize(statusDisplay) : statusDisplay
}

const handleStatusType = (status = '') => {
  const statusLowerCase = status?.toLowerCase()
  if (statusLowerCase.includes('pending')) {
    return css`
      color: #e25919;
      background: #fbe9e7;
    `
  } else if (statusLowerCase.includes('open')) {
    return css`
      color: #198bdb;
      background: #e3f2fd;
    `
  } else if (statusLowerCase.includes('closed')) {
    return css`
      color: #949494;
      background: #f1f1f1;
    `
  } else if (statusLowerCase.includes('active')) {
    return css`
      color: #5dc976;
      background: #e5ffeb;
    `
  } else if (statusLowerCase.includes('changed')) {
    return css`
      color: #949494;
      background: #f1f1f1;
    `
  } else if (statusLowerCase.includes('multiple')) {
    return css`
      color: #401cdc;
      background: #eae8f8;
    `
  }
  return css``
}

const RundownStatusLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  border-radius: 4px;
  text-transform: capitalize;

  ${({ status }) => handleStatusType(status)};

  ${space};
  ${layout};
`

const RundownStatusLabelComponent = ({ status = '', tooltipKey, tooltipTitle, ...rest }) => {
  return tooltipKey ? (
    <Tooltip key={tooltipKey} color='#28293D' title={tooltipTitle}>
      <RundownStatusLabel status={status} {...rest}>
        {status}
      </RundownStatusLabel>
    </Tooltip>
  ) : (
    <RundownStatusLabel status={status} {...rest}>
      {status}
    </RundownStatusLabel>
  )
}

RundownStatusLabelComponent.getStatusDisplay = getStatusDisplay


export default RundownStatusLabelComponent
