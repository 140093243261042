import { Select as AntSelect } from 'antd'
import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

import { Box, Icon, Text } from 'components'

const SelectAntd = styled(AntSelect)`
  font-size: 14px;
  line-height: 21px;
  width: 100%;

  .ant-select-selector {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    line-height: 1.4;
    box-sizing: border-box;
    border: 1px solid #e5e5ef !important;
    border-radius: 4px !important;

    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 36px;
      }
    }

    .ant-select-selection-placeholder {
      padding-left: 2px;
    }

    .ant-select-selection-item {
      color: #4a4a4a;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid #bdbdbd;
      border-radius: 4px;
      margin-right: 8px;

      .ant-select-selection-item-remove {
        margin-top: 3px;
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid #003e72 !important;
      border-radius: 4px !important;
    }
  }

  ${({ error }) =>
    error &&
    css`
      .ant-select-selector {
        border: 1px solid #bf2600 !important;
      }
    `}

  ${space}
  ${layout}
`

const ErrorContainer = styled(Box)`
  svg {
    vertical-align: bottom;
  }
`

const InputTag = (props) => {
  const { error } = props

  const renderError = () => {
    return (
      <ErrorContainer mt={10}>
        <Icon name='inputError' />
        <Text color='#bf2600' fontSize={14} ml={12}>
          {error.message}
        </Text>
      </ErrorContainer>
    )
  }

  return (
    <Box>
      <SelectAntd
        {...props}
        mode='tags'
        tokenSeparators={[',', ' ']}
        open={false}
        removeIcon={<Icon name='closeTag'/>}
      />
      {error && renderError()}
    </Box>
  )
}

export default InputTag
