import { useState, useRef } from 'react'
import { useOutsideClick, usePropState } from 'utils/hooks'

import FilterButton from 'components/Filter/FilterButton'
import FilterDateMenu from './FilterDateMenu'
import moment from 'moment'
import styled from 'styled-components'
import { space } from 'styled-system'

const DATE_FORMAT = 'DD MMM YYYY'
const DEFAULT_VALUE = { start: null, end: null }

const FilterDateComponent = styled.div`
  display: inline-block;

  .filter-content {
    background: white;
    z-index: 9999;

    position: absolute;
    margin-top: 4px;
    display: none;

    &.active {
      display: block;
    }
  }

  ${space};
`

const getDateFromValue = (value, format) => {
  const input = Array.isArray(value) ? value[0] : value
  const mmValue = moment(input)

  if (input && mmValue.isValid()) {
    return {
      start: mmValue,
      end: mmValue
    }
  }

  return DEFAULT_VALUE
}

const FilterDate = ({ text, validDateRange, filterKey, value, format, onFilter, ...props }) => {
  const [isActivated, setIsActivated] = useState(false)
  const [date, setDate] = usePropState(getDateFromValue(value, format), DEFAULT_VALUE)
  const containerRef = useRef()

  function getFilterButtonLabel() {
    if (date['start']) {
      return date['start'].format(DATE_FORMAT)
    }

    return text
  }

  function handleOnChange(startDate, endDate) {
    setDate({ start: startDate, end: endDate })
    onFilter?.(filterKey, startDate, endDate)
  }

  function handleOnClear() {
    setDate(DEFAULT_VALUE)
    onFilter?.(filterKey, null, null)
  }

  function handleDisabledDate(current) {
    if (validDateRange) {
      const { start, end } = validDateRange
      const startDate = moment(start).startOf('day')
      const endDate = moment(end).endOf('day')

      return current < startDate || current > endDate
    }

    return false
  }

  function getDefaultPickerValue() {
    if (validDateRange) {
      return validDateRange['start']
    }
    return null
  }

  function getDateValue() {
    return date['start']
  }

  useOutsideClick(containerRef, () => setIsActivated(false))

  const isFiltered = date.start && date.end

  return (
    <FilterDateComponent ref={containerRef} {...props}>
      <FilterButton
        className='filter-button'
        label={getFilterButtonLabel()}
        activated={isActivated}
        filtered={isFiltered}
        onClick={(e) => setIsActivated(!isActivated)}
        onClear={handleOnClear}
      />
      <FilterDateMenu
        className={`filter-content ${isActivated ? 'active' : ''}`}
        title={text}
        onChange={handleOnChange}
        value={getDateValue()}
        defaultPickerValue={getDefaultPickerValue()}
        disabledDate={handleDisabledDate}
        format={format}
      />
    </FilterDateComponent>
  )
}

export default FilterDate
