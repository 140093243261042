import { Input as AntInput } from 'antd'
import { forwardRef } from 'react'
import { Box } from 'components'
import { ErrorMessage } from './material'

import styled from 'styled-components'
import { space, border, layout, typography } from 'styled-system'
import { styled_disabled, styled_error } from './material'

const StyledInput = styled(AntInput)`
  border-radius: 4px;

  &.ant-input-affix-wrapper {
    input:focus,
    input:hover {
      border: none !important;
    }
  }

  &.ant-input-affix-wrapper:hover,
  &.ant-input:hover,
  .ant-input:hover {
    border: 1px solid #e5e5ef;
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input:focus,
  .ant-input:focus {
    outline: none;
    border: 1px solid #003e72 !important;
    box-shadow: none;
  }

  ${border}
  ${layout}
  ${typography}

  .ant-input-group-addon {
    background-color: #fff;
    border: 1px solid #e5e5ef;
    border-right: 0px;
    border-radius: 4px 0 0 4px;
  }

  .ant-input,
  &.ant-input {
    appearance: none;
    color: #444;
    width: 100%;
    font-size: 14px;
    line-height: 1.85;
    border: 1px solid #e5e5ef;
    box-sizing: border-box;
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #babac8;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #babac8;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #babac8;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #babac8;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #babac8;
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      color: #babac8;
    }
  }

  ${styled_error};
  ${styled_disabled};
  ${space};
`

const Input = ({
  error,
  enabledRenderError,
  disabled,
  displayType = 'inline-block',
  wrapperStyle,
  ...rest
}, ref) => {
  return (
    <Box display={displayType} width='100%' style={wrapperStyle}>
      <StyledInput ref={ref} size='large' error={error} disabled={disabled} {...rest} />
      {error && <ErrorMessage enabled={enabledRenderError} message={error.message} />}
    </Box>
  )
}

export default forwardRef(Input)
