import { Controller } from 'react-hook-form'
import { useTheme } from 'styled-components'

import { Text, AutoComplete } from 'components'

import { get } from 'lodash'

const AutoCompleteForm = ({
  name,
  label,
  placeholder,
  control,
  errors,
  rules,
  filterOption = true,
  options = [],
  children,
  ...rest
}) => {
  const { colors } = useTheme()
  return (
    <div>
      {label && (
        <Text display='block' fontSize='12px' fontWeight='500' mb='8px' color={colors.dark500}>
          {label}
        </Text>
      )}
      <Controller
        {...rest}
        as={AutoComplete}
        style={{ width: '100%' }}
        name={name}
        control={control}
        errors={errors}
        options={options}
        filterOption={filterOption}
        placeholder={placeholder}
        rules={rules}
        error={get(errors, name)}
      />
    </div>
  )
}

export default AutoCompleteForm
