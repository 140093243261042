import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const Loading = () => {
  const antIcon = <StyledLoading spin />
  return <Spin indicator={antIcon} />
}

export default Loading

const StyledLoading = styled(LoadingOutlined)`
  svg {
    fill: #003e72;
    font-size: 24px;
  }
`
