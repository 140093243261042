import { getCricketEntitlementDisplayType } from './entitlements'

const CRICKET_ENTITLEMENT_DISPLAY_TYPES = getCricketEntitlementDisplayType()

const DISPLAY_TYPE_FULL_LED = CRICKET_ENTITLEMENT_DISPLAY_TYPES.FULL_LED
const DISPLAY_TYPE_HALF_LED = CRICKET_ENTITLEMENT_DISPLAY_TYPES.HALF_LED
const DISPLAY_TYPE_SIGHTSCREEN = CRICKET_ENTITLEMENT_DISPLAY_TYPES.SIGHTSCREEN
const DISPLAY_TYPE_PARAPET = CRICKET_ENTITLEMENT_DISPLAY_TYPES.PARAPET

const FILTER_OPTION_TITLE_FULL_LED = 'Full LED'
const FILTER_OPTION_TITLE_HAFL_LED = 'Half LED'
const FILTER_OPTION_TITLE_SIGHTSCREEN = 'Sight Screen'
const FILTER_OPTION_TITLE_PARAPET = 'Parapet'

const getSovTableDisplay = () => {
  return {
    DISPLAY_LED_PERIMETER: 'led_perimeter',
    DISPLAY_SIGHTSCREEN: 'sightscreen',
    DISPLAY_PARAPET: 'parapet',
    DISPLAY_EMPTY: 'empty'
  }
}

const getSovFilterDisplayOptionTitleMap = () => {
  return {
    [DISPLAY_TYPE_FULL_LED]: FILTER_OPTION_TITLE_FULL_LED,
    [DISPLAY_TYPE_HALF_LED]: FILTER_OPTION_TITLE_HAFL_LED,
    [DISPLAY_TYPE_SIGHTSCREEN]: FILTER_OPTION_TITLE_SIGHTSCREEN,
    [DISPLAY_TYPE_PARAPET]: FILTER_OPTION_TITLE_PARAPET
  }
}

const getSovFilterDisplayOptionValueMap = () => {
  return {
    [FILTER_OPTION_TITLE_FULL_LED]: DISPLAY_TYPE_FULL_LED,
    [FILTER_OPTION_TITLE_HAFL_LED]: DISPLAY_TYPE_HALF_LED,
    [FILTER_OPTION_TITLE_SIGHTSCREEN]: DISPLAY_TYPE_SIGHTSCREEN,
    [FILTER_OPTION_TITLE_PARAPET]: DISPLAY_TYPE_PARAPET
  }
}

const getCricketSovUnit = () => ({
  PARAPET: 16.666666666666668
})

export {
  getSovTableDisplay,
  getSovFilterDisplayOptionTitleMap,
  getSovFilterDisplayOptionValueMap,
  getCricketSovUnit
}
