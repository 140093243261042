import { DatePicker as AntDatePicker } from 'antd'

import styled, { css } from 'styled-components'
import { Box, Icon, Text } from 'components'

const {
  RangePicker: AntRangePicker
} = AntDatePicker


const AntdRangePicker = styled(AntRangePicker)`
  &.ant-picker {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 4px;
    height: 40px;
    border: 1px solid #e5e5ef;

    &:hover {
      background: #f7f7f9 !important;
    }
  }

  ${({ error }) => error && css`
    &.ant-picker {
      border: 1px solid #bf2600 !important;
    }
  `}

  &.ant-picker-focused {
    border: 1px solid #003e72;
  }

  &:not(.ant-picker-focused).ant-picker:hover {
    border: 1px solid #e5e5ef;
  }

  &.ant-picker-range .ant-picker-active-bar {
    background: #003e72;
  }
`

const ErrorContainer = styled.div`
  margin-top: 10px;
  display='inline-block';

  svg {
    vertical-align: bottom;
  }
`

const RangPicker = (props) => {
  const { error, enabledRenderError } = props
  const renderError = () => {
    if (enabledRenderError === false) {
      return null
    }

    return (
      <ErrorContainer>
        <Icon name='inputError' />
        <Text color='#bf2600' fontSize={14} ml={12}>{error.message}</Text>
      </ErrorContainer>
    )
  }

  return (
    <Box>
      <AntdRangePicker
        style={{
          width: 270
        }}
        {...props}
        placeholder={['Start date', 'End date']}
        suffixIcon={<Icon name='selectCalendar' />}
        allowClear={false}
      />
      {error && renderError()}
    </Box>
  )
}

export default RangPicker
