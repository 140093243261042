import React from 'react'
import styled from 'styled-components'
import { usePropState } from 'utils/hooks'
import { Icon, Text, Box } from 'components'
import { clsx } from 'utils/helper'

const Container = styled.div`
  color: ${({ theme }) => theme.colors.text600};

  &,
  .accordion-item {
    user-select: none;
  }
`

const AccordionItem = ({ children, value, className, ...stylingProps }) => {
  return (
    <Box mt='2px' width='100%' {...stylingProps} className={clsx('accordion-item', className)}>
      <Text width={100}>{children}</Text>
      <Text>{value}</Text>
    </Box>
  )
}

function Accordion({
  children,
  className,
  title,
  collapsed = true,
  iconName = 'expandArrowDown',
  style
}) {
  const [expanded, setExpanded] = usePropState(!collapsed)

  return (
    <Container style={style} className={clsx('accordion-list', className)}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Text width={100}>{title}</Text>
          <Text>{React.Children.count(children) || 'N/A'}</Text>
        </Box>
        <Icon
          style={{ transform: `rotate(${expanded ? -180 : 0}deg)`, transition: 'all 0.3s' }}
          onClick={() => setExpanded(!expanded)}
          name={iconName}
          clickable
          size={24}
          color='currentColor'
        />
      </Box>
      {expanded && <Box>{children}</Box>}
    </Container>
  )
}

Accordion.Item = AccordionItem

export default Accordion
