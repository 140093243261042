import { Controller } from 'react-hook-form'
import { Row, Col, TextArea } from 'components'

import { get } from 'lodash'
import { Label, getWidth } from './helper'

const TextAreaForm = ({
  name,
  label,
  initialValue,
  control,
  errors,
  rules,
  register,
  width,
  labelWidth,
  labelMargin,
  autoSize = { minRows: 2, maxRows: 5 },
  ...props
}) => {
  return (
    <Row alignItems='center'>
      <Label gutter={width ? '0' : '8px'} useCustomWidth={labelWidth} width={labelWidth || '100%'}>
        {label}
      </Label>
      <Col width={getWidth({ labelWidth, width })} flex='unset'>
        <Controller
          {...props}
          as={TextArea}
          name={name}
          control={control}
          defaultValue={initialValue}
          rules={rules || {}}
          error={get(errors, name)}
          autoSize={autoSize}
          ref={register}
        />
      </Col>
    </Row>
  )
}

export default TextAreaForm
