import { useMemo, useState } from 'react'
import { useAbility } from 'utils/hooks'
import { useWatch, useController } from 'react-hook-form'

// UI
import { RadioForm, Text, Modal } from 'components'

// UTIL
import styled from 'styled-components'
import { validateChangeStatus } from './helper'
import ENUM from 'constants/enums'

const EntitlementStatusForm = ({ control, name, mode = 'add', mediaType, ...props }) => {
  const defaultValue = control.defaultValuesRef?.current?.[name]

  const [visible, setVisible] = useState(false)
  const value = useWatch({ control, defaultValue, name })
  const { field } = useController({ control, name })
  const ability = useAbility()

  const { title, description } = useMemo(() => {
    if (mode === 'add') return { title: '', description: '' }

    const configure = validateChangeStatus(defaultValue, value, mediaType)

    setVisible(!!configure.description)
    return configure
  }, [mode, value, defaultValue, mediaType])

  const handleCancel = () => {
    setVisible((bool) => {
      if (bool) field.onChange(defaultValue)

      return false
    })
  }

  const handleOk = () => {
    setVisible(false)
  }

  const optStatuses = useMemo(() => {
    const getDisabled = (allowed) => {
      return defaultValue === ENUM.ENTITLEMENT_STATUS.CONFIRMED && !allowed
    }

    const options = [
      {
        label: 'Open',
        disabled: getDisabled(ability.can('re-open', 'Contract')),
        value: ENUM.ENTITLEMENT_STATUS.OPEN
      },
      {
        label: 'Confirm',
        value: ENUM.ENTITLEMENT_STATUS.CONFIRMED
      }
    ]

    if (mediaType === ENUM.ENTITLEMENT_MEDIA_TYPE.MEDIA) {
      options.splice(1, 0, {
        label: 'Hold',
        disabled: getDisabled(ability.can('re-open', 'Contract')),
        value: ENUM.ENTITLEMENT_STATUS.HOLD
      })
    }

    return options
  }, [ability, defaultValue, mediaType])

  const markInfo = useMemo(() => {
    const info = [
      <p>OPEN does not commit entitlement anywhere.</p>,
      <p>CONFIRM commits this entitlement to both Inventory and the rundown.</p>
    ]

    if (mediaType === ENUM.ENTITLEMENT_MEDIA_TYPE.MEDIA) {
      info.splice(1, 0, <p>HOLD commits this entitlement to Inventory but not the rundown.</p>)
    }
    return <MarkContainer>{info}</MarkContainer>
  }, [mediaType])

  return (
    <>
      <RadioForm
        {...props}
        initialValue={defaultValue}
        name={name}
        requiredMark
        markInfo={markInfo}
        control={control}
        options={optStatuses}
      />
      <Modal
        visible={visible}
        title={title}
        okText='Submit'
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text fontWeight={400} fontSize='14px'>
          {description}
        </Text>
      </Modal>
    </>
  )
}

const MarkContainer = styled.div`
  width: 216px;
  p {
    font-size: 12px;
  }
  p:last-child {
    margin-bottom: 0;
  }
`

export default EntitlementStatusForm
