import styled, { css } from 'styled-components'
import { space, layout, flex, border, background, flexbox } from 'styled-system'


const CardWrapper = styled.div`
  position: relative;
  background: #fff;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 1px 2px rgba(96, 97, 112, 0.16);
  box-sizing: border-box;

  .extra {
    position: absolute;
    right: 0;
    top: 0;
  }

  ${({ bordered, borderColor }) => bordered && css`
    .bordered {
      height: 4px;
      width: 100%;
      background: ${borderColor ? borderColor : '#4D7ECB'};
      border-radius: 8px 8px 0px 0px;

      position: absolute;
      left: 0;
      top: 0;
      right: 0;
    }
  `}

  ${space}
  ${layout}
  ${flexbox}
  ${flex}
  ${border}
  ${background}
`

const Card = (props) => {
  const { children, extra, bordered } = props
  return (
    <CardWrapper {...props}>
      {bordered && <div className='bordered' />}
      {extra && <div className='extra'>{extra}</div>}
      {children}
    </CardWrapper>
  )
}

export default Card
