/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useRef } from 'react'
import noop from 'lodash/noop'


export const EditContext = createContext({
  editing: false,
  toggle: noop,
  set: noop,
  onOk: noop,
  onCancel: noop
})

const EditContextProvider = ({ children, editing, setEditing }) => {
  const actionRef = useRef({ onOk: noop, onCancel: noop })

  const toggle = useCallback(() => {
    setEditing(prev => !prev)
  }, [])

  const onOk = useCallback(() => {
    actionRef.current.onOk?.()
    toggle()
  }, [])

  const onCancel = useCallback(() => {
    actionRef.current.onCancel?.()
    toggle()
  }, [])

  const set = useCallback((key, func) => {
    if (key === 'ok') {
      actionRef.current.onOk = func
    }

    if (key === 'cancel') {
      actionRef.current.onCancel = func
    }
  }, [])

  return (
    <EditContext.Provider
      value={{
        editing,
        toggle,
        set,
        onOk,
        onCancel
      }}
    >
      {children}
    </EditContext.Provider>
  )
}

export const useFooterEdit = () => useContext(EditContext)

export default EditContextProvider
