import { Col as AntCol } from 'antd'

import styled from 'styled-components'
import { space, flexbox, layout, typography } from 'styled-system'

const Col = styled(AntCol)`
  flex: 1 1 auto;
  ${space};
  ${flexbox};
  ${layout};
  ${typography};
`

export default Col
