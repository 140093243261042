import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'
import capitalize from 'lodash/capitalize'

const STATUS = {
  1: 'open',
  2: 'hold',
  3: 'confirm',
  99: 'multiple'
}

const getStatusDisplay = (key, capitalized) => {
  const statusDisplay = STATUS[key] || key
  return capitalized ? capitalize(statusDisplay) : statusDisplay
}

const getDefaultStatusDisplay = ({ type }) => {
  return capitalize(STATUS[type])
}

const handleType = (type) => {
  switch(getStatusDisplay(type)){
  case 'open':
    return css`
      color: #198BDB;
      background: #E3F2FD;
    `
  case 'hold':
    return css`
      color: #949494;
      background: #F1F1F1;
    `
  case 'confirm':
    return css`
      color: #5DC976;
      background: #E5FFEB;
    `
  case 'active':
    return css`
      color: #5DC976;
      background: #E5FFEB;
    `
  case 'bonus':
    return css`
      color: #949494;
      border: 1px solid #949494;
      background: transparent;
    `
  case 'multiple':
    return css`
      color: #401cdc;
      background: #eae8f8;
    `
  default:
    return css``
  }
}

const LabelComponent = styled.span`
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 4px;


  ${({ type }) => handleType(type)};

  ${space};
  ${layout}
`

const Label = (props) => {
  return (
    <LabelComponent {...props}>
      {props.children || getDefaultStatusDisplay(props)}
    </LabelComponent>
  )
}

Label.getStatusDisplay = getStatusDisplay

export default Label