const getSpotType = () => {
  return {
    SOLD: 'Sold',
    SOLD_BONUS: 'Sold Bonus',
    PREF_BONUS: 'Pref. Bonus',
    BONUS: 'Bonus',
  }
}

export {
  getSpotType,
}