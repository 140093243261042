import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default styled(Link)`
  &,
  :hover,
  :focus,
  :active {
    color: inherit;
    text-decoration: inherit;
  }
`
