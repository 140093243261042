import { Row as AntRow } from 'antd'

import styled from 'styled-components'
import { border, flexbox, space, position, layout } from 'styled-system'

const Row = styled(AntRow)`
  display: flex;
  ${position}
  ${space}
  ${flexbox}
  ${border}
  ${layout}
`

export default Row
