import { Input as AntInput } from 'antd'
import Box from 'components/Box'
import { ErrorMessage } from './material'
import styled, { css } from 'styled-components'

const { TextArea: AntTextArea } = AntInput

const Container = styled(Box)`
  width: 100%;
  textarea {
    resize: none;
  }
`

const StyledTextArea = styled(AntTextArea)`
  &.ant-input {
    border-radius: 4px;

    :hover,
    :focus {
      border-color: #003e72;
      outline: none;
      box-shadow: none;
    }

    ${({ error }) => error && css`border-color: #bf2600`}
  }
`

const TextArea = ({ error, enabledRenderError, displayType = 'inline-block', ...props }) => {
  return (
    <Container display={displayType}>
      <StyledTextArea error={error} {...props} />
      {error && <ErrorMessage enabled={enabledRenderError} message={error.message} />}
    </Container>
  )
}

export default TextArea
