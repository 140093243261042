import { useState } from 'react'

export default function useLocalStorage(key, defaultValue) {
  const [value, update] = useState(() => {
    const text = localStorage.getItem(key)
    if (text) {
      return JSON.parse(text)
    }

    return defaultValue !== undefined ? defaultValue : text
  })

  const setValue = (value) => {
    const text = JSON.stringify(value)
    localStorage.setItem(key, text)
    update(value)
  }

  return [value, setValue]
}
