import { Radio as AntRadio } from 'antd'

import styled from 'styled-components'
import { space } from 'styled-system'

const Radio = styled(AntRadio)`
  .ant-radio-inner:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #003e72;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #003e72;
    transform: translate(-50%, -50%) scale(1);
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #003e72;
  }

  ${space};
`

export default Radio
