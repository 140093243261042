import { Controller } from 'react-hook-form'
import { Box, Text, Switch } from 'components'
import { clsx } from 'utils/helper'
import { useTheme } from 'styled-components'


const SwitchForm = ({
  label,
  name,
  rules,
  initialValue,
  labelProps,
  control,
  wrapperClassName,
  className,
  disabled,
  style,
  showHint,
  hintProps,
  ...props
}) => {
  const { colors } = useTheme()
  return (
    <Box className={clsx('switch-field', wrapperClassName)}>
      {label && (
        <Text fontSize='12px' fontWeight='500' mr={showHint ? 0 : 24} {...labelProps} color={colors.dark500}>
          {label}
        </Text>
      )}
      <Controller
        defaultValue={initialValue}
        name={name}
        render={({ onChange, value }) => {
          const defaultChecked = initialValue || false

          return (
            <>
              {showHint && (
                <Switch.Hint mr='24px' fontSize='12px' {...hintProps} checked={value ?? defaultChecked} />
              )}
              <Switch
                className={className}
                style={style}
                defaultChecked={defaultChecked}
                checked={value}
                disabled={disabled}
                onChange={(checked) => {
                  onChange(checked)
                  props.onChange?.(checked)
                }}
              />
            </>
          )
        }}
        rules={rules}
        control={control}
      />
    </Box>
  )
}

export default SwitchForm
