import React, { useEffect, useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Menu } from 'antd'
import styled from 'styled-components'

import Logo from 'assets/images/logo.png'
import PoweredBySeedooh from 'assets/images/powered-by-seedooh.svg'
import { Icon, Can } from 'components'

import last from 'lodash/last'
import { clsx, scrollToElement } from 'utils/helper'

const { SubMenu } = Menu

const Sidebar = styled.div`
  --height: 100vh;
  --width: var(--sider-width, 180px);

  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);
  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);

  background: #00325f;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  .header {
    padding: 40px 24px 40px;
    height: 104px;
  }

  .powered-by {
    display: flex;
    justify-content: center;
    margin: 16px 0 40px;
    user-select: none;
  }

  .sidebar-menu {
    flex: 1;
    overflow: overlay;

    .sidebar-menu-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #babac8;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      text-decoration: none;

      svg {
        stroke: #babac8;
      }

      &.active,
      :hover {
        color: #fff;

        svg {
          stroke: white;
        }
      }

      &.sub-item {
        padding: 0px 8px 0px 12px;
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .header,
  .powered-by,
  .sidebar-menu {
    width: 100%;
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: unset;
    cursor: unset;
    padding: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;

    color: #babac8;
  }

  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow {
      display: none;
    }

    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin: unset;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;

    .sidebar-menu-item {
      color: #fff;
    }

    svg {
      stroke: white;
    }
  }

  .ant-menu-submenu .ant-menu-submenu-title {
    height: unset;
    line-height: unset;
    margin: unset;

    display: flex;
    align-items: center;
    padding: 20px 0px 20px 24px;
    color: #babac8;

    &.active,
    :hover {
      color: #fff;

      svg {
        stroke: white;
      }

      .svg-fill {
        stroke: none;

        path {
          fill: white;
        }
      }
    }
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: unset;
    margin-bottom: unset;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: unset;
    line-height: unset;
    list-style-position: inside;
    list-style-type: disc;
    padding: 10px 0px 10px 24px;
  }

  .ant-menu-item {
    height: unset;
    line-height: unset;
    white-space: normal;

    display: flex;
    align-items: center;
    padding: 20px 0px 20px 24px;
  }
`

const getRoute = ({ pathname }) => {
  const basicPathname = ['/partners', '/scheduling', '/inventory']
  const nestedPathname = [
    '/settings/scheduling',
    '/settings/user-activity',
    '/settings/user',
    '/settings/account',
    '/settings/rundown-type',
    '/settings/terms-and-conditions',
    '/administration/venue',
    '/administration/display-type',
    '/administration/sport-team',
    '/administration/sport-type',
    '/administration/entitlement-templates',
    '/administration/production-and-nonmedia'
  ]

  const getPathname = (names) => names.find((uri) => pathname.includes(uri))
  const result = getPathname(nestedPathname)
    ? { key: getPathname(nestedPathname), nested: true }
    : { key: getPathname(basicPathname), nested: false }

  return result.key ? result : null
}

const getOpenKey = (location) => {
  const route = getRoute(location)

  if (route && route.nested) {
    const [key] = route.key.split('/').filter((txt) => txt)
    return [`/${key}`]
  }

  return []
}

const getSelectKey = (location) => {
  const route = getRoute(location)
  return route ? [route.key] : ['/']
}

const buildMenu = ({ key, icon, children }) => {
  return (
    <Menu.Item key={key} data-menu-key={key}>
      <Link to={key} className='sidebar-menu-item'>
        <Icon name={icon} mr='12px' />
        {children}
      </Link>
    </Menu.Item>
  )
}

const buildSubMenu = (items) => {
  return items.map(({ key, children, ...item }) => {
    return (
      <Menu.Item key={key} {...item} data-menu-key={key}>
        <Link to={key} className='sidebar-menu-item sub-item'>
          {children}
        </Link>
      </Menu.Item>
    )
  })
}

export default function Sider({ className, onCollapse }) {
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState(() => getSelectKey(location))
  const [openKeys, setOpenKeys] = useState(() => getOpenKey(location))

  useEffect(() => {
    const list = getSelectKey(location)
    setSelectedKeys(list)
    setOpenKeys(getOpenKey(location))
    if (list.length) {
      scrollToElement(`li[data-menu-key='${list[0]}']`, { behavior: 'auto' })
    }
  }, [location])

  const handleOpenChange = useCallback((openKeys) => {
    const key = last(openKeys)
    setOpenKeys(key ? [key] : [])
  }, [])

  return (
    <Sidebar key='appSidebar' className={clsx('sidebar', className)}>
      <div key='appHeader' className='header'>
        <img width={131.66} src={Logo} alt='logo' />
      </div>
      <div key='appSidebarMenu' className='sidebar-menu'>
        <Can I='access' an='AdminSettings' passThrough>
          {(allowed) => (
            <Menu
              mode='inline'
              style={{ height: '100%', borderRight: 0 }}
              theme='dark'
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
              defaultOpenKeys={openKeys}
              openKeys={openKeys}
              onOpenChange={handleOpenChange}
            >
              {buildMenu({
                key: '/',
                icon: 'home',
                children: 'Home'
              })}
              {buildMenu({
                key: '/partners',
                icon: 'partner',
                children: 'Partner & Contracts'
              })}
              {buildMenu({
                key: '/scheduling',
                icon: 'scheduling',
                children: 'Scheduling'
              })}
              {buildMenu({
                key: '/inventory',
                icon: 'inventory',
                children: 'Inventory Management'
              })}
              <SubMenu
                key='/administration'
                icon={<Icon name='administrator' className='svg-fill' mr='12px' />}
                title='Administration'
              >
                {buildSubMenu([
                  {
                    key: '/administration/sport-type',
                    children: 'Sport Type Management'
                  },
                  {
                    key: '/administration/sport-team',
                    children: 'Sport Team Management'
                  },
                  {
                    key: '/administration/venue',
                    children: 'Venue Management'
                  },
                  {
                    key: '/administration/display-type',
                    children: 'Display Type Management',
                    disabled: !allowed
                  },
                  {
                    key: '/administration/entitlement-templates',
                    children: 'Entitlement Templates',
                    disabled: !allowed
                  },
                  {
                    key: '/administration/production-and-nonmedia',
                    children: 'Production & Non-Media Management',
                    disabled: !allowed
                  }
                ])}
              </SubMenu>
              <SubMenu key='/settings' icon={<Icon name='setting' mr='12px' />} title='Settings'>
                {buildSubMenu([
                  {
                    key: '/settings/scheduling',
                    children: 'Scheduling Settings'
                  },
                  {
                    key: '/settings/user',
                    children: 'User Settings',
                    disabled: !allowed
                  },
                  {
                    key: '/settings/account',
                    children: 'Account Settings'
                  },
                  {
                    key: '/settings/terms-and-conditions',
                    children: "T&C's Settings"
                  },
                  {
                    key: '/settings/rundown-type',
                    children: 'Rundown Types Settings'
                  },
                  {
                    key: '/settings/user-activity',
                    children: 'User Activity Logs'
                  }
                ])}
              </SubMenu>
            </Menu>
          )}
        </Can>
      </div>
      <div className='powered-by' onDoubleClick={onCollapse} onTouchStart={onCollapse}>
        <img src={PoweredBySeedooh} alt='powered by Seedooh' />
      </div>
    </Sidebar>
  )
}
