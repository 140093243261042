import { ReactComponent as Home } from 'assets/icon/home.svg'
import { ReactComponent as Partner } from 'assets/icon/partner.svg'
import { ReactComponent as Inventory } from 'assets/icon/inventory.svg'
import { ReactComponent as Scheduling } from 'assets/icon/scheduling.svg'
import { ReactComponent as SchedulingBlack } from 'assets/icon/scheduling-black.svg'
import { ReactComponent as Setting } from 'assets/icon/setting.svg'
import { ReactComponent as Success } from 'assets/icon/success.svg'
import { ReactComponent as Warning } from 'assets/icon/warning.svg'
import { ReactComponent as Error } from 'assets/icon/error.svg'
import { ReactComponent as Star } from 'assets/icon/star.svg'
import { ReactComponent as Favourite } from 'assets/icon/favourite.svg'
import { ReactComponent as TimeCircle } from 'assets/icon/time-circle.svg'
import { ReactComponent as RecentActivity } from 'assets/icon/recent-activity.svg'
import { ReactComponent as RecentVisit } from 'assets/icon/recent-visit.svg'
import { ReactComponent as SchedulingEmpty } from 'assets/icon/scheduling-empty.svg'
import { ReactComponent as CirclePlusWhite } from 'assets/icon/circle-plus-white.svg'
import { ReactComponent as CirclePlusBlue } from 'assets/icon/circle-plus-blue.svg'
import { ReactComponent as Box } from 'assets/icon/box.svg'
import { ReactComponent as EditSquare } from 'assets/icon/edit-square.svg'
import { ReactComponent as PlusCircle } from 'assets/icon/plus-circle.svg'
import { ReactComponent as PlusBlueCircle } from 'assets/icon/plus-blue-circle.svg'
import { ReactComponent as Dropdown } from 'assets/icon/dropdown.svg'
import { ReactComponent as SelectDropdown } from 'assets/icon/select-dropdown.svg'
import { ReactComponent as SortUp } from 'assets/icon/sort-up.svg'
import { ReactComponent as SortDown } from 'assets/icon/sort-down.svg'
import { ReactComponent as CloseModal } from 'assets/icon/close-modal.svg'
import { ReactComponent as Remove } from 'assets/icon/remove.svg'
import { ReactComponent as VisualEmpty } from 'assets/icon/visual-empty.svg'
import { ReactComponent as Search } from 'assets/icon/search.svg'
import { ReactComponent as InputError } from 'assets/icon/input-error.svg'
import { ReactComponent as Calendar } from 'assets/icon/calendar.svg'
import { ReactComponent as SelectCalendar } from 'assets/icon/select-calendar.svg'
import { ReactComponent as UploadSuccess } from 'assets/icon/upload-success.svg'
import { ReactComponent as UploadError } from 'assets/icon/upload-error.svg'
import { ReactComponent as Uploading } from 'assets/icon/uploading.svg'
import { ReactComponent as Document } from 'assets/icon/document.svg'
import { ReactComponent as Paper } from 'assets/icon/paper.svg'
import { ReactComponent as Bin } from 'assets/icon/bin.svg'
import { ReactComponent as Pencil } from 'assets/icon/pencil.svg'
import { ReactComponent as CloseCircle } from 'assets/icon/close-circle.svg'
import { ReactComponent as Refresh } from 'assets/icon/refresh.svg'
import { ReactComponent as RefreshTwoArrow } from 'assets/icon/refresh-two-arrow.svg'
import { ReactComponent as ChevronDown } from 'assets/icon/chevron-down.svg'
import { ReactComponent as ExpandArrowUp } from 'assets/icon/expand-arrow-up.svg'
import { ReactComponent as ExpandArrowDown } from 'assets/icon/expand-arrow-down.svg'
import { ReactComponent as ArrowDown } from 'assets/icon/arrow-down.svg'
import { ReactComponent as ArrowRight } from 'assets/icon/arrow-right.svg'
import { ReactComponent as ArrowLeft } from 'assets/icon/arrow-left.svg'
import { ReactComponent as Download } from 'assets/icon/download.svg'
import { ReactComponent as Correct } from 'assets/icon/correct.svg'
import { ReactComponent as Danger } from 'assets/icon/danger.svg'
import { ReactComponent as Edit } from 'assets/icon/edit.svg'
import { ReactComponent as Drag } from 'assets/icon/drag.svg'
import { ReactComponent as Swap } from 'assets/icon/swap.svg'
import { ReactComponent as Restore } from 'assets/icon/restore.svg'
import { ReactComponent as Delete } from 'assets/icon/delete.svg'
import { ReactComponent as MoreSquare } from 'assets/icon/more-square.svg'
import { ReactComponent as PasswordKeyLock } from 'assets/icon/password-key-lock.svg'
import { ReactComponent as Photo } from 'assets/icon/photo.svg'
import { ReactComponent as PlusAdd } from 'assets/icon/plus-add.svg'
import { ReactComponent as UserProfile } from 'assets/icon/user-profile.svg'
import { ReactComponent as InviteUser } from 'assets/icon/invite-user.svg'
import { ReactComponent as CloseTag } from 'assets/icon/close-tag.svg'
import { ReactComponent as Popup } from 'assets/icon/popup.svg'
import { ReactComponent as Info } from 'assets/icon/info.svg'
import { ReactComponent as InfoFlip } from 'assets/icon/info-flip.svg'
import { ReactComponent as Recalculate } from 'assets/icon/recalculate.svg'
import { ReactComponent as Notify } from 'assets/icon/notify.svg'
import { ReactComponent as Convert } from 'assets/icon/convert.svg'
import { ReactComponent as DollarPaper } from 'assets/icon/dollar-paper.svg'
import { ReactComponent as TinyCalendar } from 'assets/icon/tiny-calendar.svg'
import { ReactComponent as Upload } from 'assets/icon/upload.svg'
import { ReactComponent as Hamburger } from 'assets/icon/hamburger.svg'
import { ReactComponent as Preview } from 'assets/icon/preview.svg'
import { ReactComponent as Caution } from 'assets/icon/caution.svg'
import { ReactComponent as SetPassword } from 'assets/icon/set_password.svg'
import { ReactComponent as Emailed } from 'assets/icon/emailed.svg'
import { ReactComponent as PartnerSignContract } from 'assets/icon/partner-sign-contract.svg'
import { ReactComponent as Eye } from 'assets/icon/eye.svg'
import { ReactComponent as BinWhite } from 'assets/icon/bin-white.svg'
import { ReactComponent as BinFilled } from 'assets/icon/bin-filled.svg'
import { ReactComponent as AdobeSign } from 'assets/icon/adobe-sign.svg'
import { ReactComponent as Administrator } from 'assets/icon/administrator.svg'
import { ReactComponent as MoleculeVector } from 'assets/icon/molecule-vector.svg'
import { ReactComponent as Merge } from 'assets/icon/merge.svg'
import { ReactComponent as UndoMerge } from 'assets/icon/undo-merge.svg'
import { ReactComponent as OrderUp } from 'assets/icon/arrow_order-up.svg'
import { ReactComponent as OrderDown } from 'assets/icon/arrow_order-down.svg'
import { ReactComponent as OrderTop } from 'assets/icon/arrow_order-top.svg'
import { ReactComponent as OrderBottom } from 'assets/icon/arrow_order-bottom.svg'
import { ReactComponent as Clear } from 'assets/icon/clear.svg'
import { ReactComponent as ContractCalendar } from 'assets/icon/contract_calendar.svg'
import { ReactComponent as Hide } from 'assets/icon/hide.svg'
import { ReactComponent as EmptyBox } from 'assets/icon/emptyBox.svg'
import { ReactComponent as Uploaded } from 'assets/icon/uploaded.svg'

const assets = () => {
  return {
    Icons: {
      home: Home,
      partner: Partner,
      inventory: Inventory,
      scheduling: Scheduling,
      schedulingBlack: SchedulingBlack,
      success: Success,
      warning: Warning,
      error: Error,
      star: Star,
      favourite: Favourite,
      timeCircle: TimeCircle,
      recentActivity: RecentActivity,
      recentVisit: RecentVisit,
      schedulingEmpty: SchedulingEmpty,
      circlePlusWhite: CirclePlusWhite,
      circlePlusBlue: CirclePlusBlue,
      box: Box,
      editSquare: EditSquare,
      plusCircle: PlusCircle,
      plusBlueCircle: PlusBlueCircle,
      dropdown: Dropdown,
      selectDropdown: SelectDropdown,
      sortUp: SortUp,
      sortDown: SortDown,
      closeModal: CloseModal,
      remove: Remove,
      visualEmpty: VisualEmpty,
      search: Search,
      inputError: InputError,
      calendar: Calendar,
      tinyCalendar: TinyCalendar,
      selectCalendar: SelectCalendar,
      uploadSuccess: UploadSuccess,
      uploadError: UploadError,
      uploading: Uploading,
      document: Document,
      paper: Paper,
      setting: Setting,
      bin: Bin,
      pencil: Pencil,
      closeCircle: CloseCircle,
      refresh: Refresh,
      refreshTwoArrow: RefreshTwoArrow,
      chevronDown: ChevronDown,
      expandArrowUp: ExpandArrowUp,
      expandArrowDown: ExpandArrowDown,
      arrowDown: ArrowDown,
      arrowRight: ArrowRight,
      arrowLeft: ArrowLeft,
      download: Download,
      correct: Correct,
      danger: Danger,
      edit: Edit,
      drag: Drag,
      swap: Swap,
      restore: Restore,
      delete: Delete,
      moreSquare: MoreSquare,
      passwordKeyLock: PasswordKeyLock,
      photo: Photo,
      plusAdd: PlusAdd,
      userProfile: UserProfile,
      inviteUser: InviteUser,
      closeTag: CloseTag,
      popup: Popup,
      info: Info,
      infoFlip: InfoFlip,
      recalculate: Recalculate,
      notify: Notify,
      convert: Convert,
      dollarPaper: DollarPaper,
      upload: Upload,
      hamburger: Hamburger,
      preview: Preview,
      caution: Caution,
      setPassword: SetPassword,
      emailed: Emailed,
      partnerSignContract: PartnerSignContract,
      eye: Eye,
      binWhite: BinWhite,
      binFilled: BinFilled,
      adobeSign: AdobeSign,
      administrator: Administrator,
      moleculeVector: MoleculeVector,
      merge: Merge,
      undoMerge: UndoMerge,
      orderUp: OrderUp,
      orderDown: OrderDown,
      orderTop: OrderTop,
      orderBottom: OrderBottom,
      clear: Clear,
      contractCalendar: ContractCalendar,
      hide: Hide,
      emptyBox: EmptyBox,
      uploaded: Uploaded,
    }
  }
}

export default assets()
