import { Errors, Validate } from './resource'
import { getDefaultRules, getRequiredError, getRuleNames, isNilInput } from './helper'

const defaultErrors = Errors()

export default function ExpectSchema(
  { required, ...expectValuesSchemas },
  customErrors = defaultErrors
) {
  const { customRuleNames, defaultRuleNames } = getRuleNames(expectValuesSchemas)

  return {
    ...getDefaultRules(expectValuesSchemas, defaultRuleNames),
    required: getRequiredError(required, customErrors),

    validate: (input) => {
      let message
      for (let key of customRuleNames) {
        // the validate function use a Decimal library
        const isNumberValidator = ['minimum', 'maximum', 'step'].includes(key)
        if (isNumberValidator && isNilInput(input)) continue

        const validate = Validate[key]
        if (typeof validate === 'function') {
          const config = expectValuesSchemas[key]
          const error = customErrors[key] || defaultErrors[key]

          if (typeof config === 'object') {
            const _error =
              typeof config.message === 'string' ? () => config.message : config.message

            message = validate(config.value, input, _error || error)
          } else {
            message = validate(config, input, error)
          }

          if (message) break
        }
      }
      return message
    }
  }
}

// # How to use
//
// ### Basic
// rules={ExpectSchema({
//   maximum: 9999,
// })}
//
// ### with complex
// rules={ExpectSchema({
//   maximum: {
//     value: 9999,
//     message: 'Custom error message...'
//   }
// })}
//
// ### with default rule model of react-hook-form
// rules={ExpectSchema({
//   maximum: 9999, // for string value validation
//   max: {
//     value: 30,
//     message: 'You cannot have more than 30 minutes.'
//   },
// })}
