import styled, { css } from 'styled-components'
import {
  space,
  color,
  layout,
  typography,
  position,
  border,
  flexbox,
  background
} from 'styled-system'
import { cssUnit } from 'utils/helper'

const hideScrollBar = ({ hideScrollBar }) =>
  hideScrollBar
    ? css`
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;

        ::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }
      `
    : css``

const customScrollBar = ({ customScrollBar }) =>
  customScrollBar
    ? css`
        --scroll-bar-width: 4px;
        --scroll-thumb-radius: 8px;

        ::-webkit-scrollbar:vertical {
          width: var(--scroll-bar-width);
        }

        ::-webkit-scrollbar:horizontal {
          height: var(--scroll-bar-width);
        }

        ::-webkit-scrollbar-thumb {
          background: #c1c1c1;
        }

        ::-webkit-scrollbar,
        ::-webkit-scrollbar-thumb {
          border-radius: var(--scroll-thumb-radius);
        }
      `
    : css``

const size = ({ size }) =>
  size
    ? css`
        width: ${cssUnit(size)};
        height: ${cssUnit(size)};
      `
    : css``

const Box = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  ${(p) =>
    p.center
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : css``}
  ${space};
  ${color};
  ${layout};
  ${typography};
  ${position};
  ${border};
  ${flexbox};
  ${background};
  ${hideScrollBar}
  ${customScrollBar}
  ${size}
`

export default Box
