import styled, { css } from 'styled-components'
import { position, space } from 'styled-system'

export default styled.div`
  display: inline-block;
  border-radius: 50%;

  ${({ size }) => size && css`
    width: ${size};
    height:  ${size};`}

  ${({ color }) => color && css`background-color: ${color};`}

  ${space};
  ${position};
`
