import { useRef } from 'react'
import { Text, Divider, DatePicker } from 'components'
import styled from 'styled-components'

const FilterDateMenuComponent = styled.div`
  border: 1px solid #e5e5ef;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(198, 198, 198, 0.5);
  border-radius: 4px;
  padding: 16px;

  width: 280px;

  .ant-picker {
    height: 40px;
    .ant-picker-input {
      input {
        font-size: 14px;
      }
    }
  }

  .ant-picker-dropdown {
    top: 120px !important;
    left: 0px !important;
  }
`

const FilterDateMenu = ({
  title,
  value,
  defaultPickerValue,
  format,
  onChange,
  disabledDate,
  ...props
}) => {
  const containerRef = useRef()

  function handleOnChange(date, dateString) {
    onChange?.(date, date)
  }

  function getPopupContainer() {
    return containerRef.current
  }

  return (
    <FilterDateMenuComponent {...props} ref={containerRef}>
      <Text fontSize='14px' fontWeight='600'>
        {title}
      </Text>
      <Divider margin='10px -16px' />
      <DatePicker
        suffixIcon={false}
        format={format}
        onChange={handleOnChange}
        value={value}
        disabledDate={disabledDate}
        defaultPickerValue={defaultPickerValue}
        getPopupContainer={getPopupContainer}
      />
    </FilterDateMenuComponent>
  )
}

export default FilterDateMenu
