import { Children, cloneElement, isValidElement, useEffect, useState } from 'react'
import { useEventListener, useLocalStorage } from 'utils/hooks'

import Navigator from './Sider'
import Header from './Navbar'

import styled from 'styled-components'
import { space } from 'styled-system'
import { clsx } from 'utils/helper'
import mq from 'utils/mq'
import Box from 'components/Box'

const Content = styled(Box)`
  --height: 100vh;

  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);
  width: var(--content-width, calc(100% - 180px));
  background-color: #f5f6fb;

  display: flex;
  flex-direction: column;
  overflow: overlay;
`

const InnerContent = styled.div`
  flex: 1;
  padding: 24px;
  ${space}
`

const Container = styled.div`
  --sider-width: ${({ collapsed }) => (collapsed ? 0 : 180)}px;
  --content-width: calc(100% - var(--sider-width));

  display: flex;
  position: relative;

  &.non-desktop {
    --content-width: 100%;

    .sidebar {
      position: absolute;
      overflow: hidden;
      z-index: 99999;
      left: 0;
      top: 0;
    }
  }
`

const ExpandedSign = styled.div`
  --radius: 6px;

  width: 8px;
  height: 50px;
  background: #00325f;
  position: fixed;
  bottom: 15px;
  left: 0;
  cursor: pointer;
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  z-index: 9999;
`

const BREAKPOINT = mq('lg').size

const BaseLayout = ({ children, ...props }) => {
  const [isTablet, setIsTablet] = useState(window.innerWidth <= BREAKPOINT)
  const [collapsed, setCollapsed] = useLocalStorage('layout_collapsed', false)

  useEffect(() => {
    const listener = () => {
      const isTablet = window.innerWidth <= BREAKPOINT
      setIsTablet(isTablet)
      setCollapsed(isTablet ? true : isTablet)
    }

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [])

  return (
    <Container className={clsx(isTablet && 'non-desktop')} collapsed={collapsed}>
      <Navigator key='appSider' onCollapse={() => setCollapsed(true)} />
      <Content className='page-content-wrapper' customScrollBar>
        <Header />
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { ...props, collapsed })
          }
          return child
        })}
      </Content>
      {collapsed && <ExpandedSign onClick={() => setCollapsed(false)} />}
    </Container>
  )
}

BaseLayout.Content = ({ children, collapsed, ...props }) => {
  return (
    <InnerContent {...props}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { collapsed })
        }
        return child
      })}
    </InnerContent>
  )
}

export default BaseLayout
