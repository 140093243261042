import { Route, BrowserRouter, Switch } from 'react-router-dom'

import Loadable from 'components/Loadable'
import { AuthenticatedRoute, UnauthenticatedRoute } from 'components/Route'

const Login = Loadable({
  loader: () => import('pages/login')
})

const ForgetPassword = Loadable({
  loader: () => import('pages/forget_password')
})

const ResetPassword = Loadable({
  loader: () => import('pages/reset_password')
})

const Home = Loadable({
  loader: () => import('pages/home')
})

const Logout = Loadable({
  loader: () => import('pages/logout')
})

const Scheduling = Loadable({
  loader: () => import('pages/scheduling')
})

const SchedulingAdd = Loadable({
  loader: () => import('pages/scheduling_add')
})

const SchedulingEdit = Loadable({
  loader: () => import('pages/scheduling_edit')
})

const SchedulingDetail = Loadable({
  loader: () => import('pages/scheduling_detail')
})

const SchedulingAddMatch = Loadable({
  loader: () => import('pages/scheduling_add_match')
})

const SchedulingAddSeries = Loadable({
  loader: () => import('pages/scheduling_add_series')
})

const SchedulingEditSeries = Loadable({
  loader: () => import('pages/scheduling_edit_series')
})

const PartnerContract = Loadable({
  loader: () => import(/* webpackChunkName: 'partner_contract' */ 'pages/partner_contract')
})

const PartnerDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'partner_detail' */ 'pages/partner_detail')
})

const PartnerList = Loadable({
  loader: () => import('pages/partner')
})

const EntitlementAdd = Loadable({
  loader: () => import('pages/entitlement_add')
})

const EntitlementIndex = Loadable({
  loader: () => import('pages/entitlement_index')
})

const EntitlementEdit = Loadable({
  loader: () => import('pages/entitlement_edit')
})

const SchedulingSetting = Loadable({
  loader: () => import('pages/settings/scheduling/rules')
})

const UserSetting = Loadable({
  loader: () => import('pages/settings/user')
})

const ConfirmUpdateAccount = Loadable({
  loader: () => import('pages/settings/confirm_update_account')
})

const CategoryList = Loadable({
  loader: () => import('pages/settings/scheduling/categories')
})

const MatchDetail = Loadable({
  loader: () => import('pages/match_detail')
})

const MatchEdit = Loadable({
  loader: () => import('pages/match_edit')
})

const RundownChangeLog = Loadable({
  loader: () => import('pages/rundown_changelog')
})

const Inventory = Loadable({
  loader: () => import('pages/inventory')
})

const InventoryDetail = Loadable({
  loader: () => import('pages/inventory_detail')
})

const InventoryEntitlements = Loadable({
  loader: () => import('pages/inventory_match')
})

const AccountSetting = Loadable({
  loader: () => import('pages/settings/account')
})

const UserInviteSignup = Loadable({
  loader: () => import('pages/user_invite_signup')
})

const RundownOutOfGamePopup = Loadable({
  loader: () => import('pages/match_detail/popup/out_of_game')
})

const RundownAFLPopup = Loadable({
  loader: () => import('pages/match_detail/popup/afl')
})

const RundownAFLTVCPopup = Loadable({
  loader: () => import('pages/match_detail/popup/afl_tvc')
})

const RundownTeamVTeamPopup = Loadable({
  loader: () => import('pages/match_detail/popup/team_v_team')
})

const RundownCricketPopup = Loadable({
  loader: () => import('pages/match_detail/popup/cricket')
})

const RundownCricketTVCPopup = Loadable({
  loader: () => import('pages/match_detail/popup/cricket_tvc')
})

const RundownVRCPopup = Loadable({
  loader: () => import('pages/match_detail/popup/vrc')
})

const ScheduleSovTotal = Loadable({
  loader: () => import('pages/scheduling_detail/sov_total')
})

const VenueManagement = Loadable({
  loader: () => import('pages/administration/venue')
})

const DisplayTypeManagement = Loadable({
  loader: () => import('pages/administration/display_type')
})

const SportTeamManagement = Loadable({
  loader: () => import('pages/administration/sport_team')
})

const EntitlementTemplateManagement = Loadable({
  loader: () => import('pages/administration/entitlement_templates')
})

const ProductionNonMediaManagement = Loadable({
  loader: () => import('pages/administration/production_nonmedia')
})

const SportTypeManagement = Loadable({
  loader: () => import('pages/administration/sport_type')
})

const RaceDayEdit = Loadable({
  loader: () => import('pages/scheduling_edit_race_day')
})

const RaceDayAdd = Loadable({
  loader: () => import('pages/scheduling_add_race_day')
})

const TermsAndConditionSetting = Loadable({
  loader: () => import('pages/settings/terms_and_conditions')
})

const RundownTypeSetting = Loadable({
  loader: () => import('pages/settings/rundown_type')
})

const UserActivitySetting = Loadable({
  loader: () => import('pages/settings/user_activity')
})

export default function IndexRoute() {
  return (
    <BrowserRouter>
      <Switch>
        <UnauthenticatedRoute exact path={'/login'} component={Login} />
        <UnauthenticatedRoute exact path={'/forget_password'} component={ForgetPassword} />
        <UnauthenticatedRoute exact path={'/reset_password/:token'} component={ResetPassword} />
        <UnauthenticatedRoute exact path={'/invite/:inviteHash/'} component={UserInviteSignup} />
        <Route exact path={'/logout'} component={Logout} />
        <AuthenticatedRoute exact path={'/'} component={Home} />
        <AuthenticatedRoute exact path={'/scheduling'} component={Scheduling} />
        <AuthenticatedRoute exact path={'/scheduling/add'} component={SchedulingAdd} />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/edit'}
          component={SchedulingEdit}
        />
        <AuthenticatedRoute exact path={'/scheduling/:schedulingId'} component={SchedulingDetail} />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/sovTotal'}
          component={ScheduleSovTotal}
        />
        {/* <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/add'}
          component={SchedulingAddMatch}
        /> */}
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/add'}
          component={SchedulingAddMatch}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId'}
          component={MatchDetail}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/changelog'}
          component={RundownChangeLog}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/afl/tvc/expanded'}
          component={RundownAFLTVCPopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/afl/expanded'}
          component={RundownAFLPopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/teamvteam/expanded'}
          component={RundownTeamVTeamPopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/cricket/expanded'}
          component={RundownCricketPopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/cricket/tvc/expanded'}
          component={RundownCricketTVCPopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/vrc/expanded'}
          component={RundownVRCPopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/out-of-game/expanded'}
          component={RundownOutOfGamePopup}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/matches/:matchId/edit'}
          component={MatchEdit}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/series/add'}
          component={SchedulingAddSeries}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/series/:seriesId/edit'}
          component={SchedulingEditSeries}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/race-day/add'}
          component={RaceDayAdd}
        />
        <AuthenticatedRoute
          exact
          path={'/scheduling/:schedulingId/race-day/:raceDayId/edit'}
          component={RaceDayEdit}
        />
        <AuthenticatedRoute
          exact
          path={'/partners/:partnerId/contracts/:contractId/entitlements/add'}
          component={EntitlementAdd}
        />
        <AuthenticatedRoute
          exact
          path={'/partners/:partnerId/contracts/:contractId/entitlements/:entitlementId'}
          component={EntitlementIndex}
        />
        <AuthenticatedRoute
          exact
          path={'/partners/:partnerId/contracts/:contractId/entitlements/:entitlementId/edit'}
          component={EntitlementEdit}
        />
        <AuthenticatedRoute
          exact
          path={'/partners/:partnerId/contracts/:contractId'}
          component={PartnerContract}
        />
        <AuthenticatedRoute exact path={'/partners/:partnerId'} component={PartnerDetail} />
        <AuthenticatedRoute exact path={'/partners'} component={PartnerList} />
        <AuthenticatedRoute exact path={'/inventory'} component={Inventory} />
        <AuthenticatedRoute exact path={'/inventory/:fixtureId'} component={InventoryDetail} />
        <AuthenticatedRoute
          exact
          path={'/inventory/:fixtureId/match/:matchId'}
          component={InventoryEntitlements}
        />
        <AuthenticatedRoute exact path={'/administration/venue'} component={VenueManagement} />
        <AuthenticatedRoute
          exact
          path={'/administration/display-type'}
          component={DisplayTypeManagement}
        />
        <AuthenticatedRoute
          exact
          path={'/administration/sport-team'}
          component={SportTeamManagement}
        />
        <AuthenticatedRoute
          exact
          path={'/administration/entitlement-templates'}
          component={EntitlementTemplateManagement}
        />
        <AuthenticatedRoute
          exact
          path={'/administration/sport-type'}
          component={SportTypeManagement}
        />
        <AuthenticatedRoute
          exact
          path={'/administration/production-and-nonmedia'}
          component={ProductionNonMediaManagement}
        />
        <AuthenticatedRoute exact path={'/settings/scheduling'} component={SchedulingSetting} />
        <AuthenticatedRoute
          exact
          path={'/settings/terms-and-conditions'}
          component={TermsAndConditionSetting}
        />
        <AuthenticatedRoute
          exact
          path={'/settings/scheduling/categories'}
          component={CategoryList}
        />
        <AuthenticatedRoute
          exact
          path={'/settings/user-activity'}
          component={UserActivitySetting}
        />
        <AuthenticatedRoute
          exact
          path={'/settings/rundown-type'}
          component={RundownTypeSetting}
        />
        <Route
          exact
          path={'/settings/user/confirm-update-account'}
          component={ConfirmUpdateAccount}
        />
        <AuthenticatedRoute exact path={'/settings/user'} component={UserSetting} />
        <AuthenticatedRoute exact path={'/settings/account'} component={AccountSetting} />
      </Switch>
    </BrowserRouter>
  )
}
