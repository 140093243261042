import styled, { css } from 'styled-components'
import { color, typography, space, border, layout } from 'styled-system'

const handlePointer = (pointer) => {
  if (pointer) {
    return css`
      cursor: pointer;

      &:hover {
        background-color: #f7f7f9 !important;
      }
    `
  }
}

const Button = styled.th`
  color: #28293d;
  font-weight: ${p => p.fontWeight ?? 600};
  text-align: left;
  background-color: #fff;
  vertical-align: middle;
  ${({ pointer }) => handlePointer(pointer)};

  .table-header-button {
    position: relative;
    padding: 16px;
    display: flex;
    align-items: center;

    .text {
      width: calc(100% - 6px);
      display: inline-block;
      ${color}
    }

    .sort-wrapper {
      display: inline-block;
      padding-left: 4px;
      padding-right: 4px;

      svg {
        display: block;

        &:last-child {
          margin-top: 4px;
        }
      }
    }

    .sort-index {
      position: absolute;
      font-size: 8px;
      right: 7px;
      bottom: 18px;
    }

    ${space}
    ${typography}
  }

  ${color}
  ${layout}
  ${border}
`

export default Button
