import { useState, useRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'
import get from 'lodash/get'
import { Icon, Text } from 'components'

const UploadFileContainer = styled.div`
  background: #f9f9fb;
  box-sizing: border-box;
  border-radius: 4px;
  height: 280px;
  padding-top: 90px;
  padding-bottom: 90px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  transition: all 0.2s;

  input {
    display: none;
  }

  cursor: pointer;

  ${space};
  ${layout};

  ${({ dragOverlay }) =>
    dragOverlay &&
    css`
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
    `}
`

const FileUpload = ({ processDrop, className, children, accept, multiple = false }, ref) => {
  let [dragOverlay, setDragOverlay] = useState(false)
  let dragCounter = useRef(0)

  const preventBrowserDefaults = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrag = (e) => {
    preventBrowserDefaults(e)
  }

  const handleDragIn = (e) => {
    preventBrowserDefaults(e)
    dragCounter.current++
    if (get(e, 'dataTransfer.items.length') > 0) {
      setDragOverlay(true)
    }
  }

  const handleDragOut = (e) => {
    dragCounter.current--
    if (dragCounter.current === 0) {
      setDragOverlay(false)
    }
  }

  const handleDrop = (e) => {
    const files = get(e, 'dataTransfer.files')
    preventBrowserDefaults(e)
    setDragOverlay(false)
    dragCounter.current = 0
    processDrop(files)
  }

  const onSelectedFile = (e) => {
    const files = e.target.files
    processDrop(files)
  }

  const renderChildren = () => {
    if (children) {
      return children
    }

    return (
      <>
        <Icon mb='20px' name='box' />
        <Text mb='2px' color='#555770' fontSize='14px' fontWeight='500' width='100%'>
          Click or drag file to this area to upload
        </Text>
        <Text color='#555770' fontSize='12px' width='100%'>
          Supported JSON File Format Only
        </Text>
      </>
    )
  }

  return (
    <UploadFileContainer
      className={className}
      onClick={() => {
        ref.current.click()
      }}
      dragOverlay={dragOverlay}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input type='file' ref={ref} onChange={onSelectedFile} accept={accept} multiple={multiple} />
      {renderChildren()}
    </UploadFileContainer>
  )
}

export default forwardRef(FileUpload)
