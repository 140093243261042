import styled from 'styled-components'
import { space, layout } from 'styled-system'

const ContractLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #E6F4F4;
  color: #007B6F;
  height: 20px;

  ${space};
  ${layout};
`

const ContractLabelComponent = ({type, ...props}) => {
  if (type === 'Signed') {
    return (
      <ContractLabel {...props}>
        {type}
      </ContractLabel>
    )
  }

  return '-'
}

export default ContractLabelComponent
