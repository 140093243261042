import styled from 'styled-components'
import { space } from 'styled-system'
import { Icon } from 'components'
import { clsx } from 'utils/helper'

const FilterButtonComponent = styled.div`
  display: flex;
  align-items: center;

  color: #555770;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0px 12px;
  background-color: white;
  line-height: 150%;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  height: 36px;

  &.active,
  &:hover {
    background-color: #f7f7f9;
  }

  &.filtered {
    background: #003e72;
    color: white;
  }

  &:focus {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-left: 11px;
  }

  span {
    white-space: nowrap;
  }

  ${space};
`

export default function FilterButton({ label, activated, filtered, onClear, disabled, ...props }) {
  const handleOnRemove = (e) => {
    e.stopPropagation()
    onClear?.()
  }

  return (
    <FilterButtonComponent
      {...props}
      disabled={disabled}
      className={clsx(activated && 'active', filtered && 'filtered')}
    >
      <span>{label}</span>
      {filtered ? <Icon name='remove' onClick={handleOnRemove} /> : <Icon name='dropdown' />}
    </FilterButtonComponent>
  )
}
