import React from 'react'
import { Controller } from 'react-hook-form'

import Text from 'components/Text'
import MatchesTable from 'components/MatchesTable'
import styled, { useTheme } from 'styled-components'
import { clsx } from 'utils/helper'
import { ErrorContainer } from 'components/Select/Select'
import Icon from 'components/Icon'

const Container = styled.div`
  --label-color: rgb(40, 41, 61);

  &.error .matches-table {
    .inner-container,
    .table-container > div {
      border-color: #bf2600;
    }
  }

  .field-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--label-color);
    margin-bottom: 16px;
  }
`

function MatchesTableField({
  label,
  columns = [],
  dataSource = [],
  filterKeys,
  value,
  onChange,
  onSelect,
  wrapperStyle,
  wrapperClassName,
  className,
  title = '',
  error,
  ...props
}) {
  const { colors } = useTheme()

  const renderError = () => {
    if (!error?.message) return null

    return (
      <ErrorContainer>
        <Icon name='inputError' />
        <Text color='#bf2600' fontSize={14} ml={12}>
          {error.message}
        </Text>
      </ErrorContainer>
    )
  }

  return (
    <Container
      className={clsx(wrapperClassName, error && 'error')}
      style={{ marginTop: 24, ...wrapperStyle }}
    >
      {label && <Text className='field-label' color={colors.dark500}>{label}</Text>}
      <MatchesTable
        {...props}
        title={title}
        className={clsx('matches-table', className)}
        dataSource={dataSource}
        columns={columns}
        selectedItems={value}
        filterKeys={filterKeys}
        onSelect={(selected) => {
          onChange?.(selected)
          onSelect?.(selected)
        }}
      />
      {renderError()}
    </Container>
  )
}

export default function MatchesTableForm({ name, control, dataSource, columns, ...props }) {
  return (
    <Controller
      {...props}
      name={name}
      as={MatchesTableField}
      control={control}
      dataSource={dataSource}
      columns={columns}
    />
  )
}
