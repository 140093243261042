import { useState } from 'react'
import { useOutsideClick, usePropState } from 'utils/hooks'
import useInScreenPosition from './useInScreenPosition'

import FilterButton from './FilterButton'
import FilterMenu from './FilterMenu'

import styled from 'styled-components'
import { layout, space } from 'styled-system'
import { clsx } from 'utils/helper'
import { getBtnLabel } from './helper'

const FilterComponent = styled.div`
  display: inline-block;
  position: relative;

  .filter-content {
    background: white;
    z-index: 9999;
    padding: 16px 0;

    position: absolute;
    margin-top: 4px;
    display: none;

    &.active {
      display: block;
    }
  }

  ${space};
  ${layout};
`

export default function Filter({
  text,
  filterKey,
  value,
  options,
  onFilter: onChange,
  open,
  dropdownClassName,
  listMaxHeight,
  disabled = false,
  fixed = false,
  getPopupContainer = () => document.querySelector('.page-content-wrapper'),
  ...stylingProps
}) {
  const [isActivated, setIsActivated] = usePropState(open)

  // CheckedList
  const [checkedList, setCheckedList] = usePropState(value || [])
  const [isCheckedAll, setIsCheckedAll] = useState(false)

  const {
    register,
    style: dropdownStyle,
    refs
  } = useInScreenPosition({
    enabled: fixed,
    getPopupContainer,
    deps: [isActivated, checkedList]
  })

  const isDisabled = disabled || !options?.length
  const isFiltered = checkedList.length > 0

  const handleOnChecked = (values) => {
    setCheckedList(values)
    setIsCheckedAll(values.length === options.length)
    onChange?.(filterKey, values)
  }

  const handleOnSelectAll = (checked) => {
    const values = checked ? options : []
    setCheckedList(values)
    setIsCheckedAll(checked)
    onChange?.(filterKey, values)
  }

  const handleOnClear = () => {
    setCheckedList([])
    setIsCheckedAll(false)
    onChange?.(filterKey, [])
  }

  const toggle = () => {
    if (isDisabled) return
    setIsActivated(!isActivated)
  }

  useOutsideClick({ current: refs.current.container }, () => {
    setIsActivated(false)
    refs.current.dropdown?.reset?.()
  })

  return (
    <FilterComponent {...stylingProps} ref={register('container')}>
      <FilterButton
        className='filter-button'
        label={getBtnLabel(text, checkedList)}
        activated={isActivated}
        filtered={isFiltered}
        onClear={handleOnClear}
        onClick={toggle}
        disabled={isDisabled}
      />
      <FilterMenu
        ref={register('dropdown')}
        className={clsx('filter-content', isActivated && 'active', dropdownClassName)}
        style={dropdownStyle}
        title={text}
        filterKey={filterKey}
        checkedList={checkedList}
        checkAll={isCheckedAll}
        options={options}
        minWidth={250}
        minHeight={100}
        width={280}
        onChecked={handleOnChecked}
        onSelectAll={handleOnSelectAll}
        listMaxHeight={listMaxHeight}
      />
    </FilterComponent>
  )
}
