import Empty from './Empty'
import EmptyActivity from './EmptyActivity'
import EmptyFavourite from './EmptyFavourite'
import EmptyRecent from './EmptyRecent'

Empty.Activity = EmptyActivity
Empty.Favourite = EmptyFavourite
Empty.Recent = EmptyRecent

export default Empty
