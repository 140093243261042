import { useState, createContext, useContext } from 'react'
import { size } from 'lodash'
import { getTotalConflicts } from 'utils/helper'

const RundownDetailContext = createContext([{}, {}])
export const useCtxRundownDetail = () => useContext(RundownDetailContext)

const createSetter = (setter) => (conflicts) => setter(conflicts)

export default function RundownDetailProvider({ children, ...props }) {
  const [aflConflicts, setAflConflicts] = useState(null)
  const [cricketConflicts, setCricketConflicts] = useState(null)
  const [vrcConflicts, setVRCConflicts] = useState(null)
  const [tvtConflicts, setTeamVTeamConflicts] = useState(null)
  const [oogConflicts, setOOGConflicts] = useState(null)

  return (
    <RundownDetailContext.Provider
      value={[
        {
          totalAflConflicts: getTotalConflicts(aflConflicts),
          totalVRCConflicts: getTotalConflicts(vrcConflicts),
          totalCricketConflicts: getTotalConflicts(cricketConflicts),
          totalTeamVTeamConflicts: getTotalConflicts(tvtConflicts),
          totalOOGConflicts: size(oogConflicts?.conflicts),
          aflConflicts,
          cricketConflicts,
          vrcConflicts,
          tvtConflicts,
          oogConflicts
        },
        {
          setAflConflicts: createSetter(setAflConflicts),
          setCricketConflicts: createSetter(setCricketConflicts),
          setVRCConflicts: createSetter(setVRCConflicts),
          setTeamVTeamConflicts: createSetter(setTeamVTeamConflicts),
          setOOGConflicts: createSetter(setOOGConflicts)
        }
      ]}
    >
      {children}
    </RundownDetailContext.Provider>
  )
}
