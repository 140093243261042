import { Select as AntSelect } from 'antd'
import { useState, cloneElement, useRef } from 'react'
import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'
import { Box, Icon, Text } from 'components'
import { clsx } from 'utils/helper'

const { Option } = AntSelect

const handleBorderStyle = (border) => {
  if (border) {
    return css`
      border: ${border} !important;
    `
  }
}

const handleDisabledStyle = (disabled) => {
  if (disabled) {
    return css`
      background: #f7f7f9 !important;
      color: #444 !important;
    `
  }
}

const handleMode = (mode) => {
  switch (mode) {
  case 'multiple':
    return css`
      .ant-select-selector {
        padding: 0px 8px !important;

        .ant-select-selection-search {
          .ant-select-selection-search-input {
            height: 38px;
          }
        }
      }
    `
  default:
    return css`
      .ant-select-selector {
        padding: 4px 16px !important;

        .ant-select-selection-search {
          .ant-select-selection-search-input {
            height: 40px;
          }
        }
      }
    `
  }
}

const SelectAntd = styled(AntSelect)`
  font-size: 14px;
  line-height: 21px;
  width: 100%;

  .ant-select-selector {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: unset !important;
    line-height: 1.4;
    border: 1px solid #e5e5ef !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    padding: 4px 16px !important;

    ${({ border }) => handleBorderStyle(border)}

    ${({ disabled }) => handleDisabledStyle(disabled)}

    background-color: red;

    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 40px;
      }
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        background: #F7F7F9;
        border-radius: 4px;
        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &:hover {
    .ant-select-selector {
      background: #f7f7f9 !important;
    }
  }

  ${({ error }) => error && css`
    .ant-select-selector {
      border: 1px solid #bf2600 !important;
    }
  `}

  ${({ suffixIcon }) => suffixIcon && css`
    .ant-select-arrow {
      width: 24px;
      height: 24px;
      margin-top: -8px;
    }
  `}

  ${({ mode }) => handleMode(mode)}

  ${space};
  ${layout};
`

export const ErrorContainer = styled.div`
  margin-top: 10px;
  display: inline-block;

  svg {
    vertical-align: bottom;
  }
`

const Select = ({
  border,
  error,
  suffixIcon,
  enabledRenderError,
  options,
  onChange,
  afterOnChange,
  overrideChangeValue = (...args) => args,
  wrapperClassName,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const containerRef = useRef(null)

  const getBorderStyle = () => (open ? '1px solid #003e72' : border)

  const renderError = () => {
    if (enabledRenderError === false) {
      return null
    }

    return (
      <ErrorContainer>
        <Icon name='inputError' />
        <Text color='#bf2600' fontSize={14} ml={12}>
          {error.message}
        </Text>
      </ErrorContainer>
    )
  }

  const getIcon = () => {
    if (suffixIcon) {
      return cloneElement(suffixIcon, { fill: open ? '#555770' : '#babac8' })
    }
  }

  const handleOnChange = (...args) => {
    let overriddenArgs = overrideChangeValue(...args)

    if(!overriddenArgs || !Array.isArray(overriddenArgs)){
      overriddenArgs = args
    }

    onChange?.(...overriddenArgs)
    afterOnChange?.(...overriddenArgs)
  }

  return (
    <Box position='relative' ref={containerRef} className={clsx(wrapperClassName, 'atomic-select')}>
      <SelectAntd
        getPopupContainer={() => containerRef.current}
        {...props}
        onChange={handleOnChange}
        error={error}
        suffixIcon={getIcon()}
        border={getBorderStyle()}
        onDropdownVisibleChange={(open) => setOpen(open)}
      >
        {(options || []).map((item) => {
          const { label, value, suffixLabel, disabled } = item
          return (
            <Option value={value} disabled={disabled} meta={item}>
              {label}
              {suffixLabel}
            </Option>
          )
        })}
      </SelectAntd>
      {error && renderError()}
    </Box>
  )
}

export default Select
