import { Box, Icon, Tooltip } from 'components'
import styled from 'styled-components'
import { clsx } from 'utils/helper'

const Button = styled(Box)`
  --prim-color: #8c8da0;
  --sec-color: #e5e5ef;
  --color: var(--prim-color);
  --border-color: var(--sec-color);
  --alpha: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: var(--color);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  user-select: none;
  opacity: var(--alpha);
  border: 1px solid var(--border-color);

  &.btn-disabled {
    --alpha: 0.6;
    --color: var(--sec-color);
    cursor: not-allowed;
  }

  &.no-border {
    border: none;
  }

  :not(&.btn-disabled) {
    :hover,
    :active {
      --border-color: var(--prim-color);
    }
  }
`

export default function OrderButtons({ info, choices, onClick, className, style }) {
  return (
    <div className={clsx(className, 'center-y')} style={{ gap: 10, ...style }}>
      {info && (
        <Tooltip title={info}>
          <div className='fluid center'>
            <Icon name='info' color={'#BABAC8'} />
          </div>
        </Tooltip>
      )}
      {choices.map(({ disabled, icon, action, ...choice }) => {
        return (
          <Button
            key={action}
            size={24}
            className={clsx(disabled && 'btn-disabled', choice.className)}
            onClick={() => !disabled && onClick?.(action)}
          >
            <Icon {...choice} name={icon} clickable disabled={disabled} />
          </Button>
        )
      })}
    </div>
  )
}
