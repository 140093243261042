import { API_ENDPOINT } from 'constants/settings'

const getHostName = () => {
  return API_ENDPOINT
}

const getApiEndpoint = () => {
  return `${getHostName()}/api`
}

export { getHostName, getApiEndpoint }
