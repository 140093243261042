const getInventoryTemplateType = () => ({
  ALL: 'ALL',
  IN_GAME_LED: 'IN_GAME_LED',
  IN_GAME_MOMENT: 'IN_GAME_MOMENT',
  TVC: 'TVC',
  IN_RACE_STATIC: 'IN_RACE_STATIC',
  OUT_OF_RACE: 'OUT_OF_RACE',
  OOG_DURATION: 'OOG_DURATION'
})

const getInventoryType = () => ({
  SALES: 'SALES',
  DELIVERIES: 'DELIVERIES',
  FIXTURE_ENTITLEMENT_LIST: 'FIXTURE_ENTITLEMENT_LIST'
})

const getInventoryTableType = () => ({
  OVERVIEW: 'OVERVIEW',
  BY_ROUND: 'BY_ROUND',
  BY_SERIES: 'BY_SERIES'
})

const getTeamVTeamInventory = () => ({
  ALL: 'ALL',
  TEMPLATE: 'TEMPLATE'
})

export { getInventoryTemplateType, getInventoryType, getInventoryTableType, getTeamVTeamInventory }
