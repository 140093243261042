import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback
} from 'react'

import LiveChat from 'react-livechat'
import { Spinner } from 'components'

import { AuthReducer, AuthInitialState } from 'reducers'
import ApiService from 'services/ApiService'
import { LIVECHAT_LICENSE } from 'constants/settings'

export const AuthContext = createContext({
  data: AuthInitialState,
  dispatch: () => {},
  fetchAccount: () => {}
})

const AuthContextProvider = ({ children }) => {
  const [data, dispatch] = useReducer(AuthReducer, AuthInitialState)
  const [liveChatLoaded, setLiveChatLoaded] = useState(false)
  const livechatRef = useRef()
  const { isLoading, isAuthenticated, userDetail: user } = data
  const fullName = [user?.firstname, user?.lastname].filter((txt) => txt).join(' ')

  const fetchAccount = useCallback((onsuccess, onerror) => {
    ApiService.getUserDetail().then(
      (res) => {
        dispatch({ type: 'AUTH_SUCCESS', payload: { user: res.data } })
        onsuccess?.(res)
      },
      (err) => {
        if (err.response?.status === 403) {
          localStorage.removeItem('token')
          dispatch({ type: 'AUTH_ERROR' })
          onerror?.(err)
        }
      }
    )
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      if (isLoading) {
        fetchAccount()
      }
    } else {
      dispatch({ type: 'AUTH_ERROR' })
    }
  }, [isLoading, fetchAccount])

  useEffect(() => {
    if (!liveChatLoaded) return

    isAuthenticated
      ? livechatRef.current?.minimize_chat_window?.()
      : livechatRef.current?.hide_chat_window?.()
  }, [isAuthenticated, liveChatLoaded])

  const renderLiveChat = () => (
    <LiveChat
      onChatLoaded={(ref) => {
        livechatRef.current = ref
        setLiveChatLoaded(true)
      }}
      license={LIVECHAT_LICENSE}
      visitor={{ email: user?.email, name: fullName }}
      params={[
        { name: 'email', value: user?.email },
        { name: 'name', value: fullName }
      ]}
    />
  )

  return (
    <AuthContext.Provider value={{ data, dispatch, fetchAccount }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isAuthenticated && renderLiveChat()}
          {children}
        </>
      )}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
export const useAuth = () => useContext(AuthContext)
