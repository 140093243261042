import { Tabs as AntTabs } from 'antd'

import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

const Tabs = styled(AntTabs)`
  padding: 0px 0px 0px 24px;

  .ant-tabs-nav::before {
    border-bottom: 1px solid #e5e5ef !important;
  }

  .ant-tabs-tab {
    ${({ backgroundColor }) => backgroundColor && css`background: ${backgroundColor} !important;`}

    border: 1px solid #e5e5ef !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    border-radius: 4px 4px 0 0 !important;

    .ant-tabs-tab-btn {
      color: #BABAC8;
      a {
        color: #BABAC8 !important;
      }
    }
    .ant-tabs-tab-disabled {
      a {
        cursor: not-allowed;
      }
    }

    &.ant-tabs-tab-active {
      border-bottom-color: #fff !important;
      ${({ activeBackgroundColor }) => activeBackgroundColor && css`
        background: ${activeBackgroundColor} !important; border-bottom: transparent !important;`}
      .ant-tabs-tab-btn {
        color: #003E72;
        a {
          color: #003E72 !important;
        }
      }
    }
  }

  .ant-tabs-tab-with-remove {
    padding: 8px 12px !important;
  }

  button.ant-tabs-tab-remove {
    padding-top: 8px;
    margin-left: 2px;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  ${space};
  ${layout};
`

export default Tabs
