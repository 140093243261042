import { useState, useMemo, forwardRef, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { space, layout } from 'styled-system'
import { Input, Icon, Switch, Checkbox, Box } from 'components'
import { cssUnit } from 'utils/helper'

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5ef;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(198, 198, 198, 0.5);
  border-radius: 4px;
  padding: 16px;

  font-size: 14px;
  line-height: 21px;

  .title {
    font-weight: bold;
  }

  hr {
    margin: 10px -16px;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #f1f1f1;
  }

  .select-all {
    margin-top: 14px;
  }

  .select-all .switch {
      float: right;
  }

  .title,
  .select-all,
  ${Checkbox} {
    color: ${({ theme }) => theme.colors.text800};
  }

  ${space};
  ${layout};
`

const CheckboxGroup = styled(Checkbox.Group)`
  margin-top: 14px;
  max-height: ${({ $listMaxHeight: listMaxHeight }) => cssUnit(listMaxHeight)};

  overflow: auto;
  padding: 0 0 0 16px;

  .ant-checkbox-wrapper {
    width: 100%;

    .ant-checkbox {
      vertical-align: top;
      top: 2px;
      width: 16px;
      height: 16px;
    }

    > span:not(.ant-checkbox) {
      margin-left: 0;
      width: 216px;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 12px;
  }
`

const FilterMenu = (
  {
    title,
    options,
    checkedList,
    checkAll,
    onChecked,
    onSelectAll,
    listMaxHeight = 309,
    ...stylingProps
  },
  ref
) => {
  const [searchText, setSearchText] = useState()
  const containerRef = useRef()

  useImperativeHandle(ref, () => ({
    reset: () => setSearchText(),
    element: containerRef
  }))

  const displayOptions = useMemo(() => {
    if (searchText !== undefined && searchText.length > 0) {
      return options.filter((value) => {
        const val = value.toLowerCase()
        const input = searchText.toLowerCase()
        return val.includes(input)
      })
    }

    return options
  }, [options, searchText])

  return (
    <Container {...stylingProps} ref={containerRef}>
      <Box px={16}>
        <div className='title'>{title}</div>
        <hr />
        <Input
          value={searchText}
          placeholder={`Search for ${title.toLowerCase()}`}
          prefix={<Icon name='search' />}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className='select-all'>
          {`Select all ${title.toLowerCase()}`}
          <Switch.Hint checked={checkAll} />
          <Switch size='small' checked={checkAll} onChange={onSelectAll} style={{ float: 'right' }} />
        </div>
      </Box>
      <CheckboxGroup
        className='options'
        $listMaxHeight={listMaxHeight}
        onChange={onChecked}
        value={checkedList}
      >
        {options.map((value) => (
          <Checkbox
            key={value}
            value={value}
            style={{ display: displayOptions.includes(value) ? 'inline-flex' : 'none' }}
          >
            {value}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Container>
  )
}

export default forwardRef(FilterMenu)
