import { useState, useRef, useEffect } from 'react'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

import Box from 'components/Box'
import Text from 'components/Text'
import Icon from 'components/Icon'
import FilePicker from './FileUpload'
import { ErrorMessage } from 'components/Input/material'
import ListFile, { FILE_STATUS } from './ListFile'
import { useTheme } from 'styled-components'

import axios from 'axios'
import { map, omit, intersection, get } from 'lodash'
import ClientAPI from 'utils/ClientAPI'
import { clsx } from 'utils/helper'

const { CancelToken, isCancel } = axios

const getShortFileTypeName = (type = '') =>
  type
    .toLowerCase()
    .split(',')
    .map((txt) => txt.split('/').pop())

const convertValueToState = (filenames) =>
  filenames.reduce(
    (acc, filename) => ({
      ...acc,
      [filename]: { status: FILE_STATUS.COMPLETE, name: filename }
    }),
    {}
  )

function FileUploadField({
  name,
  rules,
  control,
  label,
  multiple = true,
  accept = 'application/pdf, application/json'
}) {
  const { colors } = useTheme()
  const [fileStatus, setFileStatus] = useState({})
  const {
    field: { onChange },
    meta: { invalid: error, isDirty }
  } = useController({ name, rules, control })

  const defaultValue = get(control, ['defaultValuesRef', 'current', name], [])

  const uploadRef = useRef()
  const controllerRef = useRef({})
  const isAllowedReinitialize = useRef(true)

  useEffect(() => {
    // initialized
    if (isDirty || !isAllowedReinitialize.current) return
    setFileStatus(convertValueToState(defaultValue))
  }, [isDirty, defaultValue])

  const change = (state) => {
    const filenames = Object.values(state).flatMap((file) =>
      file.status === FILE_STATUS.COMPLETE ? [file.name] : []
    )
    onChange(filenames)
  }

  const updateStatus = (input) => {
    setFileStatus((prev) => {
      const nextState = { ...prev, ...input }
      change(nextState)
      return nextState
    })
  }

  const uploadFile = async (file) => {
    try {
      const controller = CancelToken.source()
      const payload = new FormData()
      payload.append('file', file)

      controllerRef.current[file.name] = controller
      updateStatus({ [file.name]: { status: FILE_STATUS.PENDING, name: file.name } })
      const { data } = await ClientAPI.PDFUpload.post(payload, { cancelToken: controller.token })
      updateStatus({ [file.name]: { status: FILE_STATUS.COMPLETE, ...data } })
    } catch (error) {
      if (isCancel(error)) {
        setFileStatus((prev) => {
          const nextState = omit(prev, [file.name])
          change(nextState)
          return nextState
        })
      } else {
        updateStatus({ [file.name]: { status: FILE_STATUS.FAIL, name: file.name } })
      }
    } finally {
      delete controllerRef.current[file.name]
      isAllowedReinitialize.current = true
    }
  }

  const validateFiles = (files = []) => {
    const allowedFileTypeNames = getShortFileTypeName(accept)
    let fileList = Array.from(files).flatMap((file) => {
      const fileTypeName = getShortFileTypeName(file.type)
      const validFileTypeNames = intersection(allowedFileTypeNames, fileTypeName)
      return validFileTypeNames.length ? [file] : []
    })

    if (fileList.length > 0) {
      if (!multiple) {
        fileList = [fileList.shift()]
        setFileStatus({})
      }

      isAllowedReinitialize.current = false
      for (const file of fileList) {
        uploadFile(file)
      }
    }
  }

  return (
    <Container className={clsx(error && 'has-error')}>
      {label && (
        <Text fontSize='12px' fontWeight='500' mb='8px' color={colors.dark500}>
          {label}
        </Text>
      )}
      <FilePicker
        className='file-picker'
        ref={uploadRef}
        processDrop={validateFiles}
        multiple={multiple}
      >
        <Box className='center-y flex-col justify-center'>
          <Icon name='emptyBox' />
          <Text>Click or drag file to this area to upload</Text>
        </Box>
      </FilePicker>
      {error && <ErrorMessage message={error?.message} />}
      <ListFile>
        {map(fileStatus, ({ status }, filename) => {
          return (
            <ListFile.Item
              status={status}
              onRemove={() =>
                setFileStatus((prev) => {
                  const controller = controllerRef.current[filename]
                  const nextState = omit(prev, [filename])

                  if (controller) controller.cancel()
                  change(nextState)
                  return nextState
                })
              }
            >
              {filename}
            </ListFile.Item>
          )
        })}
      </ListFile>
    </Container>
  )
}

const Container = styled.div`
  .file-picker {
    height: 132px;
    padding: 0;
  }

  &.has-error .file-picker {
    border: ${({ theme }) => `1px dashed ${theme.colors.danger}`};
  }
`

export default FileUploadField
