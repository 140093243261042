import { Modal as AntModal } from 'antd'
import { Icon } from 'components'
import styled, { css } from 'styled-components'
import { color, space } from 'styled-system'
import { cssUnit } from 'utils/helper'

const handleDisableStyle = (okButtonProps) => {
  if (okButtonProps?.disabled) {
    return css`
      color: #fff;
      &,
      &:hover,
      &:active {
        background-color: ${({ theme }) => theme.colors.gray300};
      }
    `
  }
}

const StyledModal = styled(AntModal)`
  max-width: ${({ maxWidth = 800 }) => cssUnit(maxWidth)};
  min-width: ${({ minWidth = 800 }) => cssUnit(minWidth)};
  color: ${({ theme }) => theme.colors.text800};
  padding-top: 24px;

  ${color}

  .ant-modal-content {
    border-radius: 8px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 3px 6px 0 rgba(193, 206, 217, 0.2);

    .ant-modal-close {
      top: 37px;
      right: 37px;
      .ant-modal-close-x {
        line-height: 1;
        width: auto;
        height: auto;
      }
    }

    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: none;
      padding: 32px;

      .ant-modal-title {
        font-size: 24px;
        line-height: 150%;
        color: #28293d;
      }
    }

    .ant-modal-body {
      ${({ bodyPadding }) => css`padding: ${bodyPadding || '0 32px 32px'};`}

      .label {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
      }

      .input-error-container {
        display: inline-block;
        margin-top: 10px;

        svg,
        .text-error {
          vertical-align: bottom;
        }

        .text-error {
          color: ${({ theme }) => theme.colors.danger};
          margin-left: 12px;
        }
      }
    }

    .ant-modal-footer {
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
      padding: 16px 24px;

      .ant-btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 12px 36px;
        height: unset;
        background-color: #fff;
        color: ${({ theme }) => theme.colors.primary};
        -webkit-box-shadow: none;
        box-shadow: none;
        text-shadow: none;
        border: none;
        border-radius: 4px;

        &:hover {
          background-color: ${({ theme }) => theme.colors.white200};
        }
        &:active {
          background-color: ${({ theme }) => theme.colors.gray};
        }

        &.ant-btn-primary {
          background-color: ${({ theme }) => theme.colors.primary};
          color: #fff;

          &:hover {
            background-color: ${({ theme }) => theme.colors.primaryHover};
          }
          &:active {
            background-color: ${({ theme }) => theme.colors.primaryActive};
          }

          ${({ okButtonProps }) => handleDisableStyle(okButtonProps)}
        }

        &.ant-btn-danger {
          background-color: ${({ theme }) => theme.colors.danger};
          color: #fff;

          &:hover {
            background-color: ${({ theme }) => theme.colors.dangerHover};
          }

          &:active {
            background-color: ${({ theme }) => theme.colors.dangerActive};
          }
        }
      }
    }

    .ant-modal-header {
      ${space}
    }
  }
`

export default function Modal({ keyboard = false, maskClosable = false, ...props }) {
  return (
    <StyledModal
      centered
      closeIcon={<Icon name='closeModal' />}
      keyboard={keyboard}
      maskClosable={maskClosable}
      {...props}
    />
  )
}
