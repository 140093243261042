import styled, { css } from 'styled-components'
import { space, color, typography, layout } from 'styled-system'

const Text = styled.div`
  display: inline-block;
  line-height: 1.5;

  ${({ type }) => {
    if (type === 'title') {
      return css`
        color: #003e72;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
      `
    }
  }}

  ${space};
  ${color};
  ${typography};
  ${layout};
`

export default Text
