import React from 'react'
import Assets from 'assets'
import styled from 'styled-components'
import { space, layout, position } from 'styled-system'
import { $clickable, $color, $hoverColor } from './helper'

const Icon = ({ name, ...props }) => {
  const IconComponent = Assets.Icons[name]
  return <IconComponent {...props} />
}

const EnchantedIcon = styled(Icon)`
  ${space};
  ${layout};
  ${position};
  ${$color}
  ${$hoverColor}
  ${$clickable}
`

export default React.memo(EnchantedIcon)
