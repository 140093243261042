import { useImperativeHandle, useState } from 'react'

export default function useModalHandler(defaultValue, ref) {
  const [visible, setVisible] = useState(defaultValue)
  const [value, setValue] = useState()
  const [onOk, setOnOk] = useState(null)

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
  }
  const toggle = () => {
    setVisible((bool) => !bool)
  }

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
      toggle,
      setVisible,
      setValue,
      getValue: () => value,
      getVisible: () => visible,
      setOnOk: (func) => setOnOk(() => func)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, visible]
  )

  return {
    value,
    visible,
    onOk,
    open,
    close,
    toggle,
    setVisible,
    setValue,
    setOnOk: (func) => setOnOk(() => func)
  }
}
