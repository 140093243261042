import { Radio as AntRadio } from 'antd'

import styled from 'styled-components'
import { space, layout } from 'styled-system'

const RadioGroup = styled(AntRadio.Group)`
  .ant-radio-button-wrapper {
    color: #555770;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

    &.ant-radio-button-wrapper-checked {
      color: #003e72;
      border-color: #003e72;
      border-right-color: #003e72 !important;

      &::before {
        background-color: #003e72;
      }
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  ${space};
  ${layout};
`

export default RadioGroup
