import { Route, Redirect } from 'react-router-dom'
import { useAuth } from 'utils/hooks'


const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  const { data:{ isAuthenticated }} = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Redirect to={{ pathname: '/' }} />
        } else {
          return <Component {...rest} {...props} />
        }
      }} />
  )
}

export default UnauthenticatedRoute
