import { useCallback, useState, useRef } from 'react'
import { useEventListener, usePropState } from 'utils/hooks'

import { Document, Page } from 'react-pdf'
import { Box, Text } from 'components'
import CanvasPDF from './CanvasPDF'
import { Spinner, ThumbnailContainer, ZOOM_IN_SCALE, ZOOM_OUT_SCALE } from './material'

import { clsx } from 'utils/helper'

const MAXIMUM_CANVAS_WIDTH = 1135
const MINIMUM_CANVAS_WIDTH = 620
const MODAL_HORIZON_PADDING = 32 * 2
const THUMBNAIL_ITEM_WIDTH = 100
const THUMBNAIL_PADDING = 20
const THUMBNAIL_CONTAINER_PADDING = THUMBNAIL_PADDING * 2

export default function PDFViewer({ file, width, height, selectedPageNumber, ...props }) {
  const [numPages, setNumPages] = useState(null)
  const [canvasWidth, setCanvasWidth] = useState(MAXIMUM_CANVAS_WIDTH)
  const [scale, setScale] = useState(ZOOM_IN_SCALE)
  const [currentPage, setCurrentPage] = usePropState(selectedPageNumber, 1)

  const containerThumbnailsRef = useRef(null)

  const responsiveListener = useCallback(() => {
    const el = containerThumbnailsRef.current

    if (el) {
      const thumbnailEls = el.querySelectorAll('.thumbnail')
      const widthThumbnail = Math.max(
        THUMBNAIL_ITEM_WIDTH,
        Math.max(...Array.from(thumbnailEls).map((element) => element.offsetWidth))
      )
      const widthThumbnailContainer = widthThumbnail + THUMBNAIL_CONTAINER_PADDING

      const responsiveCanvasSize =
        window.innerWidth - widthThumbnailContainer - MODAL_HORIZON_PADDING
      const previewSize = Math.min(responsiveCanvasSize, MAXIMUM_CANVAS_WIDTH)

      setCanvasWidth(Math.max(MINIMUM_CANVAS_WIDTH, previewSize))
    }
  }, [])

  const togglePdfZoom = useCallback(() => {
    if (scale === ZOOM_IN_SCALE) {
      setScale(ZOOM_OUT_SCALE)
      return
    }

    if (scale === ZOOM_OUT_SCALE) {
      setScale(ZOOM_IN_SCALE)
      return
    }
  }, [scale])

  useEventListener('resize', responsiveListener)

  return (
    <Document
      renderMode='canvas'
      file={file}
      loading={() => <Spinner height={height} />}
      onLoadSuccess={({ numPages }) => {
        setNumPages(numPages)
        responsiveListener()
      }}
      onLoadError={(error) => console.log(error)}
    >
      <Box width='100%' display='flex' maxWidth='100vw'>
        <Box display='inline-block' width={canvasWidth}>
          <CanvasPDF
            width={canvasWidth}
            height={height}
            scale={scale}
            pageNumber={currentPage}
            onClick={togglePdfZoom}
          />
        </Box>
        <Box ref={containerThumbnailsRef} display='inline-block' textAlign='center' flex='1'>
          <Box
            className='wrapper-thumbnails'
            maxHeight={height}
            overflow='auto'
            display='flex'
            flexDirection='column'
            alignItems='center'
            px={THUMBNAIL_PADDING}
          >
            {numPages > 0 &&
              Array.from(new Array(numPages), (_, index) => {
                const page = index + 1
                return (
                  <ThumbnailContainer
                    className={clsx(
                      'thumbnail',
                      `thumbnail-page-${page}`,
                      page === currentPage && 'active'
                    )}
                  >
                    <Page
                      key={`page_${page}`}
                      pageNumber={page}
                      width={THUMBNAIL_ITEM_WIDTH}
                      scale={1}
                    />
                    <Text className='page-number'>{page}</Text>
                    <Box className='overlay' onClick={() => setCurrentPage(page)}></Box>
                  </ThumbnailContainer>
                )
              })}
          </Box>
        </Box>
      </Box>
    </Document>
  )
}
