import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

import { Icon } from './../../components'

const handleStatusLabel = (status) => {
  switch (status) {
  case 'Upload completed':
    return css`
      color: #077E76;
      background: #E9FBFA;
    `
  case 'Uploading':
    return css`
      color: #949494;
      background: #F1F1F1;
    `
  case 'Upload error':
    return css`
      color: #C65353;
      background: #FBEAEA;
      path {
        fill: #C65353;
      }
    `
  default:
    return css`
    `
  }
}


const getUploadStatusLabelText = (status) => {
  return <span>{status}</span>
}


const getUploadStatusLabelIcon = (status) => {
  switch (status) {
  case 'Upload completed':
    return <Icon name='uploadSuccess' size={22} />
  case 'Uploading':
    return <Icon name='uploading' size={22} />
  case 'Upload error':
    return <Icon name='uploadError' size={22} />
  default:
    return ''
  }
}


const UploadStatusLabel = styled.div`
  align-items: center;
  display: inline-flex;
  font-weight: 400;
  font-size: 14px;
`

const UploadStatusBadge = styled.div`
  display: inline-flex;
  height: 32px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;

  span {
    line-height: 21px;
    height: 21px;
    margin-left: 10px;
  }

  ${({ status }) => handleStatusLabel(status)};

  ${space};
  ${layout};
`

const FileNameLabel = styled.span`
  color: #555770;
`

const UploadStatusLabelComponent = ({ status, fileName, onClickCloseIcon, onOpenFile, ...rest }) => {
  return (
    <UploadStatusLabel>
      <UploadStatusBadge status={status} {...rest}>
        {getUploadStatusLabelIcon(status)}
        {getUploadStatusLabelText(status)}
      </UploadStatusBadge>
      <FileNameLabel>
        {fileName}
      </FileNameLabel>
      <Icon ml='8px' name='refresh' clickable onClick={onOpenFile} />
      <Icon ml='8px' name='closeCircle' clickable onClick={onClickCloseIcon} />
    </UploadStatusLabel>
  )
}

export default UploadStatusLabelComponent
