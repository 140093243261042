import { useState, useRef } from 'react'
import { Page } from 'react-pdf'
import { CanvasContainer } from './material'
import { clsx } from 'utils/helper'

export default function CanvasPDF({ width, height, scale, onClick, pageNumber }) {
  const [isLandscape, setIsLandscape] = useState(false)
  const containerRef = useRef(null)

  const containerProps = {
    height,
    width,
    scale,
    onClick
  }

  const pageProps = {
    pageNumber,
    height,
    scale
  }

  if (isLandscape) {
    containerProps.className = clsx(scale === 1 && 'center')
    pageProps.width = width
  }

  const onRenderSuccess = () => {
    const elContainer = containerRef.current
    if (elContainer) {
      const [elCanvas] = elContainer.getElementsByTagName('canvas')
      setIsLandscape(elCanvas.width > elCanvas.height)
    }
  }

  return (
    <CanvasContainer {...containerProps} ref={containerRef}>
      <Page {...pageProps} onRenderSuccess={onRenderSuccess} />
    </CanvasContainer>
  )
}
