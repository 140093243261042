import { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { Icon } from 'components'
import omit from 'lodash/omit'
import { copy } from 'utils/helper'

const SortArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-self: flex-end;
`

const FloatingContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 0;
  cursor: pointer;
  user-select: none;

  .sort-index {
    font-size: 8px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.white200};
  }
`

const Content = styled.div`
  padding-right: 40px;
  z-index: 1;
  position: relative;
  pointer-events: none;
`

export default function TableHeaderInner({ children, sortKey, handler, sortOrder = {} }) {
  const theme = useTheme()

  const handleSort = () => {
    const direction = sortOrder[sortKey] || null
    const nxDirection = !direction ? 'asc' : { asc: 'desc', desc: null }[direction]
    const orders = copy(sortOrder)
    orders[sortKey] = nxDirection
    handler?.(nxDirection ? orders : omit(orders, [sortKey]))
  }

  const index = useMemo(() => {
    return Object.keys(sortOrder).indexOf(sortKey)
  }, [sortOrder, sortKey])

  const isAscActive = () => sortOrder[sortKey] === 'asc'
  const isDescActive = () => sortOrder[sortKey] === 'desc'

  if (sortKey) {
    const colorDesc = isDescActive() ? theme.colors.dark500 : theme.colors.gray100
    const colorAsc = isAscActive() ? theme.colors.dark500 : theme.colors.gray100

    return (
      <>
        <FloatingContainer className='arrow-container' onClick={() => handleSort()}>
          <SortArrowContainer>
            <Icon name='sortUp' fill={colorAsc} />
            <Icon name='sortDown' fill={colorDesc} />
          </SortArrowContainer>
          {index !== -1 && <div className='sort-index'>{index + 1}</div>}
        </FloatingContainer>
        <Content>{children}</Content>
      </>
    )
  }

  return children
}
