import { Checkbox as AntCheckbox } from 'antd'

import styled from 'styled-components'
import { space, layout } from 'styled-system'

const Checkbox = styled(AntCheckbox)`
  font-size: 14px;
  line-height: 21px;
  .ant-checkbox + span {
    margin-left: 16px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #003E72;
    border-color: #003E72;
  }

  .ant-checkbox-checked::after {
    border-color: #003E72;
  }

  .ant-checkbox-disabled .ant-checkbox-inner{
    background-color: #BABAC8;
    border-color: #BABAC8;

    &::after {
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
    }
  }

  .ant-checkbox-disabled::after {
    border-color: #BABAC8 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #003E72;
    .ant-checkbox-inner {
      border-color: #003E72;
    }
    .ant-checkbox-input {
      border-color: #003E72;
    }
  }

  ${space};
  ${layout};
`

const Group = styled(AntCheckbox.Group)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #003E72;
    border-color: #003E72;
  }

  .ant-checkbox-checked::after {
    border-color: #003E72;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #003E72;
    .ant-checkbox-inner {
      border-color: #003E72;
    }
    .ant-checkbox-input {
      border-color: #003E72;
    }
  }
`

Checkbox.Group = Group

export default Checkbox
