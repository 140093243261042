const getSportType = () => {
  return {
    AFL: 1,
    CRICKET: 2,
    VRC: 3,
    OTHER: 99
  }
}

const getSportTypeDisplay = () => {
  return {
    AFL: 'AFL',
    CRICKET: 'CRICKET',
    VRC: 'VRC',
    ALL: 'All Sport',
    Other: 'Other'
  }
}

export { getSportType, getSportTypeDisplay }
