import axios from 'utils/axios'
import { getApiEndpoint } from 'utils'
import URL from 'constants/urls'
import qs from 'qs'

const API_ENDPOINT = getApiEndpoint()

const User = {
  getDetail: () => {
    return axios.get(URL.USER_DETAIL)
  },
  resetPassword: (data, token) => {
    return axios.put(`${API_ENDPOINT}/reset_password/${token}/`, data)
  },
  forgetPassword: (data) => {
    return axios.post(`${API_ENDPOINT}/reset_password/`, data)
  },
  list: () => {
    return axios.get(`${API_ENDPOINT}/users/`)
  },
  activeOptions: () => {
    return axios.get(`${API_ENDPOINT}/users/active/`)
  }
}

const Partner = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/partners/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  clientOptions: () => {
    return axios.get(`${API_ENDPOINT}/partners/client_options/`)
  },
  get: (partnerId) => {
    return axios.get(`${API_ENDPOINT}/partners/${partnerId}/`)
  },
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/partners/`, data)
  },
  update: (partnerId, data) => {
    return axios.put(`${API_ENDPOINT}/partners/${partnerId}/`, data)
  },
  delete: (partnerId) => {
    return axios.delete(`${API_ENDPOINT}/partners/${partnerId}`)
  },
  partnerContactOptions: (id) => {
    return axios.get(`${API_ENDPOINT}/partners/${id}/partner_contact/`)
  }
}

const PartnerCategory = {
  list: () => {
    return axios.get(`${API_ENDPOINT}/partner_categories/`)
  }
}

const PartnerContract = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/partner_contracts/`, { params: params })
  },
  get: (contractId) => {
    return axios.get(`${API_ENDPOINT}/partner_contracts/${contractId}/`)
  },
  getEntitlements: (contractId) => {
    return axios.get(`${API_ENDPOINT}/partner_contracts/${contractId}/entitlements`)
  },
  changeTableOrder: (contractId, payload) => {
    return axios.post(
      `${API_ENDPOINT}/partner_contracts/${contractId}/entitlements/order/`,
      payload
    )
  },
  mergeFinancial: (contractId, payload) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/${contractId}/financial/`, payload)
  },
  unMergeFinancial: (contractId, financialGroupId) => {
    return axios.delete(
      `${API_ENDPOINT}/partner_contracts/${contractId}/financial/?group_id=${financialGroupId}`
    )
  },
  getPaymentSchedule: (contractId) => {
    return axios.get(`${API_ENDPOINT}/partner_contracts/${contractId}/payment_schedule`)
  },
  createPaymentSchedule: (contractId, data) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/${contractId}/payment_schedule`, data)
  },
  clearPaymentSchedule: (contractId) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/${contractId}/payment_schedule/clear`)
  },
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/`, data)
  },
  update: (contractId, data) => {
    return axios.put(`${API_ENDPOINT}/partner_contracts/${contractId}/`, data)
  },
  delete: (contractId) => {
    return axios.delete(`${API_ENDPOINT}/partner_contracts/${contractId}`)
  },
  exportPDF: (contractId, data) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/${contractId}/export/pdf`, data)
  },
  previewPDF: (contractId, data) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/${contractId}/preview/pdf`, data)
  }
}

const Fixture = {
  create: (data) => {
    return axios.post(URL.FIXTURE, data)
  },
  list: () => {
    return axios.get(`${API_ENDPOINT}/fixtures/`)
  },
  retrieve: (id) => {
    return axios.get(`${URL.FIXTURE}${id}/`)
  },
  customRetrieve: (id) => {
    return axios.get(`${URL.FIXTURE_RETRIEVE}${id}/`)
  },
  update: (fixtureId, data) => {
    return axios.put(`${API_ENDPOINT}/fixtures/${fixtureId}/`, data)
  },
  edit: (fixtureId, data) => {
    return axios.put(`${API_ENDPOINT}/fixtures/${fixtureId}/edit`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.FIXTURE}${id}/`)
  },
  getVenues: (params) => {
    return axios.get(`${API_ENDPOINT}/fixture_venues/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  sovTotal: (fixture_id, unit) => {
    return axios.get(`${API_ENDPOINT}/fixtures/sov/${fixture_id}/?unit=${unit}`)
  }
}

const FixtureItem = {
  create: (data) => {
    return axios.post(URL.FIXTURE_ITEM, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.FIXTURE_ITEM}${id}/`, data)
  },
  list: (params, config = {}) => {
    return axios.get(URL.FIXTURE_ITEM, {
      params: params,
      ...config,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  retrieve: (id) => {
    return axios.get(`${URL.FIXTURE_ITEM}${id}/`)
  },
  changeVenue: (id, data) => {
    return axios.post(`${URL.FIXTURE_ITEM}${id}/venue_changed/`, data)
  },
  confirmReset: (id) => {
    return axios.post(`${URL.FIXTURE_ITEM}${id}/confirm_reset_bonus/`)
  },
  rundownStatus: (ids) => {
    return axios.get(`${URL.FIXTURE_ITEM}rundown_status/`, {
      params: { fixture_item_id: ids },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const FixtureItemEdit = {
  retrieve: (id) => {
    return axios.get(`${URL.FIXTURE_ITEM_EDIT}${id}/`)
  },
  update: (id, data) => {
    return axios.put(`${URL.FIXTURE_ITEM_EDIT}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.FIXTURE_ITEM_EDIT}${id}/`)
  }
}

const AssetTemplate = {
  list: () => {
    return axios.get(`${API_ENDPOINT}/asset_templates/`)
  }
}

const Entitlement = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/entitlements/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/entitlements/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/entitlements/${id}/`, data)
  },
  retrieve: (id) => {
    return axios.get(`${API_ENDPOINT}/entitlements/${id}/`)
  },
  delete: (id) => {
    return axios.delete(`${API_ENDPOINT}/entitlements/${id}/`)
  },
  bulkUpdate: (data) => {
    return axios.patch(`${API_ENDPOINT}/entitlements/`, data)
  },
  getByFixture: (fixtureId) => {
    return axios.get(`${API_ENDPOINT}/entitlements/fixture/${fixtureId}/`)
  }
}

const EntitlementService = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/entitlement_services/`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  retrieve: (id) => {
    return axios.get(`${API_ENDPOINT}/entitlement_services/${id}/`)
  },
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/entitlement_services/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/entitlement_services/${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${API_ENDPOINT}/entitlement_services/${id}/`)
  }
}

const EntitlementItem = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/entitlement_items/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const Rundown = {
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/rundowns/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/rundowns/${id}/`, data)
  },
  getInterrupt: (id, params) => {
    return axios.get(`${API_ENDPOINT}/rundowns/${id}/interrupt`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  getConflicts: (id, params) => {
    return axios.get(`${API_ENDPOINT}/rundowns/${id}/conflicts`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  getMatchTotals: (id) => {
    return axios.get(`${API_ENDPOINT}/rundowns/${id}/total_spots`)
  },
  getSoldBonusContract: (id) => {
    return axios.get(`${API_ENDPOINT}/rundowns/${id}/sold_bonus_contract/`)
  },
  refresh: (id, data) => {
    return axios.post(`${API_ENDPOINT}/rundowns/${id}/refresh/`, data)
  },
  regenerate: (id, data) => {
    return axios.post(`${API_ENDPOINT}/rundowns/${id}/regenerate/`, data)
  },
  entitlementUpdated: (data) => {
    return axios.post(`${API_ENDPOINT}/rundowns/entitlement_updated/`, data)
  }
}

const OOGRundown = {
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/oog_rundowns/`, data)
  },
  get: (id) => {
    return axios.get(`${API_ENDPOINT}/oog_rundowns/${id}/retrieve/`)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/oog_rundowns/${id}/`, data)
  },
  getConflicts: (id, params) => {
    return axios.get(`${API_ENDPOINT}/oog_rundowns/${id}/conflicts/`)
  },
  getMatchTotals: (id) => {
    return axios.get(`${API_ENDPOINT}/oog_rundowns/${id}/total_spots/`)
  },
  getOptions: (id) => {
    return axios.get(`${API_ENDPOINT}/oog_rundowns/${id}/options/`)
  }
}

const AFLTVCRundown = {
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/afl_tvc_rundowns/`, data)
  },
  get: (id) => {
    return axios.get(`${API_ENDPOINT}/afl_tvc_rundowns/${id}/`)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/afl_tvc_rundowns/${id}/`, data)
  },
  getConflicts: (id, params) => {
    return axios.get(`${API_ENDPOINT}/afl_tvc_rundowns/${id}/conflicts`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  getMatchTotals: (id) => {
    return axios.get(`${API_ENDPOINT}/afl_tvc_rundowns/${id}/total_spots`)
  }
}

const CricketRundown = {
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/rundown_crickets/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/rundown_crickets/${id}/`, data)
  },
  getConflicts: (id, params) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundowns/${id}/conflicts`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const CricketTVCRundown = {
  get: (id) => {
    return axios.get(`${API_ENDPOINT}/cricket_tvc_rundowns/${id}/`)
  },
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/cricket_tvc_rundowns/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/cricket_tvc_rundowns/${id}/`, data)
  },
  getConflicts: (id) => {
    return axios.get(`${API_ENDPOINT}/cricket_tvc_rundowns/${id}/conflicts`)
  },
  getMatchTotals: (id) => {
    return axios.get(`${API_ENDPOINT}/cricket_tvc_rundowns/${id}/total_spots`)
  }
}

const VRCRundown = {
  validate: (data) => {
    return axios.post(`${API_ENDPOINT}/vrc_rundowns/validate`, data)
  },
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/vrc_rundowns/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/vrc_rundowns/${id}/`, data)
  },
  retrieve: (id, params) => {
    return axios.get(`${API_ENDPOINT}/vrc_rundowns/${id}/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const RundownItem = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/rundown_items/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const TeamVTeamRundown = {
  create: (data) => {
    return axios.post(`${API_ENDPOINT}/team_v_team_rundowns/`, data)
  },
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/team_v_team_rundowns/${id}/`, data)
  },
  getConflicts: (id, params) => {
    return axios.get(`${API_ENDPOINT}/team_v_team_rundowns/${id}/conflicts`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  getMatchTotals: (id) => {
    return axios.get(`${API_ENDPOINT}/team_v_team_rundowns/${id}/total_spots`)
  },
  getSoldBonusContract: (id) => {
    return axios.get(`${API_ENDPOINT}/team_v_team_rundowns/${id}/sold_bonus_contract/`)
  },
  getInterrupt: (id, params) => {
    return axios.get(`${API_ENDPOINT}/team_v_team_rundowns/${id}/interrupt`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const TeamVTeamRundownItem = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/team_v_team_rundown_items/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const CricketRundownMoment = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundown_moments/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const CricketRundownItem = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundown_items/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  getSoldBonusContract: (id) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundowns/${id}/sold_bonus_contract/`)
  }
}

const CricketRundownSov = {
  list: (id) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundowns/${id}/sovs`)
  },
  getDay: (id, params) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundowns/${id}/sovs`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const VRCRundownSOV = {
  get: (id) => {
    return axios.get(`${API_ENDPOINT}/vrc_rundowns/${id}/sov`)
  }
}

const VRCRundownConflict = {
  get: (id) => {
    return axios.get(`${API_ENDPOINT}/vrc_rundowns/${id}/conflicts`)
  }
}

const CricketRundownActualDisplay = {
  list: (id, params) => {
    return axios.get(`${API_ENDPOINT}/cricket_rundowns/${id}/actual_displays`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const CricketRundownOperation = {
  exportExcel: (id, data) => {
    return axios.post(`${API_ENDPOINT}/cricket_rundowns/${id}/export/excel/`, data)
  },
  exportJson: (id, data) => {
    return axios.post(`${API_ENDPOINT}/cricket_rundowns/${id}/export/json/`, data)
  }
}

const RundownOperation = {
  update: (id, data) => {
    return axios.put(`${API_ENDPOINT}/rundown/op/${id}/`, data)
  },
  exportExcel: (id, data) => {
    return axios.post(`${API_ENDPOINT}/rundowns/${id}/export/excel/`, data)
  },
  exportJson: (id, data) => {
    return axios.post(`${API_ENDPOINT}/rundowns/${id}/export/json/`, data)
  },
  closeDay: (id, data) => {
    return axios.put(`${API_ENDPOINT}/cricket_rundowns/${id}/close_day`, data)
  }
}

const SchedulingRule = {
  list: (params) => {
    return axios.get(URL.SCHEDULING_RULE, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  create: (data) => {
    return axios.post(URL.SCHEDULING_RULE, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.SCHEDULING_RULE}${id}/`)
  }
}

const RundownChangelog = {
  list: (params) => {
    return axios.get(URL.RUNDOWN_CHANGELOG, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const PartnerOptions = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/partner_options/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const RundownRestore = {
  create: (id, data) => {
    return axios.put(`${API_ENDPOINT}/rundown/restore/${id}/`, data)
  }
}

const EntitlementItemOptions = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/entitlement_item_options/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const CreativeOptions = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/creative_options/`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const RundownCreativeOptions = {
  list: (id, params) => {
    return axios.get(`${API_ENDPOINT}/rundowns/${id}/creative_options/`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const ContentIdOptions = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/content_id_options/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const NotificationAlerts = {
  list: (params) => {
    return axios.get(`${API_ENDPOINT}/rundown_change/`, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

const Inventory = {
  get: (id, params) => {
    return axios.get(`${URL.INVENTORIES}${id}/`, {
      params: params
    })
  },
  update: (id, data) => {
    return axios.put(`${URL.INVENTORIES}${id}/`, data)
  },
  exportPdf: (id, data) => {
    return axios.post(`${URL.INVENTORIES}${id}/export/excel/`, data)
  }
}

const InventoryMatch = {
  get: (id) => {
    return axios.get(`${URL.INVENTORY_MATCH}${id}/`)
  },
  update: (id, data) => {
    return axios.post(`${URL.INVENTORY_MATCH}${id}/`, data)
  }
}

const InventoryNote = {
  create: (data) => {
    return axios.post(URL.INVENTORY_NOTES, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.INVENTORY_NOTES}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.INVENTORY_NOTES}${id}/`)
  }
}

const AFLLEDInventoryNote = {
  create: (data) => {
    return axios.post(URL.AFL_LED_INVENTORY_NOTES, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.AFL_LED_INVENTORY_NOTES}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.AFL_LED_INVENTORY_NOTES}${id}/`)
  }
}

const CricketInventoryNote = {
  create: (data) => {
    return axios.post(URL.CRICKET_INVENTORY_NOTES, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.CRICKET_INVENTORY_NOTES}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.CRICKET_INVENTORY_NOTES}${id}/`)
  }
}

const TeamVTeamInventoryNote = {
  create: (data) => {
    return axios.post(URL.TEAMVTEAM_INVENTORY_NOTES, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.TEAMVTEAM_INVENTORY_NOTES}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.TEAMVTEAM_INVENTORY_NOTES}${id}/`)
  }
}

const Account = {
  updatePassword: (data) => {
    return axios.put(`${API_ENDPOINT}/change_password/`, data)
  },
  updateProfile: (data) => {
    return axios.put(`${API_ENDPOINT}/update_profile/`, data)
  }
}

const Role = {
  list: () => {
    return axios.get(URL.ROLES)
  }
}

const InviteUser = {
  create: (data) => {
    return axios.post(URL.INVITE_USER, data)
  },
  list: () => {
    return axios.get(URL.INVITE_USER)
  },
  getByHash: (hash) => {
    return axios.get(`${URL.INVITE_USER}hash/${hash}`)
  }
}

const Signup = {
  createByHash: (hash, data) => {
    return axios.post(`${URL.INVITE_USER}hash/${hash}`, data)
  }
}

const ManageUser = {
  list: () => {
    return axios.get(URL.MANAGE_USER)
  },
  create: (data) => {
    return axios.post(URL.MANAGE_USER, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.MANAGE_USER}${id}/`, data)
  },
  delete: (data) => {
    return axios.delete(URL.MANAGE_USER, { data: data })
  },
  confirmAccountUpdate: (payload) => {
    return axios.post(`${URL.MANAGE_USER}confirm-update-account/`, payload)
  }
}

const Venue = {
  list: (params) => {
    return axios.get(URL.VENUES, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  create: (data) => {
    return axios.post(URL.VENUES, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.VENUES}${id}/`, data)
  },
  nameOptions: () => {
    return axios.get(`${URL.VENUES}name_options/`)
  }
}

const SportTeam = {
  list: (sport_type = '') => {
    return axios.get(`${URL.SPORT_TEAMS}?sport_type=${sport_type}`)
  },
  create: (data) => {
    return axios.post(URL.SPORT_TEAMS, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.SPORT_TEAMS}${id}/`, data)
  }
}

const SportType = {
  list: () => {
    return axios.get(URL.SPORT_TYPES)
  },
  create: (data) => {
    return axios.post(URL.SPORT_TYPES, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.SPORT_TYPES}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.SPORT_TYPES}${id}/`)
  }
}

const Series = {
  list: (params) => {
    return axios.get(URL.SERIES, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  get: (id) => {
    return axios.get(`${URL.SERIES}${id}/`)
  },
  delete: (id) => {
    return axios.delete(`${URL.SERIES}${id}/`)
  }
}

const PDFUpload = {
  post: (data, opts) => {
    return axios.post(URL.PDF_UPLOAD, data, opts)
  }
}

const AdobeSign = {
  send: (contractId, data) => {
    return axios.post(`${API_ENDPOINT}/partner_contracts/${contractId}/send/adobe_sign`, data)
  },
  get_document_url: (contractId) => {
    return axios.get(`${API_ENDPOINT}/partner_contracts/${contractId}/download_url/adobe_sign/`)
  }
}

const DisplayType = {
  list: (params) => {
    return axios.get(URL.DISPLAY_TYPE, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  create: (data) => {
    return axios.post(URL.DISPLAY_TYPE, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.DISPLAY_TYPE}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.DISPLAY_TYPE}${id}/`)
  },
  nameOptions: () => {
    return axios.get(`${URL.DISPLAY_TYPE}name_options/`)
  }
}

const EntitlementTemplate = {
  list: (params) => {
    return axios.get(URL.ENTITLEMENT_TEMPLATE, {
      params: params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  get: (id) => {
    return axios.get(`${URL.ENTITLEMENT_TEMPLATE}${id}/`)
  },
  create: (data) => {
    return axios.post(URL.ENTITLEMENT_TEMPLATE, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.ENTITLEMENT_TEMPLATE}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.ENTITLEMENT_TEMPLATE}${id}/`)
  }
}

const TermsAndConditions = {
  list: (params) => {
    return axios.get(URL.TERMS_AND_CONDITIONS, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  get: (id) => {
    return axios.get(`${URL.TERMS_AND_CONDITIONS}${id}/`)
  },
  create: (data) => {
    return axios.post(URL.TERMS_AND_CONDITIONS, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.TERMS_AND_CONDITIONS}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.TERMS_AND_CONDITIONS}${id}/`)
  }
}

const TermsAndConditionsGroup = {
  list: (params) => {
    return axios.get(URL.TERMS_AND_CONDITIONS_GROUP, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  patch: (data) => {
    return axios.patch(URL.TERMS_AND_CONDITIONS_GROUP, data)
  }
}

const UserActivity = {
  list: (params) => {
    return axios.get(URL.USER_ACTIVITY, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  },
  update: (id, payload) => {
    return axios.put(`${URL.USER_ACTIVITY}${id}/`, payload)
  },
  getFilterOption: (params) => {
    return axios.get(`${URL.USER_ACTIVITY}filter_option/`, {
      params
    })
  }
}

const RundownType = {
  list: (params) => {
    return axios.get(URL.RUNDOWN_TYPE, { params })
  },
  create: (data) => {
    return axios.post(URL.RUNDOWN_TYPE, data)
  },
  update: (id, data) => {
    return axios.put(`${URL.RUNDOWN_TYPE}${id}/`, data)
  },
  delete: (id) => {
    return axios.delete(`${URL.RUNDOWN_TYPE}${id}/`)
  }
}

const ClientAPI = {
  User: User,
  Partner: Partner,
  PartnerCategory: PartnerCategory,
  PartnerContract: PartnerContract,
  Fixture: Fixture,
  FixtureItem: FixtureItem,
  AssetTemplate: AssetTemplate,
  Entitlement: Entitlement,
  EntitlementService: EntitlementService,
  EntitlementItem: EntitlementItem,
  AFLTVCRundown: AFLTVCRundown,
  CricketTVCRundown: CricketTVCRundown,
  Rundown: Rundown,
  RundownItem: RundownItem,
  RundownOperation: RundownOperation,
  RundownRestore: RundownRestore,
  SchedulingRule: SchedulingRule,
  RundownChangelog: RundownChangelog,
  PartnerOptions: PartnerOptions,
  EntitlementItemOptions: EntitlementItemOptions,
  CreativeOptions: CreativeOptions,
  RundownCreativeOptions: RundownCreativeOptions,
  ContentIdOptions: ContentIdOptions,
  NotificationAlerts: NotificationAlerts,
  Inventory: Inventory,
  InventoryMatch: InventoryMatch,
  InventoryNote: InventoryNote,
  AFLLEDInventoryNote: AFLLEDInventoryNote,
  CricketInventoryNote: CricketInventoryNote,
  TeamVTeamInventoryNote: TeamVTeamInventoryNote,
  Account: Account,
  Role: Role,
  InviteUser: InviteUser,
  ManageUser: ManageUser,
  Signup: Signup,
  FixtureItemEdit: FixtureItemEdit,
  Venue: Venue,
  SportTeam: SportTeam,
  SportType: SportType,
  CricketRundownItem: CricketRundownItem,
  CricketRundown: CricketRundown,
  CricketRundownMoment: CricketRundownMoment,
  CricketRundownSov: CricketRundownSov,
  CricketRundownActualDisplay: CricketRundownActualDisplay,
  CricketRundownOperation: CricketRundownOperation,
  VRCRundown: VRCRundown,
  VRCRundownSOV: VRCRundownSOV,
  VRCRundownConflict: VRCRundownConflict,
  OOGRundown: OOGRundown,
  Series: Series,
  PDFUpload: PDFUpload,
  AdobeSign: AdobeSign,
  DisplayType: DisplayType,
  EntitlementTemplate: EntitlementTemplate,
  TeamVTeamRundownItem: TeamVTeamRundownItem,
  TeamVTeamRundown: TeamVTeamRundown,
  TermsAndConditions: TermsAndConditions,
  TermsAndConditionsGroup: TermsAndConditionsGroup,
  UserActivity: UserActivity,
  RundownType: RundownType
}

export default ClientAPI
