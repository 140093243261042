/* eslint-disable indent */
import { useState, useMemo } from 'react'
import { Select as BaseSelect, Box, Tooltip } from 'components'
import { CloseCircleFilled } from '@ant-design/icons'

import { sortBy, first, last, range } from 'lodash'
import { isSequential, isNumberRange, isNumberSeparateBy } from 'utils/helper'
import styled from 'styled-components'

const Select = styled(BaseSelect)`
  .ant-select-clear {
    right: 16.5px;
  }
`

const isSeparateByComma = isNumberSeparateBy(',')

const renderEntity = (list) => {
  return list.map((label, index) => (
    <span key={index}>
      {label}
      {list.length - 1 !== index && <span>{','}</span>}
    </span>
  ))
}

// SUPPORT NUMBER ONLY
const SelectPatternNumber = ({
  value = [],
  options,
  onChange,
  maxEntityCount = 30,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [patternValues, setPatternValues] = useState()

  const handleKeyPress = (e) => {
    // handle onpress 'enter' key
    if (e.keyCode === 13 && patternValues?.length) {
      const sorted = sortBy([...patternValues, ...value])
      onChange?.([...new Set(sorted)])
      setSearchValue('')
    }
  }

  const handleSearch = (txt) => {
    let vals

    if (isSeparateByComma(txt)) {
      vals = txt.split(',').map(Number)
    }

    if (isNumberRange(txt)) {
      const [start, end] = sortBy(txt.split('-').map(Number))
      vals = range(start, end + 1)
    }

    if (vals?.length) {
      setPatternValues(vals)
    }

    setSearchValue(txt)
  }

  const handleCloseTag = () => {
    onChange?.([])
  }

  const { numbers, _isSequential } = useMemo(() => {
    const mapper = options.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})
    const sorted = sortBy(value.map((v) => mapper[v] ?? v))
    const list = [...new Set(sorted)]

    if (list.length === 1)
      return { numbers: [first(list)], _isSequential: false }

    return {
      numbers: isSequential(list) ? [first(list), last(list)] : list,
      _isSequential: isSequential(list)
    }
  }, [options, value])

  const widgetEntities = useMemo(() => {
    const length = numbers.length

    if (!numbers.every((num) => typeof num === 'number')) return

    if (length === 1) return <span>{first(numbers)}</span>

    if (length === 2 && _isSequential)
      return (
        <span>
          {first(numbers)} - {last(numbers)}
        </span>
      )

    if (length > maxEntityCount) {
      return (
        <Tooltip
          title={numbers.join(',')}
          mouseEnterDelay={1.5}
          overlayStyle={{ opacity: 0.86 }}
        >
          {renderEntity([...numbers].slice(0, 7))}
          <span>,...,</span>
          {renderEntity([...numbers].slice(length - 5, length + 1))}
        </Tooltip>
      )
    }

    return renderEntity(numbers)
  }, [_isSequential, numbers, maxEntityCount])

  return (
    <Select
      {...props}
      value={value}
      options={options}
      searchValue={searchValue}
      mode={'multiple'}
      maxTagCount={0}
      onChange={onChange}
      onInputKeyDown={handleKeyPress}
      onSearch={handleSearch}
      tagRender={({ closable, ...tag }) => {
        return (
          <Box
            display='flex'
            alignItems='center'
            background='#F7F7F9'
            p='3.5px 8px'
            mr='25px'
            borderRadius='4px'
          >
            <Box as='span' mr='8px' width='100%' display='flex' flexWrap='wrap'>
              {widgetEntities}
            </Box>
            <CloseCircleFilled
              style={{ color: '#BABAC7' }}
              onClick={handleCloseTag}
            />
          </Box>
        )
      }}
    />
  )
}

export default SelectPatternNumber
