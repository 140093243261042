const getEntitlementDisplayType = () => {
  return {
    FULL_LED: 1,
    GOAL_LED: 2,
    WING_LED: 3,
    PGI: 4,
    MAI: 5,
    FIRST_BOUNCE: 6,
    FIVE_MW: 7,
    TVC: 8,
    TIME_ON: 9
  }
}

const getEntitlementType = () => {
  return {
    CUSTOM: null,
    IN_GAME_LED: 1,
    MOMENT: 2,
    PRODUCTION_AND_NON_MEDIA: 99,
    TVC: 5
  }
}

const getCricketEntitlementType = () => {
  return {
    CUSTOM: null,
    LED: 1,
    MOMENTS: 2,
    TVC: 5,
    PRODUCTION_AND_NON_MEDIA: 99
  }
}

const getCricketEntitlementDisplayType = () => {
  return {
    FULL_LED: 1,
    SIGHTSCREEN: 2,
    PARAPET: 3,
    HALF_LED: 4
  }
}

const getCricketLEDType = () => {
  return {
    LED_PERIMETER: 1,
    SIGHTSCREEN: 2,
    PARAPET: 3,
    TVC: 4
  }
}

const getVrcEntitlementType = () => {
  return {
    IN_RACE_STATIC: 3,
    OUT_OF_RACE: 4
  }
}

const getEntitlementItemDisplayType = () => {
  return {
    FULL_LED: 1,
    GOAL_LED: 2,
    WING_LED: 3,
    PGI: 4,
    MAI: 5,
    FIRST_BOUNCE: 6,
    FIVE_MW: 7,
    TVC: 8,
    TIME_ON: 9
  }
}

const getEntitlementLayoutType = () => {
  return {
    MERGE_FINANCE: 'MERGE_FINANCE',
    BULK_STATUS: 'BULK_STATUS',
    OVERRIDE_DATE: 'OVERRIDE_DATE',
    HIDE_SELLING_METRIC: 'HIDE_SELLING_METRIC'
  }
}

export {
  getEntitlementDisplayType,
  getEntitlementType,
  getCricketEntitlementType,
  getCricketEntitlementDisplayType,
  getCricketLEDType,
  getVrcEntitlementType,
  getEntitlementItemDisplayType,
  getEntitlementLayoutType
}
