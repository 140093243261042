import HeaderButton from './HeaderButton'
import Icon from '../Icon'
import Box from '../Box'
import Checkbox from '../Checkbox'
import { cloneDeep, omit } from 'lodash'

const TableHeader = ({
  sortKey,
  sortOrder,
  handler: onSorted,
  fixHeight: fixedHeight,
  text,
  children,
  // checkbox props
  checkbox,
  checked,
  disabled,
  onChange,
  ...stylingProps
}) => {
  const handleSort = () => {
    const orderBy = sortOrder[sortKey] || null
    let nextOrderBy = null
    if (orderBy === null) {
      nextOrderBy = 'asc'
    } else if (orderBy === 'asc') {
      nextOrderBy = 'desc'
    }
    updateSortOrder(sortKey, nextOrderBy)
  }

  const updateSortOrder = (key, value) => {
    let newSortOrder = cloneDeep(sortOrder)
    if (value) {
      newSortOrder[key] = value
    } else {
      newSortOrder = omit(sortOrder, key)
    }
    onSorted(sortOrder)
  }

  const getSortIndex = () => {
    return Object.keys(sortOrder).indexOf(sortKey)
  }

  const isAscActive = () => {
    return sortOrder[sortKey] === 'asc'
  }

  const isDescActive = () => {
    return sortOrder[sortKey] === 'desc'
  }

  if (sortKey) {
    return (
      <HeaderButton {...stylingProps} onClick={handleSort} pointer>
        <div className='table-header-button' style={{ height: fixedHeight }}>
          <div className='text'>{children ?? text}</div>
          <div className='sort-wrapper'>
            <Icon name='sortUp' fill={isDescActive() ? '#28293d' : '#dedee6'} />
            <Icon name='sortDown' fill={isAscActive() ? '#28293d' : '#dedee6'} />
          </div>
          {getSortIndex() !== -1 && <div className='sort-index'>{getSortIndex() + 1}</div>}
        </div>
      </HeaderButton>
    )
  } else if (checkbox) {
    return (
      <HeaderButton>
        <Box className='table-header-button'>
          <div className='text'>
            <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
          </div>
        </Box>
      </HeaderButton>
    )
  }

  return (
    <HeaderButton {...stylingProps}>
      <div className='table-header-button'>
        <div className='text'>{children ?? text}</div>
      </div>
    </HeaderButton>
  )
}

export default TableHeader
