import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

import { isCricket, isAFL, isVRC, isMultiple, getSportTypeDisplay } from 'utils/helper'

const handleSportType = (type) => {
  if (isAFL(type))
    return css`
      color: #4d7ecb;
      border: 1px solid #4d7ecb;
    `

  if (isCricket(type))
    return css`
      border: 1px solid #138da7;
      color: #138da7;
    `

  if (isVRC(type))
    return css`
      border: 1px solid #713cdd;
      color: #713cdd;
    `

  if (isMultiple(type))
    return css`
      border: 1px solid #401CDC;
      color: #401CDC;
    `

  return css`
    border: 1px solid #555770;
    color: #555770;
    opacity: 0.6;
  `
}


const handleSportTypeColor = (color) => {
  if (color) {
    return css`
      border: 1px solid ${color};
      color: ${color};
    `
  }

  return css`
    border: 1px solid #555770;
    color: #555770;
    opacity: 0.6;
  `
}

const SportLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  border-radius: 4px;

  ${'' /* ${({ type }) => handleSportType(type)}; */}
  ${({color}) => handleSportTypeColor(color)};

  ${space};
  ${layout};
`

const SportLabelComponent = ({ type, ...rest }) => {
  return (
    <SportLabel type={type} {...rest}>
      {getSportTypeDisplay(type)}
    </SportLabel>
  )
}

export default SportLabelComponent
